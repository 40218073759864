import * as React from "react";
import { StyleSheet } from 'react-native';
import * as Animatable from 'react-native-animatable';

import Colors from "../../constants/Colors";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const autoSelector = createSelector(
  state => state.games?.data?.auto,
  auto => auto
)

const UserInstruction = ({ step, children }) => {
  const autoGame = useSelector(autoSelector);

  const messages = [
    'Call the first number when ready 👉🏻',
    autoGame ? 'Use this button to Play/Pause the game 👉🏻' : 'Press again for the second number 👉🏻',
    autoGame ? 'Enjoy the game!' : 'Great👏 Keep going 👉🏻'
  ];
  return (
    <Animatable.Text
      allowFontScaling={false}
      animation="fadeInLeft"
      useNativeDriver
      style={[styles.instruction]}
    >
      {children ? children : step === 90 ? `All 90 numbers called. Press this to end the game 👉🏻` : messages[step]}
    </Animatable.Text>
  )
}

export default UserInstruction;

const styles = StyleSheet.create({
  instruction: {
    fontSize: 16,
    color: Colors.white,
    marginLeft: "auto",
    paddingRight: 5
  }
})