import React, { useRef } from 'react';
import {
    StyleSheet,
    View,
    Text as RNText,
    Animated,
    Easing,
    TouchableOpacity
} from 'react-native';
import Svg, { Path, G, Text, TSpan } from 'react-native-svg';
import * as d3Shape from 'd3-shape';
import { snap } from '@popmotion/popcorn';
import CommonStyles from '../styles/common';
import Colors from "../constants/Colors";

const width = 300;
const values = ['HARD LUCK', '200 💎', 'NEXT TIME', '100 💎','TRY AGAIN', '50 💎'];
const numberOfSegments = values.length;
const wheelSize = width * 0.8;
const fontSize = 20;
const oneTurn = 360;
const angleBySegment = oneTurn / numberOfSegments;
const angleOffset = angleBySegment / 2;
const knobFill = Colors.primaryColor;
const innerRadius = 20, outerRadius = width/2;

const makeWheel = () => {
    console.log('makeWheel');
    const data = Array.from({ length: numberOfSegments }).fill(1);
    const arcs = d3Shape.pie()(data);
    const colors = ['#f82','#0bf','#fb0','#0fb','#b0f','#f0b','#bf0']
    return arcs.map((arc, index) => {
        const instance = d3Shape
            .arc()
            .padAngle(0.02)
            .outerRadius(outerRadius)
            .innerRadius(innerRadius)
            .cornerRadius(2);

        return {
            path: instance(arc),
            color: colors[index],
            value: values[index],
            centroid: instance.centroid(arc)
        };
    });
};



function RenderKnob({_angle}){
    const knobSize = 30;
    // [0, numberOfSegments]
    const YOLO = Animated.modulo(
        Animated.divide(
            Animated.modulo(Animated.subtract(_angle, angleOffset), oneTurn),
            new Animated.Value(angleBySegment)
        ),
        1
    );

    return (
        <Animated.View
            style={{
                width: knobSize,
                height: knobSize * 2,
                justifyContent: 'flex-end',
                zIndex: 1,
                transform: [
                    {
                        rotate: YOLO.interpolate({
                            inputRange: [-1, -0.5, -0.0001, 0.0001, 0.5, 1],
                            outputRange: ['0deg', '0deg', '35deg', '-35deg', '0deg', '0deg']
                        })
                    }
                ]
            }}
        >
            <Svg
                width={knobSize}
                height={(knobSize * 100) / 57}
                viewBox={`0 0 57 100`}
                style={{ transform: [{ translateY: 8 }] }}
            >
                <Path
                    d="M28.034,0C12.552,0,0,12.552,0,28.034S28.034,100,28.034,100s28.034-56.483,28.034-71.966S43.517,0,28.034,0z   M28.034,40.477c-6.871,0-12.442-5.572-12.442-12.442c0-6.872,5.571-12.442,12.442-12.442c6.872,0,12.442,5.57,12.442,12.442  C40.477,34.905,34.906,40.477,28.034,40.477z"
                    fill={knobFill}
                />
            </Svg>
        </Animated.View>
    );
};

function RenderWinner({wheelState}){
    return (
        <RNText style={[CommonStyles.textMedium, CommonStyles.whiteText]}>Winner is: {wheelState.winner}</RNText>
    );
};

function RenderSvgWheel({_angle, _wheelPaths}){
    return (
        <View style={styles.container}>
            <RenderKnob _angle={_angle}/>
            <Animated.View
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    transform: [
                        {
                            rotate: _angle.interpolate({
                                inputRange: [-oneTurn, 0, oneTurn],
                                outputRange: [`-${oneTurn}deg`, `0deg`, `${oneTurn}deg`]
                            })
                        }
                    ]
                }}
            >
                <Svg
                    width={wheelSize}
                    height={wheelSize}
                    viewBox={`0 0 ${width} ${width}`}
                    style={{ transform: [{ rotate: `-${angleOffset}deg` }] }}
                >
                    <G y={width / 2} x={width / 2}>
                        {_wheelPaths.map((arc, i) => {
                            const [x, y] = arc.centroid;
                            const number = arc.value.toString();

                            return (
                                <G key={`arc-${i}`}>
                                    <Path d={arc.path} fill={arc.color} />
                                    <G
                                        rotation={angleOffset - 90 + (i * oneTurn) / numberOfSegments}
                                        origin={`${x}, ${y}`}
                                    >
                                        <Text
                                            x={x}
                                            y={y}
                                            textAnchor="middle"
                                            fontSize={fontSize}
                                            fill={Colors.white}
                                            fontWeight="bold"
                                        >
                                            {number}
                                        </Text>
                                    </G>
                                </G>
                            );
                        })}
                    </G>
                </Svg>
            </Animated.View>
        </View>
    );
};
const _wheelPaths = makeWheel();
let angle = 0;
let resultIndex = 1;
const randomnumber = (minimum, maximum) => Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
export default function LuckyWheel() {
    const _angle = useRef(new Animated.Value(0)).current;
    const [wheelState, setWheelState] = React.useState({
        enabled: true,
        finished: false,
        winner: null
    });

    React.useEffect(() => {
        _angle.addListener(event => {
            if (wheelState.enabled) {
                setWheelState({
                    enabled: false,
                    finished: false
                });
            }
            angle = event.value;
        });
        return () => {
            _angle.removeAllListeners();
        }
    },[]);

    const _getWinnerIndex = () => {
        const deg = Math.abs(Math.round(angle % oneTurn));
    
        // wheel turning counterclockwise
        if(angle < 0)
        {
            return Math.floor(deg / angleBySegment);
        }
    
        // wheel turning clockwise
        return (numberOfSegments - Math.floor(deg / angleBySegment)) % numberOfSegments;
    };

    const getRotationRequiredToWinIndex = (index) => {
        const remainingToZero = oneTurn - (angle % oneTurn);
        const numberOfTurns = randomnumber(4,12);
        const rotation = remainingToZero + (((numberOfSegments - index) * angleBySegment) + numberOfTurns * oneTurn) + randomnumber(0,angleOffset/2.0);
        return rotation;
    }

    const onPress = () => {
        resultIndex = randomnumber(0, numberOfSegments - 1);
        console.log('Wheel win ', _wheelPaths[resultIndex].value);
        const toAngle = angle + getRotationRequiredToWinIndex(resultIndex);
        Animated.timing(_angle, {
            duration: 7000,
            toValue: toAngle,
            easing: Easing.out(Easing.cubic),
            useNativeDriver: true
        }).start(() => {
            _angle.setValue(angle % oneTurn);
            const snapTo = snap(oneTurn / numberOfSegments);
            Animated.timing(_angle, {
                toValue: snapTo(angle),
                duration: 300,
                useNativeDriver: true
            }).start(() => {
                const winnerIndex = _getWinnerIndex();
                setWheelState({
                    enabled: true,
                    finished: true,
                    winner: _wheelPaths[winnerIndex].value
                });
            });
        });
    };
    return (
        <TouchableOpacity
            onPress={onPress}
            disabled={!wheelState.enabled}
        >
            <View style={styles.container}>
                <RenderSvgWheel _angle={_angle} _wheelPaths={_wheelPaths}/>
                {wheelState.finished && wheelState.enabled && <RenderWinner wheelState={wheelState}/>}
            </View>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    winnerText: {
        fontSize: 32,
        fontFamily: 'Menlo',
        position: 'absolute',
        bottom: 10
    }
});
