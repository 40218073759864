import * as React from "react";
import { StyleSheet, Text, View } from "react-native";

import Colors from "../constants/Colors";
import { MonoText } from "../components/StyledText";

export default function DevModeNotice() {
  return __DEV__ ? (
    <View style={styles.developmentModeNotice}>
      <MonoText
        style={styles.developmentModeText}
      >
        {__DEV__ ? "Dev" : "Prod"}
      </MonoText>
    </View>
  ) : null;
}


const styles = StyleSheet.create({
  developmentModeNotice: {
    position: "absolute",
    top: 2,
    right: 2,
    padding: 5,
    backgroundColor: "gray",
  },
  developmentModeText: {
    color: Colors.white,
  },
});
