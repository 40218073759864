import React, { useState, useRef, useEffect, useCallback } from 'react';
import { StyleSheet, View, Text, TextInput } from "react-native";
import { ScrollView, TouchableOpacity} from "react-native-gesture-handler";
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux';
import * as Animatable from 'react-native-animatable';
import Icon from 'react-native-vector-icons/FontAwesome';
import * as Analytics from 'expo-firebase-analytics';

import Colors from "../../constants/Colors";
import commonStyles from "../../styles/common";
import { services } from "../../store";
import ThemeButton from '../ThemeButton';
import usePrevious from '../../utils/hooks/usePrevious';
import Units from '../../constants/Units';
import Layout from '../../constants/Layout';

const responsesSelector = createSelector(
  state => state.autoResponses.list,
  list => list
)

// function useOnClickOutside(ref, handler) {
//   useEffect(
//     () => {
//       const listener = event => {
//         // Do nothing if clicking ref's element or descendent elements
//         console.log(ref.current);
//         console.log(event.target);
//         if (!ref.current || ref.current.contains(event.target)) {
//           return;
//         }

//         handler(event);
//       };

//       document.addEventListener('mousedown', listener);
//       document.addEventListener('touchstart', listener);

//       return () => {
//         document.removeEventListener('mousedown', listener);
//         document.removeEventListener('touchstart', listener);
//       };
//     },
//     // Add ref and handler to effect dependencies
//     // It's worth noting that because passed in handler is a new ...
//     // ... function on every render that will cause this effect ...
//     // ... callback/cleanup to run every render. It's not a big deal ...
//     // ... but to optimize you can wrap handler in useCallback before ...
//     // ... passing it into this hook.
//     [ref, handler]
//   );
// }

export const CustomMessageBox = React.memo(({ gameId }) => {
  const [textMsg, setTextMsg] = useState('');
  const inputRef = useRef(null);

  const sendMessage = useCallback(() => {
    const message = textMsg.trim();
    if (message) {
      services.notification.create({
        message: message,
        gameId
      });
      Analytics.logEvent('chat_custom_msg_sent', { message });
    } else {
      inputRef.current.focus();
    }
    setTextMsg('');
  }, [textMsg]);

  return (
    <View
      style={[styles.option, { paddingHorizontal: 10, width: 130 }]}
    >
      <TextInput
        allowFontScaling={false}
        style={{ flexGrow: 1, textAlign: "left", color: Colors.white, paddingLeft: 5, width: 90, height: 20 }}
        disableFullscreenUI={true}
        placeholder="Write..."
        placeholderTextColor={Colors.white}
        onChangeText={setTextMsg}
        onSubmitEditing={sendMessage}
        value={textMsg}
        autoCorrect={false}
        ref={inputRef}
      />
      {
        <TouchableOpacity
          style={{ paddingVertical: 0, paddingHorizontal: 5, borderRadius: Units.cornerRadius }}
          onPress={sendMessage}
        >
          <Text allowFontScaling={false} style={styles.optionText}><Icon name='paper-plane' size={16} /></Text>
        </TouchableOpacity>
      }
    </View>
  )
})

const emojis = ['👍', '👎', '💰', '😍', '😎', '😫', '😭', '😴', '🥳']; //'😳', '🤯', '🥺', '🤧', '🤐', '👊'

const Chat = () => {
  const responses = useSelector(responsesSelector);
  const prevResponses = usePrevious(responses);
  const gameId = useSelector(state => state.games?.data?._id);

  const [isResponseListVisible, toggleResponsesList] = useState(false);
  const [buttonRef, setButtonRef] = useState(null);
  const handleViewRef = useCallback(ref => setButtonRef(ref));

  useEffect(() => {
    buttonRef && !isResponseListVisible && (prevResponses.length < responses.length) && buttonRef.tada(800);
  }, [buttonRef, isResponseListVisible, prevResponses, responses])

  const [scrollViewRef, handleScrollViewRef] = useState(null);
  useEffect(() => {
    if (isResponseListVisible && scrollViewRef) {
      scrollViewRef.scrollToEnd({ animated: true });
    }
  }, [isResponseListVisible])

  const wrapperRef = useRef();
  // useOnClickOutside(wrapperRef, () => toggleResponsesList(false));

  return (
    <View ref={wrapperRef}>
      <Animatable.View
        ref={handleViewRef}
        useNativeDriver
      >
        <ThemeButton
          type="secondary"
          onPress={() => {
            Analytics.logEvent('chat_button_click');
            toggleResponsesList(!isResponseListVisible);
          }}
          text={<Text allowFontScaling={false}>Chat <Icon name='comment' size={16} /></Text>}
          style={{ height: 32, minWidth: "auto", marginTop: 5, padding: 4 }}
        />
      </Animatable.View>
      {
        isResponseListVisible ? (
          <View pointerEvents="box-none" style={styles.container}>
            <ScrollView
              ref={handleScrollViewRef}
              style={{ flexGrow: 0 }}
              contentContainerStyle={{ alignItems: 'flex-start', zIndex: 11 }}
            >
              <CustomMessageBox gameId={gameId} />
              {
                responses.map((item, index) => <TouchableOpacity
                  onPress={() => {
                    toggleResponsesList(!isResponseListVisible);
                    services.notification.create({
                      message: item,
                      gameId
                    })
                  }}
                  key={`${item}-${index}`}
                  style={styles.option}
                >
                  <Text allowFontScaling={false} style={[commonStyles.textSmall, styles.optionText]}>
                    {item}
                  </Text>
                </TouchableOpacity>)
              }
            </ScrollView>
            <View>
              {/* <ScrollView
                horizontal
                contentContainerStyle={{ alignItems: 'flex-start' }}
              > */}
              <View style={{ flexDirection: "row", marginBottom: 10 }}>
                {
                  emojis.map((emoji, index) => (
                    <TouchableOpacity
                      onPress={() => {
                        toggleResponsesList(!isResponseListVisible);
                        services.notification.create({
                          message: emoji,
                          gameId
                        })
                      }}
                      key={`emoji${index}`}
                      style={styles.emoji}
                    >
                      <Text allowFontScaling={false} style={[commonStyles.whiteText, commonStyles.textMedium]}>{emoji}</Text>
                    </TouchableOpacity>
                  ))
                }
              </View>
              {/* </ScrollView> */}
            </View>
          </View>) : null
      }
    </View>
  )
}

export default React.memo(Chat);

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    maxHeight: 270,
    bottom: 25,
    left: Layout.window.width > 736 ? 0 : Layout.window.width > 667 ? 30 : 40,
    alignItems: "flex-start",
    justifyContent: "flex-end",
    zIndex: 5
  },
  option: {
    flexDirection: "row",
    justifyContent: "center",
    paddingVertical: 6,
    paddingHorizontal: 12,
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    borderRadius: 20,
    margin: 2,
  },
  optionText: {
    lineHeight: 18,
    textAlign: "center",
    color: Colors.white
  },
  emoji: {
    padding: 10,
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    margin: 2,
    borderRadius: 20,
    width: 40,
    height: 40,
    padding: 0,
    alignItems: "center",
    justifyContent: "center"
  }
});
