import { createSlice } from "@reduxjs/toolkit";

const online = createSlice({
  name: "online",
  initialState: {},
  reducers: {
    addList(state, { payload: list }) {
      list.map(id => state[id] = true)
    },
    addItem(state, { payload: id }) {
      state[id] = true
    },
    deleteItem(state, { payload: id }) {
      state[id] = false
    }
  },
});

export const { addList, addItem, deleteItem } = online.actions;

export default online.reducer;
