import * as React from "react";
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  KeyboardAvoidingView,
  Platform,
  ScrollView
} from "react-native";
import Icon from 'react-native-vector-icons/FontAwesome';
import { useFocusEffect } from '@react-navigation/native';
import { createSelector } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import * as yup from "yup";
import { Formik } from "formik";

import CommonStyles from "../styles/common";
import Colors from "../constants/Colors";
import Logo from "../components/Logo";
import ScreenBackground from "../components/ScreenBackground";
import ThemeButton from "../components/ThemeButton";
import ListItem from "../components/ListItem";
import { calculateTimeLeft } from "../utils";
// import { deleteStrikesForGame } from '../reducers/ticketStrikes';

import { services } from "../store";
import { gamesListSelector } from "../selectors";
import Units from "../constants/Units";
import Banner from "../components/GameElements/Banner";
import Anchor from '../components/Anchor';

const formatTime = (time) => {
  const formattedTime = new Date(time).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });
  if (Platform.OS === 'android') {
    return formattedTime.split(':').slice(0, 2).join(':');
  }
  return formattedTime;
}

const errorSelector = createSelector(
  state => JSON.stringify(state.players.isError?.message),
  errorMsg => errorMsg
)

export const RenderItem = ({ item, navigation, pinned = false }) => {
  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft(item.scheduledAt));

  React.useEffect(() => {
    if (item.public) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(item.scheduledAt));
      }, 1000);

      return () => {
        clearTimeout(timer)
      };
    }
  }, [item.public]);

  let subtitle = '';
  if (item.startAt)
    subtitle = `In progress`;
  else if (timeLeft.seconds || timeLeft.minutes > 1 || timeLeft.hours) {
    if (timeLeft.hours) {
      subtitle = `Scheduled at ${formatTime(item.scheduledAt)}`;
    } else {
      subtitle = `Starting in ${timeLeft.formatted}`;
    }
  }
  else {
    subtitle = "Starting anytime";
  }

  const title = pinned ? `${item.hostName} - ${item._id.toUpperCase()}` : `Game by ${item.hostName} - ${item._id.toUpperCase()}`

  return (
    <ListItem
      title={title}
      subtitle={subtitle}
      user={item.host}
      onPress={() => {
        return navigation.navigate("GameArea", {
          gameId: item._id,
        })
      }}
      arrow
    />
  )
}

function JoinScreen({ navigation }) {
  useFocusEffect(
    React.useCallback(() => {
      services.games.find({
        query: {
          $select: ['_id', 'startAt', 'scheduledAt', 'updatedAt', 'hostId', 'hostName', 'public', 'host']
        }
      });
    }, [])
  );

  // const dispatch = useDispatch();
  const games = useSelector(gamesListSelector);
  const errorMsg = useSelector(errorSelector);
  // const gamesFinshedLoading = useSelector(state => state.games.isFinished);
  // const persistedGamesList = useSelector(state => Object.keys(state.ticketStrikes));

  // Purge effect: Clear games which are not in the upcoming list
  // if (gamesFinshedLoading) {
  //   React.useEffect(
  //     () => {
  //       const gamesList = games.map(game => game._id);
  //       console.log(gamesList);
  //       console.log(persistedGamesList);
  //       persistedGamesList.map(
  //         gameId => gamesList.indexOf(gameId) === -1 ? dispatch(deleteStrikesForGame(gameId)) : null
  //       )
  //     },
  //     [games, persistedGamesList, dispatch]
  //   )
  // }

  return (
    <ScreenBackground>
      <ScrollView
        contentContainerStyle={styles.contentContainer}
      >
        <Formik
          initialValues={{ code: "" }}
          onSubmit={({ code }) => {
            services.players
              .create({}, { query: { gameId: code.trim().toLowerCase() } })
              .then(() => {
                navigation.navigate("GameArea", {
                  gameId: code.trim().toLowerCase(),
                })
              });
          }}
          validationSchema={yup.object().shape({
            code: yup.string().required(),
          })}
        >
          {({
            values,
            handleChange,
            errors,
            setFieldTouched,
            touched,
            isValid,
            handleSubmit,
          }) => (
              <>
                <Logo />
                <KeyboardAvoidingView
                  behavior={Platform.OS == "ios" ? "padding" : "height"}
                >
                  <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
                    <View style={{ marginHorizontal: 15 }}>
                      <TextInput
                        allowFontScaling={false}
                        style={{
                          height: 37,
                          borderColor: Colors.white,
                          borderWidth: 1,
                          borderRadius: 4,
                          color: Colors.white,
                          width: 200,
                          fontWeight: "bold",
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                        disableFullscreenUI={true}
                        placeholder="Enter invite code..."
                        placeholderTextColor="white"
                        onChangeText={handleChange("code")}
                        onBlur={() => setFieldTouched("code")}
                        value={values.code}
                        autoCapitalize="characters"
                        autoCorrect={false}
                      />
                      {touched.code && errors.code && (
                        <Text style={{ fontSize: 15, color: "red" }}>
                          {errors.code}
                        </Text>
                      )}
                      {errorMsg ? (
                        <Text style={[CommonStyles.error, CommonStyles.textMedium]}>
                          {errorMsg}
                        </Text>
                      ) : null}
                    </View>
                    <ThemeButton
                      type="primary"
                      onPress={handleSubmit}
                      text="Join"
                    />
                  </View>
                </KeyboardAvoidingView>

                {
                  games.length ? (
                    <View style={[CommonStyles.buttonContainer]}>
                      <Text allowFontScaling={false} style={[CommonStyles.secondaryText, CommonStyles.textMedium, { fontWeight: "bold" }]}>
                        Upcoming Games
                      </Text>
                      <View style={{ marginTop: 8, maxWidth: 450 }}>
                        {/* <FlatList
                          keyExtractor={(item) => item._id}
                          data={games.slice().reverse()}
                          renderItem={({ item }) => <RenderItem item={item} navigation={navigation} />}
                        /> */}
                        {games.slice().reverse().map(
                          (item) => <RenderItem key={item._id} item={item} navigation={navigation} />
                        )}
                      </View>
                    </View>
                  ) : null
                }
                <View style={{maxWidth: 450, marginTop: 8, marginBottom: 8}}>
                  <View style={{ flexDirection: "row", alignSelf: "stretch", justifyContent: "space-between", flexWrap: "wrap" }}>
                    <Banner>
                      <Anchor href={`https://api.whatsapp.com/send?text=Hi, I had a query on joining private Online Tambola game. &phone=917477645641`}>
                        <Icon name="whatsapp" size={18} color={'#25D366'} /> WhatsApp Support
                      </Anchor>
                    </Banner>
                    <Banner>
                      <Anchor href={`https://www.youtube.com/playlist?list=PLqrGxhZbwrRHyT2xu2xPZrEmTZXijr3TW`} color='white'>
                        <Icon name="youtube" size={18} color={'white'} /> Watch Video
                      </Anchor>
                    </Banner>
                  </View>
                  <Text allowFontScaling={false} style={[CommonStyles.darkBannerText, CommonStyles.textSmall]}>One of your group member should 'Host Game' and share the invite code with you for a private Online Tambola game.</Text>
                </View>
                <View style={styles.backBtnContainer}>
                  <ThemeButton
                    type="secondary"
                    onPress={() => {
                      navigation.goBack();
                    }}
                    style={CommonStyles.squareBtn}
                  >
                    <Icon name="chevron-left" size={20} />
                  </ThemeButton>
                  {/* <ThemeButton
                    type="primary"
                    onPress={() => {
                      navigation.goBack();
                    }}
                  >
                    <Text style={[CommonStyles.textMedium, CommonStyles.whiteText]}><Icon name="chevron-left" size={16} /> Back</Text>
                  </ThemeButton> */}
                </View>
              </>
            )}
        </Formik>
      </ScrollView>
    </ScreenBackground>
  );
}

export default JoinScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    paddingTop: 30,
    justifyContent: "space-around",
    alignItems: "center",
  },
  backBtnContainer: {
    position: "absolute",
    top: Units.statusBarHeight,
    left: 10
  }
});
