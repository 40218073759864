import * as React from "react";
import Icon from 'react-native-vector-icons/FontAwesome';
import { useFocusEffect } from '@react-navigation/native';
import { TouchableOpacity, ScrollView, StyleSheet, View, Text, Image } from "react-native";
import { useSelector } from 'react-redux';
import Banner from "../components/GameElements/Banner";
import Anchor from '../components/Anchor';
import Colors from "../constants/Colors";

import { services } from '../store';
import CommonStyles from "../styles/common";
import ScreenBackground from "../components/ScreenBackground";
import ThemeButton from "../components/ThemeButton";
import Loader from "../components/Loader";
import AdsBanner from "../components/AdsBanner";

import { TicketCard } from "../components/Ticket/Ticket";
import Dimensions from '../constants/Layout';
import Units from "../constants/Units";
import { captureRef } from "react-native-view-shot";
import * as Analytics from 'expo-firebase-analytics';
import * as Sharing from 'expo-sharing';

const MiddleColumnWidth = Math.min(Math.max(Dimensions.window.width - 230, 400), 470);
// const MiddleColumnWidth = Math.min(Dimensions.window.width - 230, 470);

export const Illustration = ({ prize }) => {
  return (
    <View style={styles.illustration}>
      <View style={{ justifyContent: "space-between", flexDirection: "row" }}>
        <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>{prize.name}</Text>
        <Text style={[CommonStyles.whiteText, CommonStyles.textSmall]}><Text style={{ color: "red" }}>*</Text>sample ticket</Text>
      </View>
      <Text style={CommonStyles.whiteText}>{prize.description}</Text>
      <TicketCard
        sequence={prize.ticket && prize.ticket.map(cell => cell.number) || []}
        // ticketId={ticket._id}
        isApproved={true}
        isOwn={true}
        bogey={false}
        strikedInitial={prize.ticket && prize.ticket.map(cell => cell.ismatch) || []}
        shouldInitial={prize.ticket && prize.ticket.map(cell => cell.shouldmatch) || []}
        readOnly={true}
      />
    </View>
  )
}

function useCapture() {
  const captureViewRef = React.useRef();

  function onCapture() {
    captureRef(captureViewRef, {
    }).then(
      uri => {
        Sharing.shareAsync('file://' + uri, { dialogTitle: `Dividends for the Online Tambola Game` });
        Analytics.logEvent('dividends_share_success')
      },
      error => Analytics.logEvent('dividends_share_cancel')
    );
  }

  return {
    captureViewRef,
    onCapture
  };
}

const ShareDividends = ({ onPress, style }) => (
  <View style={style}>
    <TouchableOpacity onPress={onPress} style={{ flexDirection: 'row', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
      <View style={{ backgroundColor: "rgba(0, 0, 0, 0.8)", borderWidth: 2, borderStyle: "solid", borderColor: 'white', justifyContent: "center", alignSelf: "center", height: 42, paddingHorizontal: 10 }}>
        <Text allowFontScaling={false} style={[CommonStyles.whiteText, CommonStyles.textMedium]}>
          Share
        </Text>
      </View>
      <View style={{
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: Units.cornerRadius,
        borderBottomRightRadius: Units.cornerRadius,
        height: 42,
        width: 45,
        minWidth: "auto",
        paddingHorizontal: 8,
        backgroundColor: Colors.primaryColor,
        alignItems: "center",
        justifyContent: "center"
      }}>
        <Icon name="share-alt" size={20} color={Colors.white} />
      </View>
    </TouchableOpacity>
  </View>
)
export default function SettingsScreen({ navigation, route }) {
  const { params: { gameId } = {} } = route;
  const dividends = useSelector(state => state.dividends.queryResult?.data);
  const isLoading = useSelector(state => state.dividends.isLoading);
  const { captureViewRef, onCapture } = useCapture();

  useFocusEffect(
    React.useCallback(() => {
      services.dividends.find({ query: { gameId } });
    }, [gameId])
  );

  return (
    <ScreenBackground>
      <View style={[styles.container]}>
        <View style={[{ flexDirection: "row", margin: 10, alignItems: "center" }, CommonStyles.androidStatusBarSpace]}>
          <ThemeButton
            type="secondary"
            onPress={() => {
              navigation.goBack();
            }}
            style={CommonStyles.squareBtn}
          >
            <Icon name="chevron-left" size={20} />
          </ThemeButton>
          <View style={{ marginRight: 50, flexGrow: 1, alignItems: "center" }}>
            <Text allowFontScaling={false} style={[CommonStyles.whiteText, CommonStyles.textLarge]}>How to Claim?</Text>
          </View>
        </View>
        <ScrollView
          style={styles.container}
          contentContainerStyle={styles.contentContainer}
        >
          <View style={{ width: MiddleColumnWidth }}>
            <View style={{ flexGrow: 1, alignItems: "center", marginBottom: 15 }}>
              <Image
                source={{
                  uri: 'https://onlinetambolahousie.com/help.png',
                }}
                style={{ width: "100%", height: 185, marginBottom: 5, resizeMode: 'contain' }}
              />
              <Text style={[CommonStyles.whiteText, CommonStyles.textSmall, CommonStyles.textAlignCenter]}>If numbers cut on your ticket matches a rule, tap on it to claim 🏆</Text>
            </View>
            <View ref={captureViewRef} collapsable={false} style={{ backgroundColor: "#281121" }}>
              <View style={{ flexGrow: 1, alignItems: "center", marginBottom: 10 }}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textLarge]}>Rules</Text>
              </View>
              {
                (!isLoading && dividends && dividends.length) ? dividends.map(prize => <Illustration key={prize.name} prize={prize} />) : <Loader />
              }
            </View>
          </View>
          {/* <View style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", width: MiddleColumnWidth }}>
            <Banner>
              <Anchor href={`http://api.whatsapp.com/send?text=Hi, Need help on how to play the game. &phone=917477645641`}>
                <Icon name="whatsapp" size={18} color={'#25D366'} /> WhatsApp Support
                    </Anchor>
            </Banner>
            <Banner>
              <Anchor href={`https://www.youtube.com/playlist?list=PLqrGxhZbwrRHyT2xu2xPZrEmTZXijr3TW`} color='white'>
                <Icon name="youtube" size={18} color={'white'} /> Watch Video
              </Anchor>
            </Banner>
          </View> */}
          {/* <View style={{ height: 55 }}></View> */}
        </ScrollView>
        <ShareDividends style={{ position: "absolute", bottom: "25%", right: 0 }} onPress={onCapture} />
        {/* <AdsBanner placement="infoBottomFixed" fixed={true} width={MiddleColumnWidth}/> */}
      </View>
    </ScreenBackground>
  );
}

SettingsScreen.navigationOptions = {
  header: null,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    alignItems: "center"
  },
  illustration: {
    marginBottom: 20
  }
});
