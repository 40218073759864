import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import client from "../ApiClient";
import CommonStyles from "../styles/common";
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import Colors from "../constants/Colors";
import Units from "../constants/Units";

export default function PlayerClaims({gameId, playerId, botId, currDraw}) {
    const [onlineScore, setOnlineScore] = React.useState(0);
    React.useEffect(() => {
      if(!botId){
        client.service('online').get(null, { query: { gameId, playerId } })
        .then(res => {
            setOnlineScore(parseInt(res) || 0);
        });
      }
      else{
        setOnlineScore(currDraw);
      }
    }, [gameId, playerId, currDraw])

  return (
    <View style={styles.container}>
        <AnimatedCircularProgress
          arcSweepAngle={180}
          fill={100.0 * onlineScore/currDraw}
          tintColor={Colors.successColor}
          backgroundColor={Colors.backgroundColor}
          size={60}
          width={5}
        >{
            () => (<View>
                <Text allowFontScaling={false}
                style={[CommonStyles.darkText, CommonStyles.textAlignCenter, CommonStyles.textMedium, CommonStyles.textBold]}>
                {onlineScore}
                </Text>
                <Text allowFontScaling={false}
                style={[{color: '#333', fontSize: 8, lineHeight: 8}, CommonStyles.textAlignCenter]}>
                nos.
                </Text>
            </View>)
          }
        </AnimatedCircularProgress>
        <Text allowFontScaling={false} numberOfLines={1} style={[CommonStyles.darkText, styles.nameText]}>
            Online
        </Text>
    </View> 
  );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.secondaryColor,
        padding: 8,
        borderRadius: Units.cornerRadius,
        marginTop: 10
    },
    nameText: {
      ...CommonStyles.textSmallest,
      textAlign: "center",
      flexWrap: "wrap",
      width: "100%"
    }
});
  