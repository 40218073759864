const tintColor = "#2f95dc";
const primaryColor = "#DC5F5D";
const primaryColorGenieTheme = "#009587";
const successColor = "green";
const secondaryColor = "#F8CC53";
const secondaryColorGenieTheme = "#65CCFF";
const secondaryColorLight = "#F7DB98";
const secondaryColorLightGenieTheme = "#B6E8FF";
const darkTextColor = "#271120";
const grayTextColor = "#6b6b6b";

export default {
  primaryColor,
  primaryColorGenieTheme,
  secondaryColor,
  secondaryColorGenieTheme,
  successColor,
  secondaryColorLight,
  secondaryColorLightGenieTheme,
  backgroundColor: "#1F1B2E",
  white: "#fff",
  darkTextColor,
  grayTextColor,
  tintColor,
  tabIconDefault: "#ccc",
  tabIconSelected: tintColor,
  tabBar: "#fefefe",
  errorBackground: "red",
  errorText: "#fff",
  warningBackground: "#EAEB5E",
  warningText: "#666804",
  noticeBackground: tintColor,
  noticeText: "#fff",
};
