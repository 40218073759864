import React, { useEffect, useState } from 'react';
import ConfettiCannon from 'react-native-confetti-cannon';
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from '@reduxjs/toolkit';

import { clearClaim } from "../../reducers/currentClaim";

let explosion;

const newSuccessfulClaim = createSelector(
  state => state.currentClaim.claim,
  state => state.auth.user._id,
  (claim, currentUserId) => claim && !!claim.success && (claim.playerId === currentUserId)
)

const Cannon = () => {
  const dispatch = useDispatch();
  const newClaim = useSelector(newSuccessfulClaim);
  const [isClean, clean] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      clean(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [isClean]);

  useEffect(() => {
    newClaim && blowConfetti();
    if (newClaim) {
      const timer = setTimeout(() => {
        dispatch(clearClaim());
      }, 500)

      return () => clearTimeout(timer);
    }
  }, [newClaim]);

  return (
    (isClean) ? null :
      <ConfettiCannon
        count={75}
        origin={{ x: -20, y: 0 }}
        autoStart={false}
        onAnimationEnd={() => clean(true)}
        ref={ref => (explosion = ref)}
      />
  );
}

export const blowConfetti = () => {
  explosion && explosion.start();
}

export default React.memo(Cannon);