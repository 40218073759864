import * as React from "react";
import {
  Text,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import Units from "../constants/Units";
import Colors from "../constants/Colors";
import commonStyles from '../styles/common';

export default function ThemeButton({ text, type, style, children, ...props }) {
  return (
    <TouchableOpacity
      {...props}
      style={[
        styles.btn,
        styles[type],
        props.disabled ? styles.disabled : {},
        style,
      ]}
    >
      {
        children ? children :
          props.loading ? (
            <ActivityIndicator size="small" color={Colors.secondaryColor} />
          ) : (
              <Text allowFontScaling={false} style={[styles.btnText, styles[`${type}Text`], props.strikethrough ? commonStyles.textStrikethrough : {}]}>{text}</Text>
            )
      }
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  btn: {
    padding: 8,
    borderRadius: Units.cornerRadius,
    minWidth: 85,
    // shadowColor: Colors.white,
    // shadowOffset: {
    //   width: 2,
    //   height: 2,
    // },
    // shadowOpacity: 1,
    // shadowRadius: 1.0,
    justifyContent: "center",
    alignItems: "center",
    elevation: 3,
    overflow: "visible",
  },
  primary: {
    backgroundColor: Colors.primaryColor,
  },
  secondary: {
    backgroundColor: Colors.secondaryColor,
  },
  success: {
    backgroundColor: Colors.successColor,
  },
  deselected: {
    backgroundColor: Colors.grayTextColor,
  },
  link: {
    backgroundColor: "transparent",
    elevation: 0
  },
  infolink: {
    backgroundColor: "rgba(0, 0, 0, 0.9)",
  },
  facebook: {
    minWidth: 200,
    paddingHorizontal: 15,
    backgroundColor: "#1877f2",
  },
  outline: {
    borderWidth: 1,
    borderColor: Colors.white,
    paddingHorizontal: 5,
  },
  underline: {
    borderWidth: 0,
    paddingHorizontal: 5,
  },
  btnText: {
    textAlign: "center",
    fontSize: 18,
  },
  primaryText: {
    color: Colors.white,
  },
  secondaryText: {
    color: Colors.darkTextColor,
  },
  deselectedText: {
    color: Colors.white,
  },
  facebookText: {
    color: Colors.white,
  },
  outlineText: {
    color: Colors.white,
    fontSize: 15,
  },
  underlineText: {
    textDecorationLine: "underline",
    fontSize: 15,
  },
  disabled: {
    backgroundColor: "rgba(255, 237, 214, 0.41)",
    shadowOpacity: 0,
  }
});
