import { createSlice } from "@reduxjs/toolkit";

const ticketStrikes = createSlice({
  name: "ticketStrikes",
  initialState: {},
  reducers: {
    strikeNumber(state, { payload: { gameId, ticketId, numberIndex, striked } }) {
      if (!state[gameId]) {
        state[gameId] = {}
      }
      if (!state[gameId][ticketId]) {
        state[gameId][ticketId] = Array(27).fill(false);
      }
      state[gameId][ticketId][numberIndex] = striked;
    },
    deleteStrikesForGame(state, gameId) {
      delete state[gameId]
    }
  },
});

export const { strikeNumber, deleteStrikesForGame } = ticketStrikes.actions;

export default ticketStrikes.reducer;
