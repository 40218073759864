import { StyleSheet } from "react-native";
import Colors from "../constants/Colors";
import Units from '../constants/Units';

export default StyleSheet.create({
  whiteText: {
    color: Colors.white,
  },
  darkText: {
    color: Colors.darkTextColor,
  },
  grayText: {
    color: Colors.grayTextColor,
  },
  secondaryText: {
    color: Colors.secondaryColor,
  },
  textSmallest: { fontSize: 12 },
  textSmall: { fontSize: 15 },
  text16: { fontSize: 16 },
  textMedium: { fontSize: 18 },
  textLarge: { fontSize: 24 },
  textLarger: { fontSize: 30 },
  textLargest: { fontSize: 50 },
  textGiantMinor: { fontSize: 100 },
  textGiant: { fontSize: 150 },
  textBold: { fontWeight: "bold" },
  textAlignCenter: {
    textAlign: "center",
  },
  textAlignLeft: {
    textAlign: "left",
  },
  textAlignRight: {
    textAlign: "right",
  },
  textWrap: {
    flexWrap: 'wrap'
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  hSpaced: {
    marginLeft: 4
  },
  loveyDubey: {
    letterSpacing: -10
  },
  buttonContainer: {
    marginTop: 10,
    alignItems: "center",
  },
  squareBtn: {
    height: 40,
    minWidth: "auto",
    width: 40,
    elevation: 0
  },
  circularBtn: {
    height: 35,
    minWidth: "auto",
    width: 35,
    borderRadius: 18
  },
  leftEdgeFloatingBtn: {
    marginVertical: 10,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: Units.cornerRadius,
    borderBottomRightRadius: Units.cornerRadius,
    height: 40,
    width: 40,
    minWidth: "auto",
    paddingHorizontal: 8,
    zIndex: 999
  },
  row: {
    flexDirection: "row",
    marginVertical: 4
  },
  streachItems: {
    alignItems: 'stretch'
  },
  cover: {
    flex: 1,
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  darkBannerText: {
    fontSize: 16,
    // lineHeight: 22,
    color: Colors.white,
    textAlign: "center"
  },
  error: {
    color: 'red'
  },
  success: {
    color: Colors.successColor
  },
  modalHeaderCloseBtn: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 9000,
  },
  modalContentHeader: {
    alignItems: "center",
    paddingHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: "#281121"
  },
  modalContentInside: {
    paddingHorizontal: 15,
    maxWidth: 470,
    alignSelf: "center"
  },
  textStrikethrough: {
    textDecorationLine: 'line-through'
  },
  textUnderline: {
    textDecorationLine: 'underline'
  },
  androidStatusBarSpace: {
    marginTop: Units.statusBarHeight
  }
});
