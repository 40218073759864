import 'expo-dev-client';
import * as React from "react";
import { Platform, StyleSheet, LogBox } from "react-native";
import { StatusBar } from 'expo-status-bar';
import { hideNavigationBar } from 'react-native-navigation-bar-color';
import Purchases from 'react-native-purchases';
import * as SplashScreen from 'expo-splash-screen';
import * as Font from "expo-font";
import { Ionicons } from "@expo/vector-icons";
import * as Analytics from 'expo-firebase-analytics';
import { NavigationContainer } from "@react-navigation/native";
import { Provider } from "react-redux";
import { RootSiblingParent } from 'react-native-root-siblings';
import { PersistGate } from "redux-persist/integration/react";

import store, { persistor } from "./store";
import useLinking from "./navigation/useLinking";
import Navigator from './navigation';

import featherClient from "./ApiClient";
import { userAuthenticated } from "./reducers/auth";
import ScreenBackground from "./components/ScreenBackground";
import NetworkStatus from './components/NetworkStatus';

import * as Sentry from 'sentry-expo';

Sentry.init({
  dsn: 'https://5e106ecba1a54fd2bf67c1c42afd5aac@o421950.ingest.sentry.io/5342510',
  enableInExpoDevelopment: (Platform.OS !== "web"),
  debug: true,
  ignoreErrors: [
    'No gameId specified.',
    'Wait for Game to Start',
    'Game has ended',
    'No Claimable Ticket Found',
    'Similar claim repeated',
    'Only 2 minor claim allowed',
    'Only 1 Full House claim allowed',
    'Duplicate Claim not Allowed!',
    'Game not defined',
    'Game has already started',
    'tickets are allowed',
    'No record found for id',
    'this._listeners[_key] is not a function'
  ]
});

LogBox.ignoreAllLogs(true);

export default function App(props) {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);
  const [initialNavigationState, setInitialNavigationState] = React.useState();
  const navigationRef = React.useRef();
  const routeNameRef = React.useRef();
  const { getInitialState } = useLinking(navigationRef);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();

        // Load our initial navigation state
        setInitialNavigationState(await getInitialState());

        // Load fonts
        await Font.loadAsync({
          ...Ionicons.font,
          "space-mono": require("./assets/fonts/SpaceMono-Regular.ttf"),
        });

        // Re-authenticate user
        const response = await featherClient.reAuthenticate();
        store.dispatch(userAuthenticated(response.user));
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync();
      }
    }

    async function initPurchaseAsync(){
      __DEV__ && Purchases.setDebugLogsEnabled(true);
      Purchases.setup('uxhHumVTiDtPMpLGdKvvmRPqDxncnXBB', null, false);
    }

    loadResourcesAndDataAsync();
    if(Platform.OS !== 'web') {
      initPurchaseAsync();
    }
  }, []);

  // StatusBar.setStatusBarHidden(true, 'none');
  // StatusBar.setStatusBarTranslucent(true);
  hideNavigationBar();

  if (!isLoadingComplete && !props.skipLoadingScreen) {
    return null;
  } else {
    return (
      <RootSiblingParent>
        <ScreenBackground>
          {Platform.OS === "ios" ? <StatusBar style="auto" /> : Platform.OS === 'android' ? <StatusBar hidden={true} /> : null}
          <Provider store={store}>
            {/* <PersistGate loading={null} persistor={persistor}> */}
            <NavigationContainer
              ref={navigationRef}
              onReady={() => routeNameRef.current = navigationRef.current.getCurrentRoute().name}
              onStateChange={() => {
                const previousRouteName = routeNameRef.current;
                const currentRouteName = navigationRef.current.getCurrentRoute().name

                if (previousRouteName !== currentRouteName) {
                  Analytics.setCurrentScreen(currentRouteName);
                  Analytics.logEvent(`nav_${currentRouteName}`);
                }

                // Save the current route name for later comparision
                routeNameRef.current = currentRouteName;
              }}
              initialState={initialNavigationState}
            >
              <Navigator />
            </NavigationContainer>
            <NetworkStatus />
            {/* </PersistGate> */}
          </Provider>
        </ScreenBackground>
      </RootSiblingParent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
