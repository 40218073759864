import { createSlice } from "@reduxjs/toolkit";

const autoResponses = createSlice({
  name: "autoResponses",
  initialState: {
    list: []
  },
  reducers: {
    addSet(state, { payload: data }) {
      state.list = data.reverse();
    }
  },
});

export const { addSet } = autoResponses.actions;

export default autoResponses.reducer;
