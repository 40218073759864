import { Platform, NativeModules } from "react-native";

const deviceLanguage =
      Platform.OS === 'ios'
        ? NativeModules.SettingsManager.settings.AppleLocale ||
          NativeModules.SettingsManager.settings.AppleLanguages[0] //iOS 13
        : Platform.OS === 'ios'
          ? NativeModules.I18nManager.localeIdentifier
          : 'en';


export default deviceLanguage;
