import * as React from "react";
import * as Linking from 'expo-linking';
import { Text, StyleSheet } from 'react-native';
import Colors from "../constants/Colors";

const Anchor = ({ href, onPress, children, color, fontSize, ...props }) => {
  color = color || '#25D366';
  fontSize = fontSize || 16;
  const handlePress = React.useCallback(() => {
    Linking.openURL(href);
    onPress && onPress();
  }, [href, onPress]);

  return (
    <Text allowFontScaling={false} {...props} onPress={handlePress} style={[styles.anchor,{color: color, fontSize: fontSize}]}>
      {children}
    </Text>
  )
}

const styles = StyleSheet.create({
  anchor: {
    color: "#25D366",
    fontSize: 16,
    alignSelf: "center",
  }
})

export default Anchor;
