import * as React from "react";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
} from "@react-navigation/drawer";
import { useFocusEffect, StackActions } from '@react-navigation/native';
import Animated from "react-native-reanimated";
import { ScrollView, StyleSheet, View, Text, AppState, Button, Platform, Alert } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSelector } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import { Modalize } from "react-native-modalize";
import Icon from 'react-native-vector-icons/FontAwesome';
import * as Analytics from 'expo-firebase-analytics';
// import Modal from "react-native-modal";

import client from '../ApiClient';
import CommonStyles from "../styles/common";
import Colors from "../constants/Colors";
import Dimensions from '../constants/Layout';
import ResultsScreen from './ResultsScreen';
import SettingsScreen from './SettingsScreen';
import InfoScreen from './InfoScreen';
import ConfigScreen from './ConfigScreen';
import ScreenBackground from "../components/ScreenBackground";
import Loader from "../components/Loader";
import Board from "../components/Board";
import PlayerClaims from "../components/PlayerClaims";
import OnlineScore from "../components/OnlineScore";
import LastDrawnNumbers from "../components/GameElements/LastDrawnNumbers";
import ThemeButton from "../components/ThemeButton";
import Ticket from "../components/Ticket/Ticket";
import BuyTickets from "../components/Ticket/BuyTickets";
import PlayersList from "../components/GameElements/PlayersList";
import DrawNumber from "../components/GameElements/DrawNumber";
import ShareButton from "../components/GameElements/ShareButton";
import showToast from '../components/Toast';
import Anchor from '../components/Anchor';
import User from "../components/User";
import PinnedGame from "../components/PinnedGame";
import GetGenie from "../components/GetGenie";
import GameIntro from "../components/GameIntro";

// import GameNotifications from "../components/GameElements/GameNotifications";

import { services } from "../store";
import ConfettiCannon from "../components/Animations/ConfettiCannon";
import ClaimPrizes from "../components/GameElements/ClaimPrizes";
import Banner from "../components/GameElements/Banner";
import Units from "../constants/Units";
import userStateTypes from '../constants/UserStateTypes';
import { totalGameTicketsSelector, gameTicketsSelector, currentGameSelector, userSelector, lastDrawnNumbersSelector, playersSelector, prizesSelector, userHasGenieSelector, genieCounterSelector, restoredGenieSelector, diamondsSelector } from "../selectors";

import Chat from "../components/GameElements/Chat";
import CountdownTimer from "../components/GameElements/CountdownTimer";
import AdsBanner from "../components/AdsBanner";
import { useKeepAwake } from 'expo-keep-awake';
import GenieBanner from "../components/GameElements/GenieBanner";

const DrawerL = createDrawerNavigator();
const DrawerR = createDrawerNavigator();

const isGameOverSelector = createSelector(
  state => state.games?.data?.isOver,
  gameIsOver => gameIsOver
)
// const speakTextSelector = createSelector(
//   state => state.speak,
//   speak => speak.text
// )
const trackWhatsAppClick = () => Analytics.logEvent('whatsapp_support_click');

const gameProps = [
  '_id',
  'startAt',
  'scheduledAt',
  'updatedAt',
  'endAt',
  'pause',
  'currDraw',
  'hostId',
  'hostName',
  'public',
  'prevGameId',
  'auto',
  'maxTickets',
  'disallowLateClaims',
  'limitClaims',
  'limitConcurrentClaims',
  'autoApproveTickets',
  'restoreBougie',
  'prizes',
  'insightful',
  'autocheck',
  'genieUsers',
  'ticketCost',
  'accounting',
  'commission'
]
const randomItem = (items) => items[Math.floor(Math.random() * items.length)];
const messageOptions = {
  WELCOME_PLAYER: (data) => randomItem([`Welcome ${data.name}`, `${data.name.split(' ')[0]}, aap bhi khel rahe ho 😀`, `Swagat ${data.name.split(' ')[0]} 🙏`]),
  WISH_LUCK: (data) => randomItem([`Mera aashirwaad ✋ with ${data.name.split(' ')[0]}`, `${data.name.split(' ')[0]}, khoob jeetna 💰🤑`, `Bahot khoob ${data.name} 🙌`])
};

const fetchAllGameResources = async (gameId, navigation, dispatch) => {
  try {
    services.online.find({ query: { gameId } })
    await Promise.all([
      services.games.get(gameId, {
        query: {
          $select: gameProps
        }
      }),
      services.tickets.find({ query: { gameId, includeBougie: true } }),
      services.players.find({ query: { gameId, $select: ['_id', 'name', 'tickets', 'botId'] } }),
      services.drawer.find({ query: { gameId } }),
      services.claims.find({ query: { gameId, $select: ['_id', 'success', 'prize', 'playerId', 'playerName', 'currDraw', 'reward'] } }),
      services.prizes.find({ query: { gameId } }),
      services.userstates.find({ query: {type: {$in: [userStateTypes.GENIE, userStateTypes.PAIDGAME, userStateTypes.DIAMONDS]}} })
    ])
  } catch (error) {
    if (error.code === 404) {
      navigation.goBack() || navigation.navigate("Home");
      showToast(`Game ${gameId && gameId.toUpperCase()} has already ended`, 'bottom', 4000);
    }
    else{
      let errorString;
      try{
        errorString = JSON.stringify(error);
      }
      catch{
        errorString = error.message || error.code;
      }
      Analytics.logEvent('game_load_error', { error: errorString });
      //TODO: retry all
    }
  }
}

const MiddleColumnWidth = Math.min(Math.max(Dimensions.window.width - 230, 380), 470);

function LeftDrawerContent({ progress, ...rest }) {
  const translateX = Animated.interpolateNode(progress, {
    inputRange: [0, 1],
    outputRange: [-100, 0],
  });

  return (
    <DrawerContentScrollView {...rest}>
      <Animated.View style={{ transform: [{ translateX }] }}>
        <View>
          <Board />
        </View>
        {/* <DrawerItemList {...rest} /> */}
      </Animated.View>
    </DrawerContentScrollView>
  );
}

function LeftDrawer() {
  return (
    <DrawerR.Navigator
      initialRouteName="GameCenter"
      drawerPosition="left"
      drawerContent={(props) => <LeftDrawerContent {...props} />}
      drawerType={"slide"}
      drawerStyle={{
        backgroundColor: Colors.primaryColor,
        width: 405,
      }}
    >
      <DrawerR.Screen name="GameCenter" component={GameCenter} />
      <DrawerR.Screen name="ResultsScreen" component={ResultsScreen} />
      <DrawerR.Screen name="SettingsScreen" component={SettingsScreen} />
      <DrawerR.Screen name="InfoScreen" component={InfoScreen} />
      <DrawerR.Screen name="ConfigScreen" component={ConfigScreen} />
    </DrawerR.Navigator>
  );
}

function RightDrawer() {
  return (
    <DrawerL.Navigator initialRouteName="RightDrawer" drawerPosition="right">
      <DrawerL.Screen name="RightDrawer" component={LeftDrawer} />
    </DrawerL.Navigator>
  );
}

export default function GameScreen({ navigation, route }) {
  let { params: { gameId } = {} } = route;
  gameId = gameId.toLowerCase();
  const dispatch = useDispatch();

  useFocusEffect(
    React.useCallback(() => {
      const getGameData = fetchAllGameResources.bind(null, gameId, navigation, dispatch);
      getGameData();
      client.io.on("connect", getGameData);
      navigation.setParams({
        screen: "GameCenter",
        gameId: gameId
      })
      // Unsubscribe from room events on navigating away from game screen
      return () => {
        // services.games.reset();
        services.players.reset();
        services.tickets.reset();
        services.drawer.reset();
        services.claims.reset();
        services.prizes.reset();
        services.players.remove(null, { query: { leaveChannels: true } });
        services.online.reset();
        services.userstates.reset();
        client.io.off("connect", getGameData);
      }
    }, [gameId, dispatch])
  );

  return <LeftDrawer />;
}

function GameCenter({
  navigation,
}) {
  const currentGame = useSelector(currentGameSelector);
  const { _id: gameId, prevGameId, hostId, auto, pause, ticketCost, accounting, public: isPublic, hostName, autocheck, genieUsers } = currentGame;
  const user = useSelector(userSelector);
  const userHasGenie = useSelector(userHasGenieSelector);
  const genieCounter = useSelector(genieCounterSelector);
  const restoredGenie = useSelector(restoredGenieSelector);
  const diamonds = useSelector(diamondsSelector);
  const { _id: userId, name: username } = user;
  const isHost = hostId === userId;
  const draw = useSelector(lastDrawnNumbersSelector);
  const gameStarted = useSelector(lastDrawnNumbersSelector)?.length;
  const isTicketsLoading = useSelector(state => state.tickets.isLoading);
  const isPrizesLoading = useSelector(state => state.prizes.isLoading);
  const players = useSelector(playersSelector);
  const prizes = useSelector(prizesSelector);
  const isLoading = !gameId || !players.length || ((prizes.length == 0) && isPrizesLoading);
  // const tospeak = useSelector(speakTextSelector);
  const showResults = React.useCallback(
    () => navigation.navigate("ResultsScreen", { gameId, isHost }),
    [gameId, navigation]
  );
  const [muted, setMuted] = React.useState(false);
  React.useEffect(() => {
    AsyncStorage.getItem('muted', (error, result) => {
      setMuted(result && result != 'false');
    });
  }, []);

  React.useEffect(() => {
    !introShown && players.length == 1 && isHost && !gameStarted && showIntroModal();
  }, [players, introShown]);//TODO: should it be empty array? adding in-case player list is not initialised yet

  // React.useEffect(() => {
  //   tospeak && Speech.speak(tospeak,{
  //     language: 'hi-IN',
  //     rate: 1.0,
  //     pitch: 1.0
  //     //hi-IN
  //   });
  // }, [tospeak]);

  React.useEffect(() => {
    if (prevGameId && prevGameId !== gameId) {
      services.tickets.reset();
      services.players.reset();
      services.drawer.reset();
      services.claims.reset();
      services.prizes.reset();
      services.userstates.reset();
      fetchAllGameResources(gameId, navigation);
      navigation.navigate("GameArea", { screen: 'GameCenter', params: { gameId: gameId } });
    }
  }, [prevGameId, gameId])

  // const handleAppStateChange = React.useCallback((newState) => {
  //   if (newState === "active") {
  //     client.io.connect();
  //     // services.online.create({}, { query: { gameId } })
  //   } else {
  //     client.io.disconnect()
  //     // services.online.remove(null, { query: { gameId } })
  //   }
  // }, [gameId])

  // React.useEffect(() => {
  //   AppState.addEventListener('change', handleAppStateChange);

  //   return () => {
  //     AppState.removeEventListener('change', handleAppStateChange);
  //   }
  // }, []);

  const totalGameTickets = useSelector(totalGameTicketsSelector);
  const tickets = useSelector(gameTicketsSelector);
  const gameIsOver = useSelector(state => state.games?.data?._id === gameId && (state.games?.data?.endAt || state.games?.data?.isOver));

  const updatePrizes = useSelector(state => state.prizes.data?.update);
  React.useEffect(
    () => {
      if (updatePrizes) {
        dispatch({ type: "SERVICES_PRIZES_UPDATE_FULFILLED", payload: null });
        services.prizes.find({ query: { gameId: gameId } })
      }
    },
    [updatePrizes]
  );

  React.useEffect(
    () => gameIsOver && showResults(),
    [gameIsOver]
  )

  const openBoard = React.useCallback(() => navigation.openDrawer(), [navigation]);
  const navBack = React.useCallback(() => {
    navigation.navigate("Home");
  }, [navigation]);

  const dispatch = useDispatch();

  const [modalPlayer, setModalPlayer] = React.useState(null);
  const [modalType, setModalType] = React.useState(null);
  const [introShown, setIntroShown] = React.useState(false);
  const [canShowIntroModal, setCanShowIntroModal] = React.useState(false);
  const modalizeRef = React.useRef(null);

  React.useEffect(() => {
    AsyncStorage.getItem('gamesHosted', (error, result) => {
      const gamesHosted = result ? JSON.parse(result) : [];

      if(gamesHosted.length < 5 ) setCanShowIntroModal(true);
    });
  }, []);

  const canShowGenieBanner = !isPublic && !autocheck && (
    (( !gameStarted && (players.length > 1) && (tickets.length > 0)) || userHasGenie) || (gameStarted && genieUsers?.includes(userId))
  );
  
  const showGenieModal = React.useCallback(() => {
    async function askShowGenieModal() {
        // Possibly check here with BE if we can show genie or not
        setModalType('GENIE');
        modalizeRef.current?.open();
        Analytics.logEvent('genie_modal_shown');
    };
    askShowGenieModal();
  }, [modalizeRef]);
  const showIntroModal = React.useCallback(() => {
    function askShowIntroModal() {
        // Possibly check here with BE if we can show genie or not
        if(canShowIntroModal && !introShown) {
          setModalType('INTRO');
          setIntroShown(true);
          modalizeRef.current?.open();
        }
    };
    askShowIntroModal();
  }, [modalizeRef, introShown, canShowIntroModal]);
  const showModal = React.useCallback((player) => {
    player = player || user;
    if (isHost && !gameStarted && player._id !== userId) {
      setModalPlayer(player);
      setModalType('PLAYER');
      modalizeRef.current?.open();
      Analytics.logEvent('player_modal_shown');
    }
    else if(gameStarted){
      setModalPlayer(player);
      setModalType('CLAIMS');
      modalizeRef.current?.open();
      Analytics.logEvent('claims_modal_shown');
    }
    else if(player._id !== userId){
      services.notification.create({
        message: gameStarted ? messageOptions.WISH_LUCK(player) : messageOptions.WELCOME_PLAYER(player),
        gameId,
        to: player._id
      });
    }
  }, [isHost, gameStarted, userId, modalizeRef]);
  const hideModal = React.useCallback(() => {
    modalizeRef.current?.close();
  }, [modalizeRef]);

  const renderContent = () => {
    return modalType == 'PLAYER' ? modalPlayer && [
      <View style={CommonStyles.modalContentHeader} key="0">
        <Text numberOfLines={1} style={[CommonStyles.textLarge, CommonStyles.whiteText]}>
          Assign tickets to {modalPlayer.name}
        </Text>
        <ThemeButton
          type="link"
          style={[CommonStyles.squareBtn, CommonStyles.modalHeaderCloseBtn]}
          onPress={hideModal}
        >
          <Icon name="close" size={16} color={Colors.white} />
        </ThemeButton>
      </View>,
      <View style={{ paddingHorizontal: 15, paddingBottom: 10, alignItems: "flex-start", flexDirection: "row", justifyContent: "center" }} key="1">
        <View style={{ marginRight: 10 }}>
          <User
            name={modalPlayer.name.split(' ')[0]}
            picture={modalPlayer.picture}
            badge={modalPlayer._id === hostId ? true : false}
          >
            <View style={{ flexDirection: 'row', flexWrap: "wrap" }}>
              {modalPlayer.tickets.map(
                ticket => {
                  return (
                    <Icon
                      key={ticket._id}
                      name={'ticket'}
                      size={16}
                      color={ticket.bougie ? "crimson" : ticket.isApproved ? Colors.darkTextColor : "rgba(128, 128, 128,0.5)"}
                    />
                  )
                }
              )}
            </View>
          </User>
          {
            !(modalPlayer._id === hostId) ? <ThemeButton
              type="link"
              onPress={() => {
                Analytics.logEvent('player_remove');
                services.players.remove(modalPlayer._id, { query: { gameId } });
                hideModal();
              }}
              style={{ minWidth: "auto" }}
              text={<><Icon name='user-times' size={16} color={Colors.white} /> <Text style={[CommonStyles.whiteText, CommonStyles.textSmall, CommonStyles.textUnderline]}>Remove</Text></>}
            />
              :
              null
          }
        </View>
        <BuyTickets public={isPublic} accounting={accounting} ticketCost={ticketCost} gameId={gameId} isHost={isHost} player={modalPlayer} onSuccess={hideModal} type="set" />
      </View>
    ] :
    modalType == 'CLAIMS' ? 
    [
      <View style={CommonStyles.modalContentHeader} key="0">
        <Text numberOfLines={1} style={[CommonStyles.textLarge, CommonStyles.whiteText]}>
          Claims by {modalPlayer.name}
        </Text>
        <ThemeButton
          type="link"
          style={[CommonStyles.squareBtn, CommonStyles.modalHeaderCloseBtn]}
          onPress={hideModal}
        >
          <Icon name="close" size={16} color={Colors.white} />
        </ThemeButton>
      </View>,
      <View style={{ paddingHorizontal: 15, paddingBottom: 10, alignItems: "flex-start", flexDirection: "row", justifyContent: "center" }} key="1">
        <View style={{ marginRight: 10 }}>
          <User
            name={modalPlayer.name.split(' ')[0]}
            picture={modalPlayer.picture}
            badge={modalPlayer._id === hostId ? true : false}
          >
          </User>
          <View>
            <OnlineScore gameId={gameId} playerId={modalPlayer._id} botId={modalPlayer.botId} currDraw={gameStarted}/>
          </View>
        </View>
        <PlayerClaims gameId={gameId} playerId={modalPlayer._id}/>
      </View>
    ] : 
    modalType == 'GENIE' ? 
    [
      <View style={CommonStyles.modalContentHeader} key="2">
        {/* <Text numberOfLines={1} style={[CommonStyles.textLarge, CommonStyles.whiteText]}>
          Never miss a Prize
        </Text> */}
        <ThemeButton
          type="link"
          style={[CommonStyles.squareBtn, CommonStyles.modalHeaderCloseBtn]}
          onPress={hideModal}
        >
          <Icon name="close" size={16} color={Colors.white} />
        </ThemeButton>
      </View>,
      <View style={{ paddingHorizontal: 15, paddingBottom: 10, alignItems: "flex-start", flexDirection: "row", justifyContent: "center" }} key="1">
        {/* <GetGenie gameId={gameId} conf={genieConf}/> */}
        <GetGenie hasGenie={userHasGenie} restoredGenie={restoredGenie} genieCounter={genieCounter}/>
      </View>
    ] : 
    modalType == 'INTRO' ? 
    [
      <View style={CommonStyles.modalContentHeader} key="2">
        <Text numberOfLines={1} style={[CommonStyles.textLarge, CommonStyles.whiteText]}>
        Player Invite Code 👇🏼
        </Text>
        <ThemeButton
          type="link"
          style={[CommonStyles.squareBtn, CommonStyles.modalHeaderCloseBtn]}
          onPress={hideModal}
        >
          <Icon name="close" size={16} color={Colors.white} />
        </ThemeButton>
      </View>,
      <View style={{ paddingHorizontal: 15, paddingBottom: 10, alignItems: "flex-start", flexDirection: "row", justifyContent: "center" }} key="1">
        {/* <GetGenie gameId={gameId} conf={genieConf}/> */}
        <GameIntro  gameId={gameId} game={currentGame}/>
      </View>
    ] : null
  }
  useKeepAwake();
  return (
    <ScreenBackground>
      {isLoading ? (
        <Loader />
      ) : (
          <View style={styles.container}>
            <View style={{ flexDirection: "row" }}>
              <ThemeButton
                style={[CommonStyles.leftEdgeFloatingBtn, CommonStyles.androidStatusBarSpace]}
                onPress={openBoard}
                type='primary'
              >
                <Icon name="expand" size={18} color={Colors.white} />
              </ThemeButton>
              <View
                style={[{ flexDirection: "row", margin: 10, alignItems: "center", flexGrow: 1 }, CommonStyles.androidStatusBarSpace]}
              >
                {
                  !isHost && !gameStarted ?
                    <CountdownTimer />
                    :
                    <LastDrawnNumbers isHost={isHost} openBoard={openBoard} gameIsOver={gameIsOver} totalGameTickets={totalGameTickets} timer={auto} muted={muted} />
                }
                {
                  (isHost && !gameIsOver) ?
                    (
                      totalGameTickets > 0 ?
                        gameStarted && auto ?
                          !pause ?
                            <ThemeButton
                              type={"secondary"}
                              onPress={() => {
                                Analytics.logEvent('game_pause_button_clicked');
                                services.games.patch(gameId, { pause: true }, {
                                  query: {
                                    $select: gameProps
                                  }
                                })
                              }}
                              text={<Icon name='pause-circle-o' size={20} />}
                              style={CommonStyles.squareBtn}
                            />
                            :
                            <ThemeButton
                              type={"secondary"}
                              onPress={() => {
                                Analytics.logEvent('game_resume_button_clicked');
                                services.games.patch(gameId, { pause: false }, {
                                  query: {
                                    $select: gameProps
                                  }
                                })
                              }}
                              text={<Icon name='caret-square-o-right' size={20} />}
                              style={CommonStyles.squareBtn}
                            />
                          :
                          <DrawNumber gameId={gameId} />
                        :
                        null
                    )
                    :
                    gameIsOver ?
                      <ThemeButton
                        type="secondary"
                        onPress={showResults}
                        text="Results"
                        style={{ height: 40 }}
                      /> : null

                  // <View style={{ width: 50, height: 50 }} />
                }
                {
                  (!gameIsOver && (!isHost || (totalGameTickets <= 0))) ?
                    <ThemeButton
                      type="link"
                      onPress={() => {
                        navigation.navigate("InfoScreen", { gameId });
                        Analytics.logEvent('info_secondary_button_clicked');
                      }}
                      type="secondary"
                      style={{ height: 40, minWidth: "auto", width: 40 }}
                    >
                      <Icon name="info-circle" size={20} />
                    </ThemeButton>
                    :
                    null
                }
                <ThemeButton
                  type="link"
                  onPress={navBack}
                  style={{ height: 40, minWidth: "auto", width: 40, marginLeft: 10, elevation: 0 }}
                >
                  <Icon name="close" size={20} color="white" />
                </ThemeButton>
                {/* <Button
                  title=">>"
                  color={Colors.primaryColor}
                  onPress={() => navigation.dangerouslyGetParent().openDrawer()}
                  style={{ marginLeft: "auto" }}
                /> */}
              </View>
            </View>
            <View>
              <View style={{ zIndex: 1, flexDirection: "row", justifyContent: "space-evenly", height: Units.gameScrollableAreaHeight }}>
                <View style={{ zIndex: 3 }}>
                  <PlayersList onPlayerTap={showModal} gameStarted={gameStarted} accounting={accounting}/>
                  <Chat />
                </View>
                <View style={{ zIndex: 2, flexShrink: 1, width: MiddleColumnWidth }}>
                  <ScrollView contentContainerStyle={[styles.contentContainer]} fadingEdgeLength={10}>
                    {isHost && !gameStarted ? <Text allowFontScaling={false} style={[CommonStyles.darkBannerText, { marginBottom: 10 }, CommonStyles.text16]}>Player Invite Code 👇🏼</Text> : null}
                    {
                      !gameStarted ? <View style={[{ flexDirection: 'row', alignSelf: "stretch", alignItems: "center", justifyContent: "space-evenly", marginBottom: 10 }]}>
                        <ShareButton gameId={gameId} game={currentGame} />
                      </View> : null
                    }
                    {
                      !isTicketsLoading && !tickets.length && gameStarted ? (
                        <Banner>
                          <Text style={CommonStyles.darkBannerText}>
                            {
                              isHost ?
                                `You're hosting this game and do not have any tickets. Have fun calling the numbers & organising the game 😎`
                                :
                                isPublic ?
                                  `Game has already started, you don't have any tickets.${"\n"}Click on 'Show My Ticket' before the game starts.` :
                                  `Game has already started, you don't have any tickets however you can watch or join next game 🍿😎`
                            }
                          </Text>
                          {
                            isHost ? null :
                              <View style={{ marginTop: 10 }}>
                                <Text allowFontScaling={false} style={[CommonStyles.secondaryText]}>Join next game</Text>
                                <PinnedGame navigation={navigation} />
                              </View>
                          }
                        </Banner>) : null
                    }
                    {canShowGenieBanner ? <GenieBanner showModal={showGenieModal} hasGenie={userHasGenie} restoredGenie={restoredGenie} genieCounter={genieCounter}/>: null}
                    {tickets.map((ticket) => (
                      <Ticket
                        sequence={ticket.numbers}
                        ticketId={ticket._id}
                        isApproved={ticket.isApproved}
                        isHost={isHost}
                        gameId={gameId}
                        playerId={ticket.playerId}
                        playerName={ticket.playerName}
                        bogey={ticket.bougie}
                        readOnly={ticket.bougie}
                        isOwn={ticket.playerId === userId}
                        gameIsOver={gameIsOver}
                        key={ticket._id}
                        draw={draw}
                        auto={autocheck || (!isPublic && genieUsers?.includes(userId))}
                        genie={canShowGenieBanner && ((gameStarted && genieUsers?.includes(userId)) || (!gameStarted && userHasGenie))}
                      />
                    ))}
                    {(gameIsOver || gameStarted) ? null : <>
                      <BuyTickets public={isPublic} accounting={accounting} ticketCost={ticketCost} gameId={gameId} isHost={isHost} player={user} type="individual" userHasGenie={ canShowGenieBanner && userHasGenie} />
                    </>}
                    {
                      !gameStarted ? <AdsBanner placement="ticketsContainer" width={MiddleColumnWidth} /> : null
                    }
                    <View style={{ flexDirection: "row", alignSelf: "stretch", justifyContent: "space-between", flexWrap: "wrap" }}>
                      {
                        isHost && !gameStarted ?
                          <ThemeButton
                            type="link"
                            onPress={() => {
                              navigation.navigate("SettingsScreen", { gameId });
                              Analytics.logEvent('settings_button_center_clicked');
                            }}
                            text={<><Icon name='gear' size={16} color={Colors.white} /> <Text style={[CommonStyles.whiteText, CommonStyles.text16]}>Game Settings</Text></>}
                          />
                          : null
                      }
                      <Banner>
                        <Anchor href={`https://api.whatsapp.com/send?text=Hi, I had a query on ${isHost ? "my" : hostName + "'s"} Online Tambola game https://onlinetambolahousie.com/game/${gameId.toUpperCase()} - ${username} &phone=917477645641`} onPress={trackWhatsAppClick}>
                          <Icon name="whatsapp" size={18} color={'#25D366'} /> Support
                        </Anchor>
                      </Banner>
                      {
                        ticketCost && diamonds != null && isPublic ? 
                        <Banner>
                          <Text allowFontScaling={false} style={[CommonStyles.text16, CommonStyles.whiteText, CommonStyles.textBold, CommonStyles.hSpaced, CommonStyles.textAlignCenter]}>{diamonds} 💎</Text>
                        </Banner> : null
                      }
                      <ThemeButton
                        type="link"
                        onPress={() => {
                          navigation.navigate("InfoScreen", { gameId });
                          Analytics.logEvent('info_button_footer');
                        }}
                        text={<><Icon name='info-circle' size={16} color={Colors.white} /> <Text style={[CommonStyles.whiteText, CommonStyles.text16]}>Rules</Text></>}
                      />
                    </View>
                  </ScrollView>
                </View>
                <ClaimPrizes navigation={navigation} gameId={gameId} accounting={accounting} isPublic={isPublic}/>
              </View>
            </View>
            {
              gameStarted ?
                <View style={{ position: "absolute", bottom: ((isHost && !gameStarted) || userId === '5ee4110d89bc480017461da2') ? 146 : 104 }}>
                  <ThemeButton
                    style={CommonStyles.leftEdgeFloatingBtn}
                    onPress={() => showModal(user)}
                    type='infolink'
                  >
                    <Icon name="trophy" size={18} color={Colors.white} />
                  </ThemeButton>
                </View>
                : null
            }
            {
              muted ?
                <View style={{ position: "absolute", bottom: ((isHost && !gameStarted) || userId === '5ee4110d89bc480017461da2') ? 104 : 62 }}>
                  <ThemeButton
                    style={CommonStyles.leftEdgeFloatingBtn}
                    onPress={() => {
                      setMuted(false);
                      try {
                        AsyncStorage.setItem(
                          'muted',
                          'false'
                        );
                      } catch (error) {
                        // Error saving data
                      }
                    }}
                    type='infolink'
                  >
                    <Icon name="volume-off" size={18} color={Colors.white} />
                  </ThemeButton>
                </View> :
                <View style={{ position: "absolute", bottom: ((isHost && !gameStarted) || userId === '5ee4110d89bc480017461da2') ? 104 : 62 }}>
                  <ThemeButton
                    style={CommonStyles.leftEdgeFloatingBtn}
                    onPress={() => {
                      setMuted(true);
                      try {
                        AsyncStorage.setItem(
                          'muted',
                          'true'
                        );
                      } catch (error) {
                        // Error saving data
                      }
                    }}
                    type='infolink'
                  >
                    <Icon name="volume-up" size={18} color={Colors.white} />
                  </ThemeButton>
                </View>
            }
            {
              ((isHost && !gameStarted) || userId === '5ee4110d89bc480017461da2' || userId === '5f0ce808ae1c580017ffcf3a') ?
                <View style={{ position: "absolute", bottom: 62 }}>
                  <ThemeButton
                    style={CommonStyles.leftEdgeFloatingBtn}
                    onPress={() => {
                      navigation.navigate("SettingsScreen", { gameId });
                      Analytics.logEvent('settings_button_floating_clicked');
                    }}
                    type='infolink'
                  >
                    <Icon name="gear" size={18} color={Colors.white} />
                  </ThemeButton>
                </View>
                : null
            }
            {(isHost && !gameIsOver && (totalGameTickets > 0)) ?
              <View style={{ position: "absolute", bottom: 20 }}>
                <ThemeButton
                  style={CommonStyles.leftEdgeFloatingBtn}
                  onPress={() => {
                    navigation.navigate("InfoScreen", { gameId });
                    Analytics.logEvent('info_primary_button_clicked');
                  }}
                  type='infolink'
                >
                  <Icon name="info-circle" size={18} color={Colors.white} />
                </ThemeButton>
              </View> : null
            }
          </View>
        )
      }
      <Modalize
        ref={modalizeRef}
        adjustToContentHeight={modalType == 'PLAYER'}
        modalStyle={[{ backgroundColor: "#281121", zIndex: 999}, modalType == 'CLAIMS' ? {marginHorizontal: '15%'} : {marginHorizontal: (Dimensions.window.width - 420) / 2}]}
      >
        {renderContent()}
      </Modalize>
      <ConfettiCannon />
    </ScreenBackground >
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    justifyContent: "flex-start",
    flexGrow: 1,
    alignItems: "center",
    padding: 10
  },
});
