import { combineReducers } from "@reduxjs/toolkit";
import { rawServices } from "../ApiClient";
import auth from "./auth";
import bogeys from "./bogeys";
import ticketStrikes from "./ticketStrikes";
import autoResponses from "./autoResponses";
// import speak from "./speak";
import online from "./online";
import currentClaim from "./currentClaim";
import dividendsShortlist from "./dividendsShortlist";

const rootReducer = combineReducers({
  auth,
  bogeys,
  ticketStrikes,
  autoResponses,
  // speak,
  online,
  currentClaim,
  dividendsShortlist,
  users: rawServices.users.reducer,
  games: rawServices.games.reducer,
  players: rawServices.players.reducer,
  tickets: rawServices.tickets.reducer,
  drawer: rawServices.drawer.reducer,
  prizes: rawServices.prizes.reducer,
  claims: rawServices.claims.reducer,
  notification: rawServices.notification.reducer,
  dividends: rawServices.dividends.reducer,
  userstates: rawServices.userstates.reducer,
});

export default rootReducer;
