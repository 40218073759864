import * as React from "react";
import { FlatList, TouchableOpacity, StyleSheet, Text, View } from "react-native";
import { useSelector } from 'react-redux';
import * as Animatable from 'react-native-animatable';
import Icon from 'react-native-vector-icons/FontAwesome';

import User from "../User";
import Colors from "../../constants/Colors";
import Units from "../../constants/Units";
import CommonStyles from "../../styles/common";
import { playersSelector, onlinePlayersMapSelector, currentGameSelector } from '../../selectors/';

const PlayersList = ({ onPlayerTap, gameStarted, accounting }) => {
  const players = useSelector(playersSelector);
  const onlinePlayers = useSelector(onlinePlayersMapSelector)
  const { hostId, public: isPublicGame, _id: gameId } = useSelector(currentGameSelector);
  const keyExtractor = React.useCallback(item => item._id);

  const renderPlayer = React.useCallback(({ item: player }) => {
    return (
      <Animatable.View animation="fadeInUp" useNativeDriver style={styles.player}>
        <TouchableOpacity onPress={() => onPlayerTap(player)}>
          <User
            name={player.name.split(' ')[0]}
            picture={player.picture}
            badge={player._id === hostId ? true : false}
            online={player.botId || onlinePlayers[player._id] || (isPublicGame && (player._id === hostId))}
          >
            <View style={{ flexDirection: 'row', flexWrap: "wrap" }}>
              {
                player.tickets ?
                  player.tickets.reduce((acc, ticket) => {
                    ticket.bougie ? acc[2]++ : (ticket.isApproved ? acc[1]++ : acc[0]++);
                    return acc;
                  }, [0,0,0,player.claims,player.rewards])//[pending,approved,bougie]
                  .map((ctr, index) => {
                    return ctr ? (index == 4 ? 
                      <Text key={player._id + '_badge_' + index} allowFontScaling={false} style={[CommonStyles.textBold, CommonStyles.textSmallest, CommonStyles.darkText, CommonStyles.hSpaced]}>+{accounting && '₹'}{ctr}{!accounting && '💎'}</Text>
                    : (index == 3 ? 
                      <Text key={player._id + '_badge_' + index} allowFontScaling={false} style={[CommonStyles.textBold, CommonStyles.textSmallest, CommonStyles.darkText, CommonStyles.hSpaced]}>{ctr}🏆</Text>
                    : (
                      <Text key={player._id + '_badge_' + index} allowFontScaling={false} style={[CommonStyles.textBold, CommonStyles.textSmallest, CommonStyles.hSpaced, {color: index == 0 ? "rgba(128, 128, 128,0.5)" : (index == 1 ? Colors.darkTextColor : "crimson")}]}>{ctr}
                        <Icon
                          name={'ticket'}
                          size={12}
                          color={index == 0 ? "rgba(128, 128, 128,0.5)" : (index == 1 ? Colors.darkTextColor : "crimson")}
                        />
                      </Text>
                      
                    ))) : null
                  }) : null
              }
            </View>
          </User>
        </TouchableOpacity>
      </Animatable.View>
    )
  }, [onPlayerTap]);

  return players.length ? (
    <View style={[styles.container, { height: Units.gameScrollableAreaHeight - 35 }]}>
      <FlatList
        fadingEdgeLength={10}
        data={players}
        ListHeaderComponent={
          <View style={{ alignItems: "center" }}>
            <Text allowFontScaling={false} numberOfLines={1} style={[CommonStyles.whiteText, CommonStyles.textSmall]}>
              {
              gameStarted ?
              `${players.filter((player) => player.tickets && player.tickets.length).length} 🙋‍♂️🙋‍♀️` :
              `${players.filter((player) => player.tickets && player.tickets.some((ticket) => ticket.isApproved)).length}/${players.length} 🙋‍♂️🙋‍♀️`
              }
            </Text>
          </View>
        }
        keyExtractor={keyExtractor}
        renderItem={renderPlayer}
      />
    </View>
  ) : null;
};

export default React.memo(PlayersList);

const styles = StyleSheet.create({
  container: {
    width: 75,
    paddingTop: 10,
    alignItems: "stretch"
  },
  player: {
    marginVertical: 5,
  },
  playerText: { color: Colors.darkTextColor, fontWeight: "bold" },
});
