import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";

import Colors from "../constants/Colors";
import { lastDrawnNumbersSelector } from "../selectors";

function Board() {
  const draw = useSelector(lastDrawnNumbersSelector)
  const allNumbers = React.useMemo(
    () =>
      Array(90)
        .fill()
        .map((num, index) => index + 1),
    []
  );
  return (
    <View style={styles.board}>
      {allNumbers.map((number) => (
        <View
          style={[
            styles.boardNumber,
            draw.includes(number) ? { backgroundColor: Colors.white } : {},
          ]}
          key={number}
        >
          <Text
            style={{
              color: draw.includes(number)
                ? Colors.darkTextColor
                : Colors.white,
            }}
          >
            {number}
          </Text>
        </View>
      ))}
    </View>
  );
}

export default React.memo(Board);

const styles = StyleSheet.create({
  board: {
    flexDirection: "row",
    flexWrap: "wrap",
    padding: 15,
  },
  boardNumber: {
    width: 30,
    height: 30,
    margin: 3,
    borderRadius: 15,
    overflow: "hidden", //This does the trick for border radius on Android
    alignItems: "center",
    justifyContent: "center",
  },
});
