import * as React from "react";
import { StyleSheet, Text, View, Image } from "react-native";
import UserAvatar from 'react-native-user-avatar';
import Icon from 'react-native-vector-icons/FontAwesome';

import commonStyles from "../styles/common";
import Colors from "../constants/Colors";
import Units from "../constants/Units";
import * as Animatable from 'react-native-animatable';

const Avatar = ({ picture, name, size = 50, borderRadius = Units.cornerRadius }) => {
  {/* Todo: use just UserAvatar. Make it work for loading src image when available */ }
  {/* ISSUE: Error fetching source, falling back to initials TypeError: Cannot read property 'content-type' of undefined */ }
  const nameForAvatar = name.split(' ').map(word => word.charAt(0).toUpperCase() + word.toLowerCase().slice(1)).slice(0, 4).join(' ');
  return (
    !picture ? <UserAvatar
      size={size}
      borderRadius={borderRadius}
      src={picture}
      name={nameForAvatar}
      bgColors={['#545454', '#3e293b', '#843c60', '#63baff', '#283d8a']}
    />
      :
      <Image
        style={[styles.avatar, { borderRadius: borderRadius, width: size, height: size }]}
        source={{
          uri: picture,
        }}
      />
  )
}

const User = ({ name, picture, badge, online, children, style, editable }) => {
  return (
    <View style={[styles.container, style]}>
      <Avatar name={name} picture={picture} />
      {
        badge ?
          <View style={styles.badge}>
            <Icon name="star" size={17} color={online ? 'green' : Colors.primaryColor} />
            <Icon name="star-o" size={18} color={Colors.secondaryColor} style={{ position: "absolute" }} />
            {/* <Text style={styles.badgeText}>{badge}</Text> */}
          </View>
          : online ?
            <Animatable.View animation="zoomIn" useNativeDriver style={styles.dot} />
            : null
      }
      <Text allowFontScaling={false} numberOfLines={1} style={[commonStyles.darkText, styles.nameText]}>
        {name}
      </Text>
      {editable && 
        <View style={{flexDirection: "row", alignItems: "center"}}>
          <Icon name='edit' size={12} color={Colors.grayTextColor} />
          <Text allowFontScaling={false} style={[commonStyles.textSmallest, commonStyles.grayText]}>edit</Text>
        </View>
      }
      {children}
    </View>
  );
}

export { Avatar };
export default User

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flexWrap: "wrap",
    padding: 10,
    backgroundColor: Colors.secondaryColor,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: Units.cornerRadius
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: Units.cornerRadius
  },
  badge: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    right: 5,
    top: 50
  },
  dot: {
    position: "absolute",
    right: 10,
    top: 45,
    height: 16,
    width: 16,
    backgroundColor: Colors.successColor,
    borderWidth: 2,
    borderColor: Colors.secondaryColor,
    borderRadius: 8,
  },
  nameText: {
    ...commonStyles.textSmallest,
    textAlign: "center",
    flexWrap: "wrap",
    width: "100%"
  }
});
