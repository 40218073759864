import * as React from "react";
import ReactDOM from 'react-dom'
import { StyleSheet, Text, View, Image, TouchableOpacity, Alert } from "react-native";
import * as Animatable from 'react-native-animatable';
import Icon from 'react-native-vector-icons/FontAwesome';

import CommonStyles from "../styles/common";
import Colors from "../constants/Colors";
import Units from "../constants/Units";
import Anchor from "./Anchor";
import Banner from "./GameElements/Banner";
import ShareButton from "./GameElements/ShareButton";

const GameIntro = React.memo(
    ({ gameId, game }) => (
        <View style={styles.container}>
            {/* <Text style={[CommonStyles.textAlignCenter, CommonStyles.whiteText, CommonStyles.text16, CommonStyles.textBold]}>Player Invite Code 👇🏼</Text> */}

            <Animatable.View useNativeDriver animation="pulse" iterationCount="infinite" iterationDelay={3000}
                style={[{ flexDirection: 'row', alignSelf: "stretch", alignItems: "center", justifyContent: "space-evenly", marginBottom: 10 }]}>
                <ShareButton gameId={gameId} game={game} textStyle={[CommonStyles.textLarge]}/>
            </Animatable.View>

            {/* <Text style={[CommonStyles.textAlignCenter, CommonStyles.whiteText, CommonStyles.text16, CommonStyles.textBold]}>Let's invite your <Text style={[CommonStyles.secondaryText]}>Friends</Text> & <Text style={[CommonStyles.secondaryText]}>Family</Text></Text> */}
            <View style={[{paddingBottom: 10}]}>
                <Banner style={{ marginBottom: 10 ,padding: 0}}>
                   <View style={{flexDirection: "row", justifyContent: "space-around", padding: 10}}>
                        {/* <View style={{flex: 1}}>
                            <Text></Text>
                        </View> */}
                        <View style={{flex: 15}}>
                            <Text style={[CommonStyles.whiteText, CommonStyles.text16, CommonStyles.textAlignCenter]}>Tap above 👆🏼 to invite your WhatsApp friends</Text>
                            <Text style={[CommonStyles.whiteText, CommonStyles.text16, CommonStyles.textAlignCenter]}>You are the <Text style={[CommonStyles.secondaryText]}>organiser</Text> of this private game</Text>
                        </View>
                    </View>
                   {/* <View style={{flexDirection: "row", justifyContent: "space-around", padding: 10}}>
                        <View style={{flex: 1}}>
                            <Text></Text>
                        </View>
                        <View style={{flex: 15}}>
                            <Text style={[CommonStyles.whiteText, CommonStyles.text16]}>Let's <Text style={[CommonStyles.secondaryText]}>Invite</Text> players</Text>
                        </View>
                    </View>
                   <View style={{flexDirection: "row", justifyContent: "space-around", padding: 10}}>
                        <View style={{flex: 1}}>
                            <Text></Text>
                        </View>
                        <View style={{flex: 15}}>
                            <Text style={[CommonStyles.whiteText, CommonStyles.text16]}>Tap on the above button to send invitation to your friend/family WhatsApp group. Or share the invite code <Text style={[CommonStyles.secondaryText]}>{gameId.toUpperCase()}</Text> with them</Text>
                        </View>
                    </View> */}
                </Banner>
            </View>
            <View style={[{paddingBottom: 10, paddingTop: 5}]}>
                <Text style={[CommonStyles.whiteText, CommonStyles.text16, CommonStyles.textBold]}><Text style={[CommonStyles.secondaryText]}>Tips</Text> to organise your game</Text>
                <Banner dark style={{marginTop: 10, marginBottom: 10 ,padding: 0}}>
                    <View style={{flexDirection: "row", justifyContent: "space-around", padding: 10}}>
                        <View style={{flex: 1}}>
                            <Text><Icon name='gear' size={16} color={Colors.white} /></Text>
                        </View>
                        <View style={{flex: 15}}>
                            <Text style={[CommonStyles.whiteText]}>Game Settings - Controls for your game</Text>
                        </View>
                    </View>
                    <View style={{flexDirection: "row", justifyContent: "space-around", padding: 10}}>
                        <View style={{flex: 1}}>
                            <Text><Icon name='info-circle' size={16} color={Colors.white} /></Text>
                        </View>
                        <View style={{flex: 15}}>
                            <Text style={[CommonStyles.whiteText]}>Rules - Prize explanation with examples</Text>
                        </View>
                    </View>
                    <View style={{flexDirection: "row", justifyContent: "space-around", padding: 10}}>
                        <View style={{flex: 1}}>
                            <Text><Icon name='ticket' size={16} color={Colors.white} /></Text>
                        </View>
                        <View style={{flex: 15}}>
                            <Text style={[CommonStyles.whiteText]}>E-Tickets - Players can request after joining</Text>
                        </View>
                    </View>
                    <View style={{flexDirection: "row", justifyContent: "space-around", padding: 10}}>
                        <View style={{flex: 1}}>
                            <Text><Icon name='caret-square-o-right' size={16} color={Colors.white} /></Text>
                        </View>
                        <View style={{flex: 15}}>
                            <Text style={[CommonStyles.whiteText]}>Call - Start calling numbers when ready</Text>
                        </View>
                    </View>
                    <View style={{flexDirection: "row", justifyContent: "space-around", padding: 10}}>
                        <View style={{flex: 1}}>
                            <Text><Icon name='expand' size={16} color={Colors.white} /></Text>
                        </View>
                        <View style={{flex: 15}}>
                            <Text style={[CommonStyles.whiteText]}>Board - Show all numbers</Text>
                        </View>
                    </View>
                </Banner>
                <Banner dark style={{marginTop: 10, marginBottom: 10 ,padding: 0}}>
                    <View style={{flexDirection: "row", justifyContent: "space-around", padding: 10}}>
                        <View style={{flex: 1}}>
                            <Text>🏆</Text>
                        </View>
                        <View style={{flex: 15}}>
                            <Text style={[CommonStyles.whiteText]}>Explore 80+ prize dividends/variations</Text>
                        </View>
                    </View>
                    <View style={{flexDirection: "row", justifyContent: "space-around", padding: 10}}>
                        <View style={{flex: 1}}>
                            <Text>🙋‍♂️</Text>
                        </View>
                        <View style={{flex: 15}}>
                            <Text style={[CommonStyles.whiteText]}>App supports upto 1000 players in a single game 🙋‍♀️</Text>
                        </View>
                    </View>
                </Banner>
                {/* <Banner dark style={{marginTop: 10, marginBottom: 10 ,padding: 0}}>
                    <View style={{flexDirection: "row", justifyContent: "space-around", padding: 10}}>
                        <View style={{flex: 1}}>
                            <Text>📝</Text>
                        </View>
                        <View style={{flex: 15}}>
                            <Text style={[CommonStyles.whiteText]}>Numbers are not auto cut, just like real housie</Text>
                        </View>
                    </View>
                    <View style={{flexDirection: "row", justifyContent: "space-around", padding: 10}}>
                        <View style={{flex: 1}}>
                            <Text>🎁</Text>
                        </View>
                        <View style={{flex: 15}}>
                            <Text style={[CommonStyles.whiteText]}>Prizes are not auto claimed, just like real housie</Text>
                        </View>
                    </View>
                    <View style={{flexDirection: "row", justifyContent: "space-around", padding: 10}}>
                        <View style={{flex: 1}}>
                            <Text>😑</Text>
                        </View>
                        <View style={{flex: 15}}>
                            <Text style={[CommonStyles.whiteText]}>Turn on auto check mode, if you still want this</Text>
                        </View>
                    </View>
                </Banner> */}
            </View>
            <View style={[{paddingBottom: 10, paddingTop: 5}]}>
                <Text style={[CommonStyles.whiteText, CommonStyles.text16, CommonStyles.textBold]}>Continue to <Text style={[CommonStyles.secondaryText]}>Invite</Text> 👇🏼</Text>
            </View>
            <Animatable.View useNativeDriver animation="swing" iterationCount="infinite" iterationDelay={3000}
            style={[{ flexDirection: 'row', alignSelf: "stretch", alignItems: "center", justifyContent: "space-evenly", marginBottom: 10 }]}>
                <ShareButton gameId={gameId} game={game} textStyle={[CommonStyles.textLarge]}/>
            </Animatable.View>
        </View>
    )
);

export default GameIntro;

const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: "stretch",
      width: "100%"
    },
    contentContainer: {
      alignItems: "center"
    },
    resultsHeader: {
      paddingHorizontal: 2,
      paddingVertical: 2,
      justifyContent: "center",
      borderWidth: 1,
      borderColor: "black",
      backgroundColor: Colors.primaryColor,
      borderRadius: Units.cornerRadius
    },
    resultsItem: {
      paddingHorizontal: 2,
      paddingVertical: 2,
      borderWidth: 1,
      borderColor: "black",
      borderRadius: Units.cornerRadius
    },
    buyBtn: {
        borderRadius: Units.cornerRadius,
        marginLeft: -20,
        flexDirection: "row",
        justifyContent: "space-around",
        backgroundColor: Colors.primaryColor,
        marginBottom: 10,
        minHeight: 36,
        alignItems: "center"
    },
    buyBtnSuccess: {
        backgroundColor: Colors.successColor
    }
  });
  