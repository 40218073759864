import * as React from "react";
import { StyleSheet, Text, View, Image, TouchableOpacity, Alert } from "react-native";

import CommonStyles from "../styles/common";
import { offerTypes, purchasePackageByInfo, getPackagesByOffer } from "../utils/purchase";
import Loader from "./Loader";
import LuckyWheel from "./LuckyWheel";

export default function CreditDiamond({type}) {
    const [isSaving, setSaving] = React.useState(false);
    const [packages, setPackages] = React.useState([]);
    const [onWheel, setOnWheel] = React.useState(false);
    ['ALL','BUY'].includes(type) && React.useEffect(async () => {
        const res = await getPackagesByOffer(offerTypes.diamond);
        res.success && setPackages(res.data);
    }, []);
    return (<>
        <View style={{ paddingHorizontal: 15, paddingBottom: 10, alignItems: "flex-start", flexDirection: "row", justifyContent: "center" }} key="1">
          <View style={styles.container}>
            {!onWheel && <View style={{flexDirection: "row", justifyContent: "space-around"}}>
                {
                    packages.map((pckg, index) => (
                        <View key={pckg.identifier}>
                            <TouchableOpacity onPress={async() => {
                                setSaving(pckg.identifier);
                                // setErrorMsg('');
                                const res = await purchasePackageByInfo(pckg);
                                setSaving(false);
                            }}>
                                <Text style={[CommonStyles.textLargest, CommonStyles.textAlignCenter]}><Text style={[CommonStyles.textSmall, CommonStyles.loveyDubey]}>{'💎'.repeat(index+1)}</Text>💰</Text>
                                <Text style={[CommonStyles.textSmall, CommonStyles.textAlignCenter, CommonStyles.whiteText]}>{pckg.product?.title}</Text>
                                <Text style={[CommonStyles.textSmall, CommonStyles.textAlignCenter, CommonStyles.whiteText]}>{pckg.product?.price_string}</Text>
                                {
                                    isSaving == pckg.identifier ? <View style={[{position: 'absolute', top: 20, left: '50%'}]}><Loader size="small"/></View> : null
                                }
                            </TouchableOpacity>
                        </View>
                    ))
                }
                {!onWheel && ['ALL','WHEEL','BONUS'].includes(type) && <View key='wheelTile'>
                    <TouchableOpacity onPress={async() => {
                        setOnWheel(true);
                    }}>
                        <Text style={[CommonStyles.textLargest, CommonStyles.textAlignCenter]}><Text style={[CommonStyles.textSmall, CommonStyles.loveyDubey]}>💎💎</Text>🎡</Text>
                        <Text style={[CommonStyles.textSmall, CommonStyles.textAlignCenter, CommonStyles.whiteText]}>Spin for Diamonds</Text>
                        <Text style={[CommonStyles.textSmall, CommonStyles.textAlignCenter, CommonStyles.whiteText]}>⏳ 17 mins.</Text>
                    </TouchableOpacity>
                </View>}
            </View>}
            {onWheel && <LuckyWheel/>}
          </View>
        </View>
    </>)
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    contentContainer: {
      paddingTop: 30,
      alignItems: "center",
    }
  });
  