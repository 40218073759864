import * as React from "react";
import Icon from 'react-native-vector-icons/FontAwesome';
import * as Analytics from 'expo-firebase-analytics';
import * as Linking from 'expo-linking';
import { View, Text } from "react-native";


import Colors from "../../constants/Colors";
import Units from "../../constants/Units";
import ThemeButton from '../ThemeButton';
import CommonStyles from "../../styles/common";
import deviceLanguage from "../../constants/Locale";

const defaultTitleText = `🎺🥁🎺🥁 *Party Invitation* 🎺🥁🎺🥁
Click to join my *Online Tambola Paperless* party`;

const italiantTitleText = `🎺🥁🎺🥁 *Invito alla festa* 🎺🥁🎺🥁
Clicca per unirti alla mia festa *Online Tambola Paperless*`

const turkishTitleText = `🎺🥁🎺🥁 *Parti Davetiyesi* 🎺🥁🎺🥁
*Çevrimiçi Tambola Kağıtsız* partime katılmak için tıklayın`

const textMap = {
  'it_IT': italiantTitleText,
  'it-IT': italiantTitleText,
  'it': italiantTitleText,
  'tr_TR': turkishTitleText,
  'tr-TR': turkishTitleText,
  'tr': turkishTitleText,
}

const titleText = textMap[deviceLanguage] || defaultTitleText;

const ShareButton = React.memo(
  ({ gameId, game, textStyle }) => (
    <ThemeButton
      type=''
      onPress={() => {
        Analytics.logEvent('share_game_code_whatsapp', {
          contentType: gameId,
          itemId: 'Game code share via button',
          method: 'share button'
        });
        const settings = `
        💌 Invite Code: *${gameId.toUpperCase()}*
        🎩 Host: ${game.hostName}
        ⏳ Speed: ${game.auto ? (game.auto === 8000 ? 'Slow' : (game.auto === 6000 ? 'Medium' : (game.auto === 4000 ? '*Fast*' : 'Manual'))) : 'Manual'}
        🎟️ Tickets: Max ${game.maxTickets}${game.accounting ? ` @*₹ ${game.ticketCost}* each` : ''}
        ⏰ Late Claims: ${game.disallowLateClaims ? '*Not Allowed*' : 'Allowed'}
        🏆 Claims Per Player: ${game.limitClaims ? '*2 Minor, 1 House*' : 'Unlimited'}
        👥 Prize Shared With: ${game.limitConcurrentClaims == -1 ? 'All Claimers' : (game.limitConcurrentClaims == 1 ? '*First One To Claim*' : '*First Two Claimers*')}
        🎰 Auto Check: ${game.autocheck ? '*On*' : 'Off'}
        💰 Smart Calculator: ${game.accounting ? '*On*' : 'Off'}`
        Linking.openURL(`https://api.whatsapp.com/send?text=${titleText} 👇 https://onlinetambolahousie.com/game/${gameId.toUpperCase()}${settings}
Upgrade game to try Smart Calculator 💰 today!`);
      }}
    >
      <View style={{ flexDirection: 'row' }}>
        <View style={{ borderWidth: 2, borderStyle: "solid", borderColor: Colors.white, justifyContent: "center", alignSelf: "center", height: 42, paddingHorizontal: 10 }}>
          <Text allowFontScaling={false} selectable={true} style={[CommonStyles.whiteText, CommonStyles.textMedium, textStyle]}>
            {gameId.toUpperCase()}
          </Text>
        </View>
        <View 
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            justifyContent: "center", 
            alignSelf: "center", 
            borderTopRightRadius: Units.cornerRadius,
            borderBottomRightRadius: Units.cornerRadius,
            height: 42,
            width: 45,
            minWidth: "auto",
            paddingHorizontal: 8,
            backgroundColor: '#25D366'
          }}
        >
        <Icon name="whatsapp" size={32} color={Colors.white} />
      </View>
      </View>
    </ThemeButton>
  )
)

export default ShareButton;
