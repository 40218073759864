import * as React from "react";
import Icon from 'react-native-vector-icons/FontAwesome';
import { useFocusEffect } from '@react-navigation/native';
import { ScrollView, StyleSheet, View, Text, TouchableOpacity, Button } from "react-native";
import { Modalize } from 'react-native-modalize';
import { createSelector, isImmutableDefault } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from 'react-redux'

import { services } from '../store';
import { prizesMapSelector } from '../selectors';
import { initializeList, toggleSelection, incrementUnits, decrementUnits } from '../reducers/dividendsShortlist';
import CommonStyles from "../styles/common";
import ScreenBackground from "../components/ScreenBackground";
import ThemeButton from "../components/ThemeButton";
import { Illustration } from "./InfoScreen";
import Loader from "../components/Loader";
import Dimensions from '../constants/Layout';
import Colors from "../constants/Colors";
import Units from "../constants/Units";
import { rawServices } from "../ApiClient";
import common from "../styles/common";

const LeftColumnWidth = Math.min(Dimensions.window.width - 180);

function groupByArray(list, key, secondaryKey) {
  return list.reduce((acc, item) => {
    let group = key instanceof Function ? key(item) : item[key] || item[secondaryKey];
    let el = acc.find((x) => x && x.key === group);
    if (el) {
      el.values.push(item);
      el.selected = el.selected && item.selected;
    } else {
      acc.push({ key: group, values: [item], selected: item.selected, multi: item.multi });
    }
    return acc;
  }, []);
}

const allDividendsSelector = createSelector(
  state => state.dividendsShortlist,
  dividends => groupByArray(dividends, 'set', 'name')
)
const shortListSelector = createSelector(
  state => state.dividendsShortlist.filter(item => item.selected),
  dividends => dividends
)
const savingPrizesSelector = createSelector(
  state => state.prizes.isSaving,
  saving => saving
)

export default function ConfigScreen({ navigation, route }) {
  const { params: { gameId } = {} } = route;
  const dividends = useSelector(allDividendsSelector);
  const shortlist = useSelector(shortListSelector);
  const prizesMap = useSelector(prizesMapSelector);
  const savingPrizes = useSelector(savingPrizesSelector);
  const [samples, setSamples] = React.useState([]);
  // const [isModalVisible, setModalVisible] = React.useState(false);

  // const toggleModal = () => {
  //   setModalVisible(!isModalVisible);
  // };

  const modalizeRef = React.useRef(null);
  const showModal = () => {
    modalizeRef.current?.open();
  };
  const hideModal = () => {
    modalizeRef.current?.close();
  };

  const dispatch = useDispatch();

  const navBack = React.useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate("GameArea", { screen: 'GameCenter', params: { gameId: gameId } });
    }
  }, [navigation]);

  useFocusEffect(
    React.useCallback(() => {
      services.dividends.find().then(res => {
        dispatch(initializeList(res.value.map(
          item => {
            return prizesMap[item.name] ? ({ ...item, selected: true, units: prizesMap[item.name].units }) : item
          }
        )))
      })
    }, [])
  );

  const renderContent = () => [
    <View style={CommonStyles.modalContentHeader} key="0">
      <Text allowFontScaling={false} style={[CommonStyles.textLarge, CommonStyles.whiteText]}>Dividend Details</Text>
      <ThemeButton
        type="link"
        style={[CommonStyles.squareBtn, CommonStyles.modalHeaderCloseBtn]}
        onPress={hideModal}
      >
        <Icon name="close" size={16} color={Colors.white} />
      </ThemeButton>
    </View>,
    <View style={CommonStyles.modalContentInside} key="1">
      {
        samples.map(
          item => <Illustration key={item.name} prize={item} />
        )
      }
    </View>
  ]

  return (
    <ScreenBackground>
      <View style={[styles.container]}>
        <View style={[{ flexDirection: "row", margin: 10, alignItems: "center" }, CommonStyles.androidStatusBarSpace]}>
          <View style={{ marginRight: 50, flexGrow: 1, alignItems: "center" }}>
            <Text allowFontScaling={false} style={[CommonStyles.whiteText, CommonStyles.textLarge]}>Change Dividends</Text>
          </View>
          <ThemeButton
            type="link"
            onPress={navBack}
            style={CommonStyles.squareBtn}
          >
            <Icon name="close" size={20} color="white" />
          </ThemeButton>
        </View>
        {
          dividends.length ?
            <View style={[CommonStyles.row, { height: Units.gameScrollableAreaHeight, marginVertical: 0, marginHorizontal: 10 }]}>
              <View style={{ width: LeftColumnWidth }}>
                {/* <Text style={[CommonStyles.whiteText, CommonStyles.textMedium, CommonStyles.textAlignCenter]}>Select Dividends</Text> */}
                <ScrollView
                  contentContainerStyle={styles.contentContainer}
                >
                  <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                    {dividends.map(({ key, values, selected, multi }) => (
                      <TouchableOpacity key={key} style={[styles.card, selected && styles.cardSelected]} onPress={() => dispatch(toggleSelection(values))}>
                        <ThemeButton
                          type="link"
                          style={[CommonStyles.squareBtn, styles.infoBtn]}
                          onPress={() => {
                            rawServices.dividends.find({ query: { prizes: values.map(value => value.name) } }).then(res => {
                              // services.dividends.get(values[0].name, {}).then(res => {
                              setSamples(res.value);
                              showModal();
                            });
                          }}
                        >
                          <Icon name="info-circle" size={16} color={selected ? Colors.darkTextColor : Colors.white} />
                        </ThemeButton>
                        {values.map(({ name, units }) => <React.Fragment key={name}>
                          <View style={{ justifyContent: "center", height: 25 }}>
                            <Text allowFontScaling={false} style={selected ? CommonStyles.darkText : CommonStyles.whiteText} key={name}>{name}</Text>
                          </View>
                          {
                            selected && multi ? <View style={styles.stepper} pointerEvents="box-none">
                              <ThemeButton type="primary" onPress={() => dispatch(decrementUnits(name))} disabled={units <= 1} style={CommonStyles.circularBtn}>
                                <Icon name="minus" size={16} color="white" />
                              </ThemeButton>
                              <Text>{units}</Text>
                              <ThemeButton type="success" onPress={() => dispatch(incrementUnits(name))} style={CommonStyles.circularBtn}>
                                <Icon name="plus" size={16} color="white" />
                              </ThemeButton>
                            </View>
                              :
                              null
                          }
                        </React.Fragment>)}
                      </TouchableOpacity>
                    ))}
                  </View>
                </ScrollView>
              </View>
              <View style={styles.preview}>
                {/* <Text style={[CommonStyles.whiteText, CommonStyles.textMedium, CommonStyles.textAlignCenter]}>Preview</Text> */}
                <ScrollView
                  contentContainerStyle={styles.contentContainer}
                >
                  <View>
                    {
                      shortlist.map((item, index) => {
                        return item.units > 1 ? Array(item.units).fill().map(
                          (x, index) => (
                            <ThemeButton
                              key={`${item.name}${index + 1}`}
                              type={"secondary"}
                              style={styles.previewItem}
                              onPress={() => { /* scroll corresponding card into view */ }}
                            >
                              <Text>{`${item.name} ${index + 1}`}</Text>
                            </ThemeButton>
                          )
                        )
                          :
                          <ThemeButton
                            key={`${item.name}${index}`}
                            type={"secondary"}
                            style={styles.previewItem}
                            onPress={() => { /* scroll corresponding card into view */ }}
                          >
                            <Text>{`${item.name}`}</Text>
                          </ThemeButton>
                      }).flat()
                    }
                  </View>
                </ScrollView>
                <ThemeButton style={{ elevation: 0 }} type="success" disabled={savingPrizes} onPress={() => {
                  const finalList = shortlist.filter(({ selected }) => selected).map(({ name, units }) => ({ name, units }));
                  services.prizes.update(null, finalList, { query: { gameId } }).then((res) => {
                    // services.prizes.find({ query: { gameId: gameId } })
                    navBack();
                  })
                }}>
                  {
                    savingPrizes ?
                      <Text><Icon name="spinner" size={16} color="white" /> <Text allowFontScaling={false} style={CommonStyles.whiteText}>Saving...</Text></Text>
                      :
                      <Text><Icon name="check" size={16} color="white" /> <Text allowFontScaling={false} style={CommonStyles.whiteText}>Save Changes</Text></Text>
                  }
                </ThemeButton>
              </View>
            </View>
            :
            <Loader />
        }
        <Modalize
          ref={modalizeRef}
          adjustToContentHeight={false}
          modalStyle={{ backgroundColor: "#281121", marginHorizontal: "15%", zIndex: 999 }}
        >
          {renderContent()}
        </Modalize>
      </View>
    </ScreenBackground>
  );
}

ConfigScreen.navigationOptions = {
  header: null,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    alignItems: "center"
  },
  card: {
    width: 150,
    height: 80,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
    borderRadius: Units.cornerRadius,
    backgroundColor: "rgba(203, 203, 203, 0.5)",
  },
  cardSelected: {
    backgroundColor: Colors.secondaryColor
  },
  stepper: {
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  preview: {
    width: 160,
    paddingHorizontal: 10
  },
  previewItem: {
    marginBottom: 5,
    paddingVertical: 5,
    paddingHorizontal: 0,
    width: 130,
    minHeight: 34
  },
  infoBtn: {
    position: "absolute",
    top: 0,
    right: 0
  }
});