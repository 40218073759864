import { Alert } from 'react-native';
import * as StoreReview from 'expo-store-review';
import * as Analytics from 'expo-firebase-analytics';

import showToast from '../components/Toast';

const openStore = () => {
  Analytics.logEvent('rate_us_popup_5_star');
  StoreReview.requestReview()
}

const showRatePrompt = () => {
  if (StoreReview.isAvailableAsync()) {
    Alert.alert(
      'Loving the free 🇮🇳 app?',
      'Support by rating us 5 stars ⭐⭐⭐⭐⭐',
      [
        {
          text: 'Needs Improvement',
          onPress: () => {
            Analytics.logEvent('rate_us_popup_needs_improvement');
            showToast('Thanks for your feedback. Send your suggestions to our WhatsApp Support!', 4000);
          },
          style: 'cancel',
        },
        { text: 'Rate 5 Stars', onPress: openStore },
      ],
      { cancelable: true }
    )
  }
}

export default showRatePrompt