import { Platform, StatusBar } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { bindWithDispatch } from "feathers-redux";
import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
// import Toast from 'react-native-root-toast';
let Toast;
if (Platform.OS !== 'web') {
  Toast = require('react-native-root-toast').default;
}
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";

import rootReducer from "./reducers";
import { addSet } from "./reducers/autoResponses";
// import { setText } from "./reducers/speak";
import { addItem, deleteItem } from "./reducers/online";
import { setClaim } from "./reducers/currentClaim";
import middlewares from "./middleware";
import { rawServices } from "./ApiClient";
import client from "./ApiClient";

const persistConfig = {
  key: "root",
  version: 1,
  storage: AsyncStorage,
  whitelist: ['ticketStrikes'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    ...middlewares,
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      },
      immutableCheck: false
    })
  ],
});

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./reducers", () => {
    const newRootReducer = require("./reducers").default;
    store.replaceReducer(newRootReducer);
  });
}
export const persistor = persistStore(store);
export const services = bindWithDispatch(store.dispatch, rawServices);

// Event listeners for socket messages
const drawer = client.service("drawer");
const players = client.service("players");
const tickets = client.service("tickets");
const prizes = client.service("prizes");
const claims = client.service("claims");
const games = client.service("games");
const notification = client.service("notification");
const online = client.service("online");
const userstates = client.service("userstates");
// Drawer
drawer.on("created", (data) => {
  store.dispatch(services.drawer.onCreated(data));
});

// Players
players.on("created", (data) => {
  store.dispatch(services.players.onCreated(data));
});
players.on("updated", (data) => {
  store.dispatch(services.players.onUpdated(data));
});
players.on("patched", (data) => {
  store.dispatch(services.players.onPatched(data));
});
players.on("removed", (data) => {
  store.dispatch(services.players.onRemoved(data));
});

// Online status
online.on("created", (data) => {
  store.dispatch(addItem(data));
});
online.on("removed", (data) => {
  store.dispatch(deleteItem(data));
});

// userstates (used for genie and other offerings)
userstates.on("created", (data) => {
  store.dispatch(services.userstates.onCreated(data));
});
userstates.on("removed", (data) => {
  store.dispatch(services.userstates.onRemoved(data));
});
userstates.on("updated", (data) => {
  store.dispatch(services.userstates.onUpdated(data));
});
userstates.on("patched", (data) => {
  store.dispatch(services.userstates.onPatched(data));
});

// Tickets
tickets.on("created", (data) => {
  store.dispatch(services.tickets.onCreated(data));
});
tickets.on("updated", (data) => {
  store.dispatch(services.tickets.onUpdated(data));
});
tickets.on("patched", (data) => {
  if (data.bougie) {
    store.dispatch(services.tickets.onCreated(data));
  }
  store.dispatch(services.tickets.onPatched(data));
});
tickets.on("removed", (data) => {
  store.dispatch(services.tickets.onRemoved(data));
});

// Prizes
prizes.on("created", (data) => {
  store.dispatch(services.prizes.onCreated(data));
});
prizes.on("updated", (data) => {
  // Using this as a flag to refresh prizes list for a game. Server returns empty object for this case
  store.dispatch({
    type: "SERVICES_PRIZES_GET_FULFILLED",
    payload: {
      update: "update"
    }
  });
  // store.dispatch(services.prizes.onUpdated(data));
});
prizes.on("patched", (data) => {
  store.dispatch(services.prizes.onPatched(data));
});
prizes.on("removed", (data) => {
  store.dispatch(services.prizes.onRemoved(data));
});

// Claims
claims.on("created", (data) => {
  store.dispatch(services.claims.onCreated(data));
  store.dispatch(setClaim(data));
});
claims.on("updated", (data) => {
  store.dispatch(services.claims.onUpdated(data));
});
claims.on("patched", (data) => {
  store.dispatch(services.claims.onPatched(data));
});
claims.on("removed", (data) => {
  store.dispatch(services.claims.onRemoved(data));
});

// Games
games.on("created", (data) => {
  store.dispatch({
    type: "SERVICES_GAMES_CREATE_FULFILLED",
    payload: data
  });
});
games.on("removed", (data) => {
  // Todo: Find better way to handle this. Right now Patching game on removal as there is no game over event
  store.dispatch({ type: "SERVICES_GAMES_GET_FULFILLED", payload: { ...data, isOver: true } });
});
games.on("patched", (data) => {
  store.dispatch({
    type: "SERVICES_GAMES_PATCH_FULFILLED",
    payload: data
  });
});

// Notification
const toastStyle = {
  position: Platform.OS === 'android' ? StatusBar.currentHeight + 40 : 40,
  opacity: 1,
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  textColor: 'white',
  containerStyle: { height: 30, borderRadius: 20, paddingVertical: 0, justifyContent: "center", paddingHorizontal: 10 },
}

notification.on("created", (data) => {
  // const message = data && data.message;
  const message = (data && data.message) || data;
  // if(data.type != 'chat' || data.type != 'moptions'){
  if (Platform.OS === 'web' && data.type == 'toast') {
    return console.log(`Toast: ${message}`);
  }
  if (data.type == 'toast') {
    Toast.show(message, toastStyle)
  }
  if (data.type == 'chat') {
    Toast.show(<><Icon name='comment' size={16} /> {`${data.from?.name?.split(' ')[0]}: ${message}`}</>, toastStyle)
  }
  else if (data.type == 'speak') {
    // console.log('data', data);
    // console.log('dispatching', data.message);
    // store.dispatch(setText(data.message));
  }
  else {
    if (data.type === 'moptions') {
      store.dispatch(addSet(data.message))
    }
  }
});

export default store;
