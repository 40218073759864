import { useLinking } from '@react-navigation/native';
import * as Linking from 'expo-linking';

export default function (containerRef) {
  return useLinking(containerRef, {
    prefixes: ['https://tambolah.herokuapp.com', 'https://onlinetambolahousie.com', 'http://onlinetambolahousie.com', 'exp://expo.io/@akashbudhia/TambolaHousie', Linking.makeUrl('/')],
    config: {
      Login: '/',
      Home: 'home',
      JoinGame: 'join',
      GameArea: {
        path: 'game/:gameId',
        screens: {
          GameCenter: '/',
          ResultsScreen: 'results',
          SettingsScreen: 'settings',
          InfoScreen: 'info',
          ConfigScreen: 'config'
        }
      },
    },
  });
}
