import { Platform, StatusBar } from "react-native";
import Dimensions from '../constants/Layout';

const width = Dimensions.window.width
const statusBarHeight = Platform.OS === 'android' ? StatusBar.currentHeight : 10;
const gameScrollableAreaHeight = Dimensions.window.height - 55 - statusBarHeight;

export default {
  cornerRadius: 3,
  ticketCellWidth: width > 660 ? 45 : 40,
  statusBarHeight,
  gameScrollableAreaHeight
};
