import { Dimensions } from 'react-native';

const scale = Dimensions.get('screen').scale / Dimensions.get('window').scale;

const width = Dimensions.get('window').width * scale;
const height = Dimensions.get('window').height * scale;

const landscapeWidth = width > height ? width : height;
const landscapeHeight = height < width ? height : width;

export default {
  window: {
    width: landscapeWidth,
    height: landscapeHeight,
  },
  isSmallDevice: landscapeHeight < 375,
};
