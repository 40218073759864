import * as React from "react";
import { ActivityIndicator, ScrollView, Platform, StyleSheet, View, Text, KeyboardAvoidingView, TextInput, Button, Alert } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import * as yup from "yup";
import { Formik } from "formik";
import * as Analytics from 'expo-firebase-analytics';

import Colors from "../constants/Colors";
import CommonStyles from "../styles/common";
import Logo from "../components/Logo";
import ScreenBackground from "../components/ScreenBackground";
import DevModeNotice from "../components/DevModeNotice";
import LoginWithFacebook from "../components/LoginWithFacebook";
import * as AppleAuthentication from 'expo-apple-authentication';
import ThemeButton from "../components/ThemeButton";
import { services } from "../store";
import featherClient from "../ApiClient";
import { userAuthenticated } from "../reducers/auth";

const isSavingGuesUserSelector = createSelector(
  state => state.users.createPending,
  createPending => createPending
)

export default function LoginScreen() {
  const [formVisible, setFormVisible] = React.useState(false);
  const [retrying, setRetrying] = React.useState(false);
  const isSavingGuesUser = useSelector(isSavingGuesUserSelector);
  const dispatch = useDispatch();
  // check for token already present
  featherClient.authentication.getAccessToken().then((old_token) => {
    if(old_token){
      setRetrying(true);
      let retries = 0, delay = 50;
      const maxRetry = 599999, backoff = 50;
      const retryAuth = () => {
        retries++;
        delay += backoff;
        featherClient.reAuthenticate(retries % 2 == 0).then((authResponse) => {
          if(authResponse && authResponse.user){
            dispatch(userAuthenticated(authResponse.user));
          }
          else{
            retries < maxRetry && setTimeout(retryAuth, delay);
          }
        }).catch((err) => {
          retries < maxRetry && setTimeout(retryAuth, delay);
        })
      }
      retryAuth();
    }
  })
  return (
    <ScreenBackground>
      <View style={styles.container}>
        <ScrollView
          style={styles.container}
          contentContainerStyle={styles.contentContainer}
        >
          <Logo />
          <View style={CommonStyles.buttonContainer}>
            <LoginWithFacebook />
          </View>
          {
            Platform.OS === "ios" ? <View style={CommonStyles.buttonContainer}>
              <AppleAuthentication.AppleAuthenticationButton
                buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
                buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.WHITE}
                cornerRadius={5}
                style={{ width: 250, height: 44 }}
                onPress={async () => {
                  try {
                    const credential = await AppleAuthentication.signInAsync({
                      requestedScopes: [
                        AppleAuthentication.AppleAuthenticationScope.FULL_NAME
                      ],
                    });
                    Analytics.logEvent('apple_login_success');
                    const name = `${credential.fullName?.givenName || ''} ${credential.fullName?.middleName || ''} ${credential.fullName?.familyName || ''}`
                    services.users.create({
                      name: name.trim() || "Apple user",
                      password: credential.user,
                      meta: credential
                    }).then(
                      (createResponse) => {
                        const _id = createResponse.value._id;
                        featherClient.authenticate({
                          strategy: "local",
                          _id,
                          password: credential.user
                        }).then((authResponse) => {
                          dispatch(userAuthenticated(authResponse.user));
                        })
                      }
                    ).catch(error => {
                      if (error.code === 404) {
                        //logout user: Handle invalid token case while switching between prod/dev env
                        featherClient.authentication.removeAccessToken();
                        featherClient.authentication.reset();
                        // try again
                        login();
                      }
                    })
                  } catch (e) {
                    if (e.code === 'ERR_CANCELED') {
                      // handle that the user canceled the sign-in flow
                    } else {
                      // handle other errors
                      Analytics.logEvent('apple_login_error', { code: e.code })
                    }
                  }
                }}
              />
            </View>
              : null
          }
          {
            formVisible ? (
              <Formik
                initialValues={{ firstname: "", lastname: "" }}
                onSubmit={({ firstname, lastname }) => {
                  function login() {
                    const password = new Date().toISOString();
                    services.users.create({
                      name: `${firstname.trim()} ${lastname.trim()}`,
                      password
                    }).then(
                      (createResponse) => {
                        const _id = createResponse.value._id;
                        featherClient.authenticate({
                          strategy: "local",
                          _id,
                          password
                        }).then((authResponse) => {
                          dispatch(userAuthenticated(authResponse.user));
                        })
                      }
                    ).catch(error => {
                      if (error.code === 404) {
                        //logout user: Handle invalid token case while switching between prod/dev env
                        featherClient.authentication.removeAccessToken();
                        featherClient.authentication.reset();
                        // try again
                        login();
                      }
                    })
                  }
                  login();
                }}
                validationSchema={yup.object().shape({
                  firstname: yup.string().required(),
                  lastname: yup.string(),
                })}
              >
                {({
                  values,
                  handleChange,
                  errors,
                  setFieldTouched,
                  touched,
                  isValid,
                  handleSubmit,
                }) => (
                    <>
                      <KeyboardAvoidingView
                        behavior={Platform.OS === "ios" ? "padding" : "height"}
                      >
                        <View style={{ marginVertical: 10 }}>
                          <View style={{ marginBottom: 5 }}>
                            <TextInput
                              style={styles.input}
                              disableFullscreenUI={true}
                              placeholder="First name"
                              placeholderTextColor="white"
                              onChangeText={handleChange("firstname")}
                              onBlur={() => setFieldTouched("firstname")}
                              value={values.firstname}
                              autoCapitalize="words"
                              autoCompleteType="name"
                              autoCorrect={false}
                            />
                            {touched.firstname && errors.firstname && (
                              <Text style={{ fontSize: 15, color: "red" }}>
                                {errors.firstname}
                              </Text>
                            )}
                          </View>
                          <View style={{ marginBottom: 5 }}>
                            <TextInput
                              style={styles.input}
                              disableFullscreenUI={true}
                              placeholder="Last name"
                              placeholderTextColor="white"
                              onChangeText={handleChange("lastname")}
                              onBlur={() => setFieldTouched("lastname")}
                              value={values.lastname}
                              autoCapitalize="words"
                              autoCorrect={false}
                            />
                            {touched.lastname && errors.lastname && (
                              <Text style={{ fontSize: 15, color: "red" }}>
                                {errors.lastname}
                              </Text>
                            )}
                          </View>
                          <ThemeButton
                            style={{ width: 250 }}
                            type="primary"
                            onPress={handleSubmit}
                            text="Continue"
                            disabled={isSavingGuesUser}
                          />
                        </View>
                      </KeyboardAvoidingView>
                    </>
                  )}
              </Formik>
            ) : (
                <View style={CommonStyles.buttonContainer}>
                  {
                    Platform.OS === 'ios' ?
                      <Button color="white" title="Play as guest" onPress={() => setFormVisible(!formVisible)} />
                      :
                      <Button color="transparent" title="PLAY AS GUEST" onPress={() => setFormVisible(!formVisible)} />
                  }
                </View>
              )
          }
          {
            retrying ? <ActivityIndicator
            size="small" color={Colors.secondaryColor}/> : null
          }
          <DevModeNotice />
        </ScrollView>
      </View>
    </ScreenBackground>
  );
}

LoginScreen.navigationOptions = {
  header: null,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    paddingTop: 30,
    justifyContent: "space-around",
    alignItems: "center",
    flexGrow: 1
  },
  input: {
    height: 40,
    borderColor: Colors.white,
    borderWidth: 1,
    borderRadius: 4,
    color: Colors.white,
    width: 250,
    fontWeight: "bold",
    paddingLeft: 10,
    paddingRight: 10,
  }
});
