import * as React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from "react-redux";
import Purchases from 'react-native-purchases';

import LoginScreen from "../screens/LoginScreen";
import HomeScreen from "../screens/HomeScreen";
import GameScreen from "../screens/GameScreen";
import JoinScreen from "../screens/JoinScreen";
import { Platform } from "react-native";

const Stack = createStackNavigator();
const userSelector = createSelector(
  state => state.auth.user,
  user => user
)

const appNavigator = () => {
  const user = useSelector(userSelector);

  React.useEffect(async() => {
    // Todo: Save purchaser Info in redux store, to confirm login was successfull before making purchase
    if(Platform.OS !== 'web' && user?._id){
      const { purchaserInfo, created } = await Purchases.logIn(user._id);
      //Set custom attributes https://docs.revenuecat.com/docs/subscriber-attributes
      Purchases.setDisplayName(user.name);
      console.log('Purchases logged in : will be tied to userId', user._id, user.name);
    }
  }, [user]);

  return (
    <Stack.Navigator
      mode="modal"
      screenOptions={{
        headerShown: false,
      }}
    >
      {user ? <>
        <Stack.Screen
          name="Home"
          component={HomeScreen}
          options={{
            title: "Home",
          }}
        />
        <Stack.Screen
          name="JoinGame"
          component={JoinScreen}
          options={{
            title: "Join Game",
          }}
        />
        <Stack.Screen
          name="GameArea"
          component={GameScreen}
          options={{
            title: "Tickets",
            gestureEnabled: false
          }}
        />
      </>
        :
        <Stack.Screen
          name="Login"
          component={LoginScreen}
          options={{
            title: "Login"
          }}
        />
      }
    </Stack.Navigator>
  );
}

export default appNavigator;