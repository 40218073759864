import { createSelector } from '@reduxjs/toolkit'

const playerRewardMap = createSelector(
  state => state.claims.queryResult?.data || [],
  claims => {
    let map = {};
    claims.forEach(claim => {
      map[claim.playerId] = map[claim.playerId] || {totalRewards: 0, claims: [], prizes: [], name: claim.playerName};      
      const playerReward = map[claim.playerId];
      playerReward.totalRewards += (claim.reward || 0);
      playerReward.prizes.push(claim.prize);
      playerReward.claims.push(claim);
    });
    return map
  }
)
export default playerRewardMap