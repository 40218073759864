import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from '@reduxjs/toolkit'
import Icon from 'react-native-vector-icons/FontAwesome';
import * as Analytics from 'expo-firebase-analytics';

import { services } from "../../store";
import Colors from "../../constants/Colors";
import Units from "../../constants/Units";
import CommonStyles from "../../styles/common";
import TicketCell from "./TicketCell";
import BogeyStamp from './BogeyStamp'
import { gameStartedSelector } from '../../selectors';
import { strikeNumber } from '../../reducers/ticketStrikes';
import Banner from '../GameElements/Banner';
import ThemeButton from '../ThemeButton';
import * as Animatable from 'react-native-animatable';

const TicketApprovalPending = React.memo(
  ({ ticketId, isHost, gameId, playerId, playerName, genie, dispatch }) => {

    return isHost ? (
      <Banner dark style={{ flexDirection: "row", alignItems: "center" }}>
        <View style={{ width: "75%" }}>
          <Text style={[CommonStyles.darkBannerText, { textAlign: "left" }]}>
            {isHost ? `${playerName || 'Anonymous user'} requested for a ticket` : `Your ticket is pending for approval`}
          </Text>
        </View>
        <View style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "25%" }}>
          {isHost ? (
            <>
              <View style={{ marginRight: 10 }}>
                <ThemeButton
                  onPress={() =>
                    services.tickets.patch(
                      ticketId,
                      { isApproved: true },
                      { gameId, playerId }
                    ).then(
                      () => dispatch(
                        {
                          type: 'SERVICES_TICKETS_ON_REMOVED',
                          payload: {
                            data: {
                              _id: ticketId
                            }
                          }
                        }
                      )
                    )
                  }
                  type="success"
                  style={CommonStyles.squareBtn}
                >
                  <Icon name="check-circle" size={22} color="white" />
                </ThemeButton>
              </View>
              <View>
                <ThemeButton
                  onPress={() =>
                    services.tickets.remove(
                      ticketId
                    )
                  }
                  type="primary"
                  style={CommonStyles.squareBtn}
                >
                  <Icon name="times-circle" size={22} color="white" />
                </ThemeButton>
              </View>
            </>
          ) : (
              <View>
                <ThemeButton
                  onPress={() =>
                    services.tickets.remove(
                      ticketId
                    )
                  }
                  type="primary"
                  style={CommonStyles.squareBtn}
                >
                  <Icon name="times-circle" size={22} color="white" />
                </ThemeButton>
              </View>
            )
          }
        </View>
      </Banner>
    ) : (
        <TicketCard
          gameId={gameId}
          ticketId={ticketId}
          readOnly={true}
          bogey={false}
          sequence={[3, 0, 0, 31, 40, 0, 72, 0, 84, 0, 19, 0, 0, 57, 61, 0, 73, 89, 23, 0, 46, 58, 0, 75, 0, 90, 0]}
          blur={true}
          cancellable={true}
          genie={genie}
          dispatch={dispatch}
        >
          <View style={[StyleSheet.absoluteFill, styles.nonBlurredContent]}>
            <ThemeButton
              type="primary"
              style={{ width: 165 }}
              onPress={
                () => services.notification.create({
                  message: "Please approve my ticket 🙏",
                  gameId
                })
              }
            >
              <Text style={[CommonStyles.whiteText, CommonStyles.textBold]}>Pending Approval ⌛</Text>
            </ThemeButton>
          </View>
        </TicketCard>
      )
  }
)

const getRandomSequence = () => {
  const ticketNumbers = [
    [1, 16, 0, 31, 0, 0, 66, 72, 0, 0, 0, 41, 51, 0, 68, 78, 0, 80, 6, 0, 20, 0, 44, 0, 0, 79, 87],
    [3, 0, 0, 31, 40, 0, 72, 0, 84, 0, 19, 0, 0, 57, 61, 0, 73, 89, 23, 0, 46, 58, 0, 75, 0, 90, 0],
    [1, 0, 21, 0, 0, 53, 62, 79, 0, 0, 0, 0, 32, 41, 55, 66, 0, 83, 0, 12, 22, 38, 0, 59, 0, 0, 85],
    [3, 0, 26, 33, 0, 0, 60, 0, 82, 0, 0, 45, 55, 0, 63, 73, 0, 88, 0, 14, 0, 38, 0, 58, 0, 79, 89],
    [1, 0, 0, 36, 41, 0, 64, 0, 81, 0, 0, 23, 39, 0, 52, 0, 71, 86, 9, 19, 28, 0, 0, 0, 66, 74, 0],
    [0, 0, 28, 0, 43, 50, 0, 70, 82, 0, 15, 0, 39, 0, 51, 62, 0, 86, 9, 17, 0, 0, 44, 0, 67, 76, 0]
  ]
  return ticketNumbers[1];
  // return ticketNumbers[Math.floor(Math.random() * 6)];
}

// const ctr = {};
export const TicketCard = React.memo(({ gameId, ticketId, readOnly, bogey, sequence, children, blur, strikedInitial, shouldInitial, cancellable, dispatch, auto, draw, genie }) => {
  sequence = sequence || getRandomSequence();
  // ticketId != 'ticketid' && console.log('Ticket Card Rendered', ctr[ticketId] = ctr[ticketId] ? ctr[ticketId] + 1 : 1);
  const gameStarted = useSelector(gameStartedSelector);
  const onNumberStrike = React.useCallback(
    (numberIndex, striked) => {
      dispatch(strikeNumber({
        gameId,
        ticketId,
        numberIndex,
        striked
      }))
    }, [gameId, ticketId, dispatch]
  );
  const strikedSequence = useSelector(state => {
    return (strikedInitial || state.ticketStrikes[gameId] && state.ticketStrikes[gameId][ticketId]) || [];
  });
  return (
    <>
      <View
        style={[styles.ticketWrapper, genie && styles.ticketWrapperGenie]}
        pointerEvents={bogey ? "none" : "auto"}
      >
        <View style={styles.ticketInnnerWrapper}>
          {
            (!gameStarted && !readOnly) || cancellable ? <ThemeButton
              type="primary"
              onPress={() => {
                Analytics.logEvent('ticket_self_cancel_click');
                services.tickets.remove(ticketId)
              }}
              style={{ height: 30, minWidth: "auto", width: 30, position: "absolute", top: -15, right: -10, borderRadius: 15, zIndex: 1, backgroundColor: "rgba(220, 95, 93, 0.71)", padding: 0 }}
            >
              <Icon name="close" size={12} color="white" style={{ fontWeight: 100 }} />
            </ThemeButton> : null
          }
          {sequence.map((number, index) =>
            {
              const numberPosInDrawer = auto && draw?.indexOf(number);
              const isStriked = auto && draw ? numberPosInDrawer > -1 && numberPosInDrawer != (draw?.length -1) : strikedInitial ? strikedInitial[index] : strikedSequence[index];
              return (
              <TicketCell
                ticketId={ticketId}
                number={number}
                key={index}
                index={index}
                onStrike={onNumberStrike}
                strikedInitial={isStriked}
                shouldInitial={shouldInitial && shouldInitial[index]}
                readOnly={readOnly}
                blur={blur}
                genie={genie}
              />)
            })
          }
          {children}
        </View>
      </View>
      {bogey ? (
        <BogeyStamp />
      ) : (
          null
        )}
    </>
  )
})
const Ticket = React.memo(({
  sequence,
  isApproved,
  ticketId,
  isHost,
  playerId,
  playerName,
  isOwn,
  gameId,
  bogey,
  blur,
  readOnly,
  draw,
  auto,
  genie
}) => {
  const dispatch = useDispatch();
  // console.log('Ticket Parent Rendered', ctr['parent' + ticketId] = ctr['parent' + ticketId] ? ctr['parent' + ticketId] + 1 : 1);
  return (
    <View style={styles.ticketContainer}>
      {
        isHost && bogey ?
          <Banner dark style={{ flexDirection: "row", alignItems: "center" }} key={ticketId}>
            <View style={{ width: "75%" }}>
              <Text style={[CommonStyles.darkBannerText, { textAlign: "left" }]}>
                {`Restore ${playerName || 'Player'}'s bogey ticket`}
              </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "25%" }}>
              <>
                <View style={{ marginRight: 10 }}>
                  <ThemeButton
                    onPress={() =>
                      services.tickets.patch(
                        ticketId,
                        { bougie: false },
                        { gameId, playerId }
                      ).then(
                        () => dispatch({
                          type: "SERVICES_TICKETS_ON_REMOVED",
                          payload: { data: { _id: ticketId } }
                        })
                      )
                    }
                    type="success"
                    style={CommonStyles.squareBtn}
                  >
                    <Icon name="check-circle" size={22} color="white" />
                  </ThemeButton>
                </View>
                <View>
                  <ThemeButton
                    onPress={() => dispatch({
                      type: "SERVICES_TICKETS_ON_REMOVED",
                      payload: { data: { _id: ticketId } }
                    })}
                    type="primary"
                    style={CommonStyles.squareBtn}
                  >
                    <Icon name="times-circle" size={22} color="white" />
                  </ThemeButton>
                </View>
              </>
            </View>
          </Banner>
          :
          isApproved && isOwn ? (
            <TicketCard
              gameId={gameId}
              ticketId={ticketId}
              readOnly={readOnly}
              bogey={bogey}
              sequence={sequence}
              blur={blur}
              dispatch={dispatch}
              draw={draw}
              genie={genie}
              auto={auto}
            />
          ) : (
              <TicketApprovalPending
                ticketId={ticketId}
                isHost={isHost}
                playerId={playerId}
                playerName={playerName}
                gameId={gameId}
                dispatch={dispatch}
                genie={genie}
              />
            )
      }
    </View>
  );
});

export default Ticket;
const styles = StyleSheet.create({
  ticketContainer: {
    // width: Units.ticketCellWidth * 9 + 14,
    alignSelf: "stretch",
    marginBottom: 10,
  },
  ticketWrapper: {
    flexDirection: "column",
    backgroundColor: Colors.primaryColor,
    borderRadius: Units.cornerRadius,
    padding: 2,
  },
  ticketWrapperGenie: {
    backgroundColor: Colors.primaryColorGenieTheme,
  },
  ticketInnnerWrapper: {
    backgroundColor: Colors.white,
    borderRadius: Units.cornerRadius,
    padding: 2,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  nonBlurredContent: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "rgba(255, 255, 255, 0.55)"
  }
});
