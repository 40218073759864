import * as React from "react";
import Icon from 'react-native-vector-icons/FontAwesome';
import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'

import ThemeButton from '../ThemeButton';
import { services } from '../../store';
import CommonStyles from '../../styles/common';

const drawInProgressSelector = createSelector(
  state => state.drawer.isSaving,
  state => state.drawer.queryResult?.data.length === 90,
  state => state.prizes.queryResult?.data.reduce((result, prize) => result && !!(prize.claimed === prize.units), true),
  (isSaving, allNumbersDrawn, allPrizesClaimed) => [isSaving, allNumbersDrawn, allPrizesClaimed]
)

const DrawNumber = ({ gameId }) => {
  const [drawInProgress, allNumbersDrawn, allPrizesClaimed] = useSelector(drawInProgressSelector);
  const endGame = allNumbersDrawn || allPrizesClaimed;

  return <ThemeButton
    type={"secondary"}
    onPress={() => {
      services.drawer.create({}, { query: { gameId } });
    }}
    disabled={drawInProgress}
    loading={drawInProgress}
    text={endGame ? "Results" : <Icon name='caret-square-o-right' size={20} />}
    style={endGame ? { height: 40 } : CommonStyles.squareBtn}
  />
};

export default DrawNumber;
