import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import client from "../ApiClient";
import CommonStyles from "../styles/common";
import Colors from "../constants/Colors";
import Units from "../constants/Units";
import Loader from "./Loader";

export default function PlayerClaims({gameId, playerId}) {
    const [claims, setClaims] = React.useState([]);
    React.useEffect(() => {
        client.service('claims').find({ query: { gameId, playerId } })
        .then(res => {
            setClaims(res);
        });
    }, [gameId, playerId])

  return (
    <View style={styles.container}>
        <View style={{ flexDirection: "row" }}>
            <View style={[styles.resultsHeader, {flex: 1}]}><Text allowFontScaling={false} style={[CommonStyles.textSmall, CommonStyles.whiteText, CommonStyles.textAlignCenter]}>ℹ️</Text></View>
            <View style={[styles.resultsHeader, {flex: 3}]}><Text allowFontScaling={false} style={[CommonStyles.textSmall, CommonStyles.whiteText]}>Prize</Text></View>
            <View style={[styles.resultsHeader, {flex: 8}]}><Text allowFontScaling={false} style={[CommonStyles.textSmall, CommonStyles.whiteText]}>Status</Text></View>
            <View style={[styles.resultsHeader, {flex: 1}]}><Text allowFontScaling={false} style={[CommonStyles.textSmall, CommonStyles.whiteText, CommonStyles.textAlignCenter]}>At</Text></View>
        </View>
        {
            claims ? 
            claims.slice(0).reverse().map(claim => {
                return <View style={{ flexDirection: "row" }}>
                    <View style={[styles.resultsItem, {flex: 1}]}><Text allowFontScaling={false} style={[CommonStyles.textSmall, CommonStyles.whiteText, CommonStyles.textAlignCenter]}>{claim.success ? '🏆' : claim.limitExceed ? '⚡' : claim.isLate ? '⏰': claim.unavailable ? '💨' : '🚫'}</Text></View>
                    <View style={[styles.resultsItem, {flex: 3}]}><Text allowFontScaling={false} style={[CommonStyles.textSmall, CommonStyles.whiteText]}>{claim.prize}</Text></View>
                    <View style={[styles.resultsItem, {flex: 8}]}><Text allowFontScaling={false} style={[CommonStyles.textSmall, CommonStyles.whiteText]}>{claim.reason ? claim.reason: ''}</Text></View>
                    <View style={[styles.resultsItem, {flex: 1}]}><Text allowFontScaling={false} style={[CommonStyles.textSmall, CommonStyles.whiteText, CommonStyles.textAlignCenter]}>{claim.currNumber ? claim.currNumber: ''}</Text></View>
                </View>
            }) : 
            <Loader />
        }
        {
            claims && !claims.length ?
            <View style={{ flexDirection: "row" }}>
                <View style={[styles.resultsItem, {flex: 1}]}><Text allowFontScaling={false} style={[CommonStyles.textSmall, CommonStyles.whiteText]}>No claims made by player in this game</Text></View>
            </View> : null
        }
    </View>
  );
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    contentContainer: {
      alignItems: "center"
    },
    resultsHeader: {
      paddingHorizontal: 2,
      paddingVertical: 2,
      justifyContent: "center",
      borderWidth: 1,
      borderColor: "black",
      backgroundColor: Colors.primaryColor,
      borderRadius: Units.cornerRadius
    },
    resultsItem: {
      paddingHorizontal: 2,
      paddingVertical: 2,
      borderWidth: 1,
      borderColor: "black",
      borderRadius: Units.cornerRadius
    }
  });
  