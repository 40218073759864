import * as React from "react";
import { StyleSheet, Text, View, ActivityIndicator } from "react-native";
import Icon from 'react-native-vector-icons/FontAwesome';
import * as Analytics from 'expo-firebase-analytics';

import Units from "../../constants/Units";
import CommonStyles from "../../styles/common";
import ThemeButton from "../ThemeButton";
import { services } from "../../store";
import Banner from "../GameElements/Banner";
import Colors from "../../constants/Colors";
import { useSelector } from "react-redux";
import { TicketCard } from "./Ticket";
import { gameTicketsSelector, userSelector } from "../../selectors";
import AdsInst from "../AdsInst";

const TicketOption = ({ label, units, gameId, player, onSuccess, style }) => (
  <View style={style}>
    <ThemeButton
      type="primary"
      style={styles.option}
      onPress={() => {
        Analytics.logEvent('buy_tickets', {
          contentType: units,
          itemId: units,
          method: 'click'
        });
        services.tickets.create({ gameId, units, playerId: player._id, playerName: player.name }).then(() => onSuccess && onSuccess())
      }}
    >
      <View>
        <Text style={[CommonStyles.whiteText, CommonStyles.textLarge]} numberOfLines={1}>
          {units} <Icon name="ticket" size={25} />
          {/* {Array(units).fill().map((val, key) => <Icon key={key} name="ticket" size={20} />)} */}
        </Text>
      </View>
    </ThemeButton>
    {/* <Text style={[CommonStyles.whiteText, CommonStyles.text16]}>{label}</Text> */}
  </View>
);

const getTicketText = {
  1: "Show My Ticket",
  2: "Show Second Ticket",
  3: "Show Third Ticket",
  4: "Show Fourth Ticket",
  5: "Show Fifth Ticket",
  6: "Show Sixth Ticket",
  last: "Show Last Ticket",
}

const getPaidTicketText = {
  1: "Buy My Ticket",
  2: "Buy Second Ticket",
  3: "Buy Third Ticket",
  4: "Buy Fourth Ticket",
  5: "Buy Fifth Ticket",
  6: "Buy Sixth Ticket",
  last: "Buy Last Ticket",
}

const BuyTickets = ({ ticketCost, gameId, isHost, player, onSuccess, type = "set", userHasGenie, isPublic, accounting }) => {
  const user = useSelector(userSelector);
  const maxTickets = useSelector(state => state.games?.data?.maxTickets);
  const isLoading = useSelector(state => state.tickets?.createPending);
  const currentNumberOfTickets = useSelector(gameTicketsSelector).filter(ticket => ticket.playerId === user._id).length;
  const [showAdsInst, setShowAdsInst] = React.useState(false);
  const [adWasShown, setAdWasShown] = React.useState(false);
  // return (
  //   <Banner dark style={styles.banner}>
  //     <Text
  //       style={[
  //         CommonStyles.text16,
  //         CommonStyles.whiteText,
  //         CommonStyles.textAlignCenter,
  //       ]}
  //     >
  //       Number of Tickets?
  //     </Text>
  //     <View style={styles.container}>
  //       {Array(maxTickets).fill().map((option, index) => (
  //         <TicketOption key={index + 1} style={styles[`column${maxTickets < 4 ? maxTickets : 3}`]} gameId={gameId} label={index + 1} units={index + 1} player={player} onSuccess={onSuccess} />
  //       ))}
  //     </View>
  //   </Banner>
  // )
  return type !== 'set' ? (currentNumberOfTickets < maxTickets) ? (
    <View style={{ alignSelf: "stretch", marginBottom: 10 }}>
      {
        !adWasShown && showAdsInst && !currentNumberOfTickets ? <AdsInst minDelayInSec={1} unit='resultInst' isFacebookAds={true}/> : null
      }
      <TicketCard
        ticketId={"ticketid"}
        isHost={true}
        gameId={gameId}
        playerId={player._id}
        playerName={player.name}
        bogey={false}
        blur={true}
        readOnly={true}
        isOwn={true}
        key={"ticketid"}
        genie={userHasGenie}
      >
        <View style={[StyleSheet.absoluteFill, styles.nonBlurredContent]}>
          <ThemeButton
            type="primary"
            style={{ width: 250, backgroundColor: "#25D366" }}
            onPress={() => {
              Analytics.logEvent('buy_tickets_blurred_view', {
                contentType: 1,
                itemId: 1,
                method: 'click'
              });
              if(currentNumberOfTickets === 0){
                !adWasShown && setShowAdsInst(true);
                // show ad if returning user only
                // services.magic.create({
                //   type: "rating",
                //   screen: name,
                //   gameId
                // }).then(() => {
                //   res?.value?.rate && setShowAdsInst(true);
                // })
              }
              services.tickets.create({ gameId, units: 1, playerId: player._id, playerName: player.name }).then(() => {
                setShowAdsInst(false);
                !adWasShown && setAdWasShown(true);
              })
            }}
          >
            {
              isLoading ?
                <ActivityIndicator size="small" color={Colors.secondaryColor} />
                :
                <>
                <Text allowFontScaling={false} style={[CommonStyles.whiteText, CommonStyles.textBold]}>{ticketCost && isPublic ? 
                  getPaidTicketText[maxTickets == 1 || maxTickets !== currentNumberOfTickets + 1 ? currentNumberOfTickets + 1 : "last"] :
                  getTicketText[maxTickets == 1 || maxTickets !== currentNumberOfTickets + 1 ? currentNumberOfTickets + 1 : "last"]} 🤩</Text>
                {
                  ticketCost && (isPublic || accounting) ? <Text allowFontScaling={false} style={[CommonStyles.whiteText, CommonStyles.textBold]}>Price: {isPublic ? `${ticketCost} 💎` : `₹ ${ticketCost}`}</Text> : null
                }
                </>
            }
          </ThemeButton>
        </View>
      </TicketCard>
    </View>
  ) : null
    : (
      <Banner dark style={styles.banner}>
        <Text
          style={[
            CommonStyles.text16,
            CommonStyles.whiteText,
            CommonStyles.textAlignCenter,
          ]}
        >
          {/* {isHost ? `Get tickets for self` : `Request Tickets`} */}
        Number of Tickets?
      </Text>
        <View style={styles.container}>
          {Array(maxTickets).fill().map((option, index) => (
            <TicketOption key={index + 1} style={styles[`column${maxTickets < 4 ? maxTickets : 3}`]} gameId={gameId} label={index + 1} units={index + 1} player={player} onSuccess={onSuccess} />
          ))}
        </View>
      </Banner>
    )
};

export default BuyTickets;

const styles = StyleSheet.create({
  banner: {
    paddingVertical: 20,
    maxWidth: 270
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap"
  },
  column3: {
    flexBasis: "33%",
    paddingHorizontal: 10,
    alignItems: "center"
  },
  column2: {
    flexBasis: "50%",
    alignItems: "center"
  },
  column1: {
    flexBasis: "100%",
    alignItems: "center"
  },
  option: {
    height: 40,
    borderRadius: Units.cornerRadius,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
    minWidth: "auto",
  },
  nonBlurredContent: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "rgba(255, 255, 255, 0.55)"
  }
});
