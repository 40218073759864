import React, { useState, useEffect } from "react";

import client, { rawServices } from "../ApiClient";
import { RenderItem } from "../screens/JoinScreen";

const PinnedGame = ({ navigation }) => {
  const [games, setGames] = useState([]);

  useEffect(() => {
    // TODO: Figure out why rawservoce triggers dispatch event and use that instead of client

    // rawServices.games.find({
    //   query: {
    //     pinned: true,
    //     $select: ['_id', 'startAt', 'scheduledAt', 'updatedAt', 'hostId', 'hostName', 'public', 'host']
    //   }
    // }).then(res => {
    //   setGames(res.value)
    // });

    client.service('games').find({
      query: {
        pinned: true,
        $select: ['_id', 'startAt', 'scheduledAt', 'updatedAt', 'hostId', 'hostName', 'public', 'host']
      }
    }).then(res => {
      setGames(res)
    });
  }, []);

  return (
    games && games.length ? games.map(
      game => (
        <RenderItem key={game._id} item={game} navigation={navigation} pinned />
      )
    )
      : null
  );
}

export default React.memo(PinnedGame)