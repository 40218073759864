import React from 'react';
import { StyleSheet, Text, View } from "react-native";
import { useSelector } from 'react-redux'

import commonStyles from '../../styles/common';
import { currentGameSelector } from '../../selectors';
import { calculateTimeLeft } from '../../utils';

const CountdownTimer = () => {
  const game = useSelector(currentGameSelector);
  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft(game.scheduledAt));
  let subtitle = '';

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(game.scheduledAt));
    }, 1000);

    return () => clearTimeout(timer);
  });

  if (game.startAt)
    subtitle = `In progress`;
  else if (timeLeft.seconds || timeLeft.minutes > 1 || timeLeft.hours)
    if (timeLeft.hours) {
      subtitle = `Scheduled at ${formatTime(game.scheduledAt)}`;
    } else {
      subtitle = `Starting in ${timeLeft.formatted}`;
    }
  else
    subtitle = "Waiting for host to start the game ⌛";

  return (
    <View style={[styles.container, { justifyContent: "center" }]}>
      <Text style={[commonStyles.whiteText, commonStyles.textSmall]}>{subtitle}</Text>
    </View>
  )
}

export default CountdownTimer;

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  }
});
