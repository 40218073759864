import { createSlice } from "@reduxjs/toolkit";

const bogeys = createSlice({
  name: "bogeys",
  initialState: {},
  reducers: {
    addItem(state, { payload: data }) {
      state[data.gameId] = [...(state[data.gameId] || []), data]
    },
    deleteItem(state, { payload: { _id, gameId } }) {
      const index = state[gameId].findIndex((item) => item._id === _id);
      state[gameId].splice(index, 1)
    }
  },
});

export const { addItem, deleteItem, empty } = bogeys.actions;

export default bogeys.reducer;
