import { createSlice } from "@reduxjs/toolkit";

const dividendsShortlist = createSlice({
  name: "dividendsShortlist",
  initialState: [],
  reducers: {
    initializeList(state, { payload }) {
      if (state.length === 0) {
        payload.map(item => state.push({ units: 1, ...item }))
      }
    },
    toggleSelection(state, { payload }) {
      payload.map(
        ({ name }) => {
          const item = state.find(item => item.name === name)
          if (item) {
            item.selected = !item.selected;
          }
        }
      )
    },
    incrementUnits(state, { payload }) {
      const item = state.find(item => item.name === payload)
      if (item) {
        item.units = item.units + 1;
      }
    },
    decrementUnits(state, { payload }) {
      const item = state.find(item => item.name === payload)
      if (item && item.units > 1) {
        item.units = item.units - 1;
      }
    }
  },
});

export const { initializeList, toggleSelection, incrementUnits, decrementUnits } = dividendsShortlist.actions;

export default dividendsShortlist.reducer;
