import * as React from "react";
import { View, Image, StyleSheet } from "react-native";

export default function Logo(props) {
  return (
    <View style={styles.logoContainer}>
      <Image
        source={require("../assets/images/heading.png")}
        style={styles.logo}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  logoContainer: {
    alignItems: "center",
    paddingBottom: 20
  },
  logo: {
    width: 300,
    height: 55,
    resizeMode: "contain",
  },
});
