import * as React from "react";
import {
  View,
  TextInput
} from "react-native";
import { useDispatch } from "react-redux";
import { userAuthenticated } from "../reducers/auth";

import Colors from "../constants/Colors";
import ThemeButton from "../components/ThemeButton";

import { services } from "../store";

export default function EditProfile({user, onclose}){
  const [name, setName] = React.useState(user.name);
  const dispatch = useDispatch();
  return (
    <View style={{ flexDirection: "row", alignItems: "flex-start", padding: 15 }}>
      <View style={{ marginHorizontal: 15 }}>
        <TextInput
          allowFontScaling={false}
          style={{
            height: 37,
            borderColor: Colors.white,
            borderWidth: 1,
            borderRadius: 4,
            color: Colors.white,
            width: 200,
            fontWeight: "bold",
            paddingLeft: 10,
            paddingRight: 10,
          }}
          placeholder="Enter name..."
          placeholderTextColor="white"
          value={name}
          onChangeText={name => setName(name)}
          selectTextOnFocus={true}
          disableFullscreenUI={true}
        />
      </View>
      <ThemeButton
        type="primary"
        onPress={() => name && services.users.patch(null,{name}).then((data) => {
          if(data?.value?.length)
            dispatch(userAuthenticated(data.value[0]));
            onclose();
        })}
        text="Change"
      />
    </View>
  )

}