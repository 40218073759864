import * as React from "react";
import { Text, View } from "react-native";
import * as Animatable from 'react-native-animatable';

const BogeyStamp = () => <View style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, justifyContent: "center", alignItems: "center", backgroundColor: "rgba(255, 255, 255, 0.55)" }}>
  <Animatable.View animation="bounceIn" useNativeDriver>
    <View style={{ transform: [{ rotate: "-10deg" }], borderWidth: 1, borderStyle: "solid", borderColor: "#D23", padding: "0.25rem 1rem", borderRadius: 2, alignSelf: "center", padding: 10 }}>
      <Text
        style={{
          color: "#D23", transform: [{ rotate: "2deg" }], textTransform: "uppercase", fontWeight: 'bold', fontSize: 20
        }}
      >
        - Ticket bogey -
  </Text>
    </View>
  </Animatable.View>
</View>

export default BogeyStamp