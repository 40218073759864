import * as React from "react";
import { ActivityIndicator, View, StyleSheet } from "react-native";
import Colors from "../constants/Colors";

const Loader = ({ size = "large" }) => (
  <View style={styles.container}>
    <ActivityIndicator
      size={size}
      color={Colors.secondaryColor}
      style={{ margin: "auto" }}
    />
  </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  }
});


export default Loader;
