import * as React from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";

import Banner from "./Banner";
import ThemeButton from '../ThemeButton';
import CommonStyles from "../../styles/common";
import Colors from "../../constants/Colors";
import Units from "../../constants/Units";
import Icon from 'react-native-vector-icons/FontAwesome';

const messages = [
  {text: 'Do you miss claiming prize in time?', icon: '⏰'},
  {text: 'Did you miss cutting a number on your ticket?', icon: '🔢'},
  {text: 'Did you miss a prize due to poor network?', icon: '🤳'},
  {text: 'Do you find some prize rules confusing?', icon: '😖'},
  {text: 'Lost game due to distractions at home?', icon: '👩‍🍳'},
  {text: 'You\'ve the Genie. Sit back and relax!', icon: '🧞‍♂️'},
  {text: 'Your Genie from last game was restored, Enjoy!', icon: '🧞‍♂️'},
];

export const specialDiscount = {
  message: {
    title: 'Diwali offer!',
    text: 'Diwali offer, upto 80% off on Tambola Genie!',
    icon: '🪔'
  },
  isRunning: false
};

const GenieBanner = ({ showModal, hasGenie, restoredGenie, genieCounter }) => {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    if(!specialDiscount.isRunning && !hasGenie) {
      const interval = setInterval(() => {
        setIndex((index + 1) % (messages.length - 2) );
      }, 7000);
      return () => clearInterval(interval);
    }
  }, [hasGenie, specialDiscount.isRunning]);
  
  const message = !hasGenie ? 
                    specialDiscount.isRunning ? 
                      specialDiscount.message 
                      : messages[index] 
                    : restoredGenie ?
                      messages[messages.length - 1]
                      : messages[messages.length - 2];

  return message.text && (
    <TouchableOpacity onPress={showModal} style={styles.container}>
      <Banner dark style={hasGenie ? {} : styles.roundedRight}>
        <View style={styles.dumbelSection}>
          {!hasGenie ? <View>
            <Text style={CommonStyles.textLarger}>{message.icon}</Text>
          </View> : <View style={[styles.extendVerticallyToEdges]}>
            <Text style={styles.genie}>{message.icon}</Text>
          </View>
          }
          <View style={styles.flex1}>
            {/* {
              genieCounter ?
              <Text style={styles.badge}>{genieCounter}</Text> : null
            } */}
            <Text style={CommonStyles.darkBannerText}>
              {message.text}
            </Text>
          </View>
          <View>
            {!hasGenie ? <View style={[styles.cta, styles.extendVerticallyToEdges, styles.roundedRight]}>
              <Text style={styles.genie}>🧞‍♂️</Text>
            </View> : <View>
              <Text style={CommonStyles.textLarger}>✨</Text>
            </View>
            }
          </View>
        </View>
      </Banner>
    </TouchableOpacity>
  )
};

export default React.memo(GenieBanner);


const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
    width: "100%"
  },
  flex1: {
    flex: 1,
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: "row",
    justifyContent: "center"
  },
  dumbelSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  roundedRight: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  extendVerticallyToEdges : {
    marginTop: -10,
    marginBottom: -10,
  },
  cta: {
    ...CommonStyles.squareBtn,
    flexDirection: "row",
    marginRight: -10,
    width: 60,
    height: 60,
    color: Colors.darkTextColor,
    backgroundColor: Colors.secondaryColor,
    justifyContent: "center",
    alignItems: "center",
  },
  genie: {
    fontSize: 40,
  },
  badge:{
    color: Colors.darkTextColor,
    backgroundColor: Colors.secondaryColor,
    borderRadius: 11,
    borderWidth: 1,
    overflow: "hidden",
    padding: 3,
    minWidth: 24,
    fontSize: 12,
    fontWeight: "bold",
    justifyContent: "center",
    textAlign: "center"
  }
});
