import Purchases from 'react-native-purchases';

const packagesConfig = {
    'genie': {
        offerId: 'genieoffer',
        packageId: 'geniepkg',
        entitlement: 'genie'
    },
    'genie_20': {
        offerId: 'genieoffer',
        packageId: 'genie_20pkg',
        entitlement: 'genie'
    },
    'mrpgenie': {
        offerId: 'mrpgenie',
        packageId: 'mrpgeniepkg',
        entitlement: 'mrpgenie'
    },
    'paidgame': {
        offerId: 'paidgameoffer',
        packageId: 'paidgamepkg',
        entitlement: 'paidgame'
    },
    'paidgame_20': {
        offerId: 'paidgameoffer',
        packageId: 'paidgame_20pkg',
        entitlement: 'paidgame'
    }
}

const packageTypes = {
    genie: 'genie',
    genie_20: 'genie_20',
    mrpgenie: 'mrpgenie',
    paidgame: 'paidgame',
    paidgame_20: 'paidgame_20'
}

const offerTypes = {
    diamond: 'diamondoffer'
}

//TODO: ensure cache is persistent during the app lifecycle
const cachedPackages = {}
const cachedOffers = {}

const getPackage = async (packageId) => {
    if(!cachedPackages[packageId])
    {
        try{
            const offerings = await Purchases.getOfferings();
            const packageConfig = packagesConfig[packageId];
            cachedPackages[packageId] = offerings.all[packageConfig['offerId']]?.availablePackages.find(packageInfo => packageInfo.identifier == packageConfig.packageId);
        }
        catch(e){
            console.log(`Error getting pakcage ${packageId}`, e);
            return {
                success: false,
                error: e.toString()
            }
        }
    }
    return {
        success: true,
        data: cachedPackages[packageId]
    }
};

const getPackagesByOffer = async (offerId) => {
    if(!cachedOffers[offerId])
    {
        try{
            const offerings = await Purchases.getOfferings();
            cachedOffers[offerId] = offerings.all[offerId]?.availablePackages.sort((p1,p2) => p1.product?.price - p2.product?.price);
        }
        catch(e){
            console.log(`Error getting offer ${offerId}`, e);
            return {
                success: false,
                error: e.toString()
            }
        }
    }
    return {
        success: true,
        data: cachedOffers[offerId]
    }
};

const purchasePackageByInfo = async (packageInfo) => {
    try {
        const purhcaseMade = await Purchases.purchasePackage(packageInfo);
        //TODO: checking entitlement here, implenentation to change for multi package offerings
        return {
            success: purhcaseMade.purchaserInfo.entitlements.active[packagesConfig[packageId]?.entitlement]
        }
    }
    catch(e){
        console.log('Error making purchase pakcage ${packageId}', e);
        return {
            success: false,
            error: e?.message
        }
    }
}

const purchasePackage = async (packageId) => {
    !cachedPackages[packageId] && await getPackage(packageId);
    const packageInfo = cachedPackages[packageId];
    if(packageInfo){
        return purchasePackageByInfo(packageInfo);
    }
}

export {packageTypes, offerTypes, purchasePackage, getPackage, getPackagesByOffer, purchasePackageByInfo}