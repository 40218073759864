import * as React from "react";
// import { Audio } from 'expo-av';
import { TouchableWithoutFeedback, StyleSheet, Text, View } from "react-native";
import * as Animatable from 'react-native-animatable';
import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'

import UserInstructions from './UserInstructions';
import Dimensions from '../../constants/Layout';
import commonStyles from '../../styles/common';
import Colors from "../../constants/Colors";
import usePrevious from "../../utils/hooks/usePrevious";
import { lastDrawnNumbersSelector } from "../../selectors";
import * as Speech from 'expo-speech';
import { AnimatedCircularProgress } from 'react-native-circular-progress';

const newClaimSelector = createSelector(
  state => state.claims.queryResult?.data || [],
  lastDrawnNumbersSelector,
  (claims, lastDrawnNumbers) => !!claims.filter(claim => claim.success && claim.currDraw === lastDrawnNumbers.length).length
)
const NumbersInPreview = Dimensions.window.width < 667 ? 7 : Dimensions.window.width == 667 ? 8 : 9;

const LastDrawnNumbers = ({ isHost, openBoard, gameIsOver, totalGameTickets, timer, muted }) => {
  const lastDrawnNumbers = useSelector(lastDrawnNumbersSelector);
  const totalNumbersDrawn = lastDrawnNumbers.length;
  const numberPreview = lastDrawnNumbers.slice(
    Math.max(lastDrawnNumbers.length - NumbersInPreview, 0)
  );
  const lastNumber = lastDrawnNumbers && lastDrawnNumbers.length && String(lastDrawnNumbers[lastDrawnNumbers.length - 1]);
  // console.log('lastNumber', lastNumber);
  // const soundRef = React.useRef(null);
  // React.useEffect(() => {
  //   async function loadAudio() {
  //     try {
  //       const { sound, status } = await Audio.Sound.createAsync(
  //         require('../../assets/sounds/bell.mp3'),
  //         { shouldPlay: false }
  //       );
  //       sound.setIsMutedAsync(muted);
  //       soundRef.current = sound;
  //     } catch (error) {
  //       // An error occurred!
  //       console.error("Error while loading audio: ", error);
  //     }
  //   };
  //   loadAudio();
  // }, []);
  React.useEffect(() => {
    return () => {
      Speech.stop();
    }
  }, []);
  React.useEffect(() => {
    !muted && Speech.stop();
  }, [muted]);

  const prevDrawnNumbersLength = usePrevious(lastDrawnNumbers?.length);
  if (!muted && (prevDrawnNumbersLength !== lastDrawnNumbers.length)) {
    // soundRef.current.replayAsync();
    const digits = lastNumber && lastNumber.split && lastNumber.split('');
    let tospeak;
    if(digits && digits.length == 2){
      tospeak = `${digits[0]} and ${digits[1]}, ${lastNumber}`;
    }
    else if(digits && digits.length == 1){
      tospeak = `Single number ${digits[0]}`
    }
    Speech.stop();
    tospeak && Speech.speak(tospeak);
  }

  if (!gameIsOver && isHost && totalNumbersDrawn == 90) {
    return <UserInstructions step={totalNumbersDrawn} />
  }
  // console.log(`newClaim ${newClaim} ${!!prevDrawnNumbersLength && newClaim}`);
  return (
    <View style={styles.container}>
      {lastDrawnNumbers.length > 10 ? (
        <View style={[styles.numberContainer]} key="...">
          <TouchableWithoutFeedback
            onPress={openBoard}
          >
            <Text style={styles.pastDrawn}>...</Text>
          </TouchableWithoutFeedback>
        </View>
      ) : null}
      {numberPreview.map((number, index) => (
        <Number
          number={number}
          isLatest={index === numberPreview.length - 1}
          timer={timer || isHost && 6000}
          resetTimer={!!prevDrawnNumbersLength}
          key={`key-${number}-${!!prevDrawnNumbersLength}`}
        />
      ))}
      {
        (totalGameTickets > 0) && isHost && (totalNumbersDrawn <= 2) ?
          <UserInstructions step={totalNumbersDrawn} />
          : !isHost && (totalNumbersDrawn < 1) ?
            <UserInstructions>{/*Host hasn't started the game yet ⏳*/}</UserInstructions>
            : null
      }
    </View>
  );
};

// const Number = React.memo(({ number, isLatest }) => {
//   return (
//     <Animatable.View
//       animation="bounceIn"
//       direction="alternate"
//       useNativeDriver
//       style={[styles.numberContainer, isLatest ? styles.latestContainer : {}]}
//     >
//       <Text style={[styles.pastDrawn, isLatest ? commonStyles.textLarge : {}]}>
//         {number}
//       </Text>
//     </Animatable.View>
//   );
// });

const Number = React.memo(({ number, isLatest, timer, resetTimer }) => {
  const newClaim = useSelector(newClaimSelector);
  return timer && isLatest ? (
    <Animatable.View
      style={styles.numberContainer}
      animation="bounceIn"
      direction="alternate"
      useNativeDriver
    >
      <AnimatedCircularProgress
          size={46}
          width={3}
          duration={resetTimer ? timer : 0}
          fill={100}
          rotation={0}
          tintColor={Colors.white}
          backgroundColor={Colors.primaryColor}>{
            () => (
              <View style={[styles.latestContainer, styles.numberWithTimer]}>
                <Text allowFontScaling={false} style={[styles.pastDrawn, isLatest ? commonStyles.textLarge : {}]}>
                  {number}
                </Text>
              </View>
            )
          }
      </AnimatedCircularProgress>
    </Animatable.View>
  )
    : (
      <Animatable.View
        animation="bounceIn"
        direction="alternate"
        useNativeDriver
        style={[styles.numberContainer, isLatest ? styles.latestContainer : {}]}
      >
        <Text allowFontScaling={false} style={[styles.pastDrawn, isLatest ? commonStyles.textLarge : {}]}>
          {number}
        </Text>
      </Animatable.View>
    );
});

export default React.memo(LastDrawnNumbers);

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    height: 40
  },
  numberContainer: {
    marginLeft: "4%",
  },
  latestContainer: {
    width: 40,
    height: 40,
    backgroundColor: Colors.primaryColor,
    borderColor: Colors.white,
    borderWidth: 1,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
  },
  numberWithTimer: {
    borderWidth: 0
  },
  pastDrawn: {
    fontSize: 20,
    color: "white",
    borderColor: Colors.white,
    borderRadius: 10,
  }
});
