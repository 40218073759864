import { createSelector } from '@reduxjs/toolkit';
import { uniqueListByKey } from '../utils';

const gameTickets = createSelector(
  state => state.tickets.queryResult?.data || [],
  state => state.auth.user || {},
  state => state.games?.data?.hostId === state.auth.user?._id,
  (tickets, user, isHost) => uniqueListByKey(tickets, '_id').sort((t1, t2) => {
    if (isHost && (t1.bougie || t2.bougie)) {
      if (t1.bougie && t2.bougie) {
        return (new Date(t1.updatedAt) - new Date(t2.updatedAt))
      } else {
        return t1.bougie && !t2.bougie ? -1 : 1
      }
    }
    if ((t1.playerId === user._id) && (t2.playerId === user._id)) {
      if ((t1.isApproved === t2.isApproved) && (t1.bougie === t2.bougie)) {
        return (new Date(t1.updatedAt) - new Date(t2.updatedAt))
      } else if (t1.bougie !== t2.bougie) {
        return t1.bougie ? 1 : -1
      } else if (t1.isApproved !== t2.isApproved) {
        return t1.isApproved ? -1 : 1
      }
    } else {
      if ((t1.isApproved === t2.isApproved) && (t1.bougie === t2.bougie)) {
        return (new Date(t1.updatedAt) - new Date(t2.updatedAt))
      } else if (t1.bougie !== t2.bougie) {
        return t1.bougie ? -1 : 1
      } else if (t1.isApproved !== t2.isApproved) {
        return t1.isApproved ? 1 : -1
      }
    }
  })
)

export default gameTickets