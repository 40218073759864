export const calculateTimeLeft = (time) => {
  const difference = +new Date(time) - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.ceil((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60)
    };
  }

  if (timeLeft.minutes > 1) {
    timeLeft.formatted = timeLeft.minutes === 1 ? `${timeLeft.minutes} min` : `${timeLeft.minutes} mins`;
  } else if (timeLeft.seconds) {
    timeLeft.formatted = timeLeft.seconds === 1 ? `${timeLeft.seconds} sec` : `${timeLeft.seconds} secs`;
  } else {
    timeLeft.formatted = '';
  }
  return timeLeft;
};

export const uniqueListByKey = (list, key) => {
  return list.filter(
    (item, index) => list.findIndex(
      i => i[key] === item[key]
    ) === index
  )
}