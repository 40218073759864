import { createSlice } from "@reduxjs/toolkit";

const currentClaim = createSlice({
  name: "currentClaim",
  initialState: {
    claim: {}
  },
  reducers: {
    setClaim(state, { payload: claim }) {
      state.claim = {playerId: claim.playerId, success: claim.success, _id: claim._id};
    },
    clearClaim(state) {
      state.claim = {}
    }
  },
});

export const { setClaim, clearClaim } = currentClaim.actions;

export default currentClaim.reducer;
