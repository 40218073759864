import * as React from "react";
import * as Facebook from "expo-facebook";
import { Platform, Alert, Text, TouchableOpacity } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesome } from '@expo/vector-icons';
import Icon from 'react-native-vector-icons/FontAwesome';
import * as Analytics from 'expo-firebase-analytics';

import Units from '../constants/Units';
import commonStyles from "../styles/common";
import ThemeButton from "./ThemeButton";
import featherClient from "../ApiClient";
import { userAuthenticated } from "../reducers/auth";


// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// Above import beaks phones so conditionally importing FacebookLogin only for web
let FacebookLogin;
if (Platform.OS === 'web') {
  FacebookLogin = require('react-facebook-login/dist/facebook-login-render-props').default;
}

const APP_ID = '527873157817955';

const FacebookButton = (props) => (
  <Icon.Button
    style={{ width: 250, justifyContent: "center" }}
    name="facebook"
    backgroundColor="#3b5998"
    borderRadius={Units.cornerRadius}
    {...props}
  >
    <Text style={[commonStyles.textMedium, commonStyles.whiteText]}> Login with Facebook</Text>
  </Icon.Button>
  // <TouchableOpacity {...props} style={{
  //   padding: 8,
  //   paddingHorizontal: 30,
  //   borderRadius: Units.cornerRadius,
  //   width: 250,
  //   paddingHorizontal: 15,
  //   backgroundColor: "#3b5998",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   flexDirection: "row"
  // }}>
  //   <FontAwesome name={'facebook'} size={22} color="white" />
  //   <Text style={{ marginLeft: 10, fontWeight: "bold", color: "white", fontSize: 16 }}> Login with Facebook</Text>
  // </TouchableOpacity>
)

const LogInWithFacebook = () => {
  const dispatch = useDispatch();

  const handleFbResponseToken = async (token) => {
    if (token) {
      // Get the user's name using Facebook's Graph API client.authenticate({strategy: 'facebook', token: token})
      const response = await featherClient.authenticate({
        strategy: "facebook",
        access_token: token,
      });
      dispatch(userAuthenticated(response.user));
    } else {
      // console.log(`Error: no fb token found`);
    }
  }

  const logInWithFacebook = async () => {
    try {
      await Facebook.initializeAsync(APP_ID);
      const fbResponse = await Facebook.logInWithReadPermissionsAsync({
        permissions: ["public_profile"],
      });
      handleFbResponseToken(fbResponse.token);
    } catch ({ message }) {
      // Alert.alert(`Facebook Login Error: ${message}`);
      Analytics.logEvent('facebook_login_error', { message });
    }
  }

  return (
    Platform.OS === 'web' ? (
      <FacebookLogin
        appId={APP_ID}
        autoLoad={false}
        callback={({ accessToken }) => handleFbResponseToken(accessToken)}
        scope="public_profile"
        render={renderProps => (
          <FacebookButton onPress={renderProps.onClick} />
        )}
      />
    ) : (
        <FacebookButton onPress={logInWithFacebook} />
      )
  );
};

export default LogInWithFacebook;
