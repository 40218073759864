import React from 'react';
import { StyleSheet, View } from "react-native";

import Colors from '../../constants/Colors';
import Units from '../../constants/Units';

const Banner = ({ children, dark, style }) => (
  <View style={[styles.container, dark && styles.dark, style]}>
    {children}
  </View>
)

export default React.memo(Banner);

const styles = StyleSheet.create({
  container: {
    // alignSelf: "stretch",
    borderRadius: Units.cornerRadius,
    padding: 10,
    justifyContent: "center"
  },
  dark: {
    backgroundColor: "rgba(128, 128, 128, 0.2)"
  }
});
