import { createSelector } from '@reduxjs/toolkit'
import userStateTypes from '../constants/UserStateTypes';

const paidGameCounter = createSelector(
  state => state.userstates.queryResult?.data || [],
  userstates => {
    const userState = userstates.find(userstate => userstate.type == userStateTypes.PAIDGAME && userstate.state?.active);
    return (userState && userState.state.active) || 0;
  }
)

export default paidGameCounter