import * as React from "react";
import { ImageBackground } from "react-native";

import commonStyles from "../styles/common";

export default function ScreenBackground({ children }) {
  return (
    <ImageBackground
      style={commonStyles.cover}
      imageStyle={{
        resizeMode: "cover",
      }}
      source={require("../assets/images/background.png")}
    >
      {children}
    </ImageBackground>
  );
}
