import * as React from "react";
import ReactDOM from 'react-dom'
import { StyleSheet, Text, View, Image, TouchableOpacity, Alert } from "react-native";
import * as Analytics from 'expo-firebase-analytics';
import * as Animatable from 'react-native-animatable';
import Icon from 'react-native-vector-icons/FontAwesome';

import CommonStyles from "../styles/common";
import Colors from "../constants/Colors";
import Units from "../constants/Units";
import Anchor from "./Anchor";
import Banner from "./GameElements/Banner";
import { packageTypes, purchasePackage, getPackage } from "../utils/purchase";
import Loader from "./Loader";
import { specialDiscount } from "./GameElements/GenieBanner";

const ERROR_MESSAGES = {
    'CANCELLED': /cancelled/i, // "Error: Purchase was cancelled",
    'CARD_DECLINED': /problem with the Play Store/i, //"Error: There was a problem with the Play Store",
    'PENDING': /pending/i // "Error: The payment is pending",
}

const getCurrencySymbol = (priceString) => priceString.replace(/[\d\., ]/g, '');

const BuyGenieBtn = React.memo(({isSaving, setSaving, hasGenie, restoredGenie, errorMsg, setErrorMsg, setStartClearLoaderTimer, product, packageType, genieCounter}) => {

    return (<>
        {
            !hasGenie && errorMsg ? (
                <Text style={[CommonStyles.error, CommonStyles.textSmall]}>
                    {errorMsg}
                </Text>
            ) : null
        }
        <TouchableOpacity disabled={isSaving || hasGenie} onPress={async() => {
            setSaving(product.id);
            setErrorMsg('');
            const res = await purchasePackage(packageType);
            console.log('res', res);
            if(res.success){
                //Server webhook should automatically take care of this
                Analytics.logEvent('genie_purchase_success');``
            }
            else{
                ReactDOM.unstable_batchedUpdates(() => {
                    if (res.error.match(ERROR_MESSAGES.PENDING)) {
                        // Clear loading after 10 seconds delay
                        // Alert.alert("pending received, firing effect");
                        setStartClearLoaderTimer(true);
                    } else {
                        // clear loading state immediately
                        setSaving(false);
                    }
                    if (!res.error.match(ERROR_MESSAGES.CANCELLED)) {
                        setErrorMsg(res.error);
                    }
                });
                Analytics.logEvent('genie_purchase_error');
            }
        }}>
            <Animatable.View useNativeDriver animation="pulse" iterationCount="infinite" iterationDelay={3000} style={[styles.buyBtn, hasGenie && styles.buyBtnSuccess]}>
            {
                hasGenie ? restoredGenie ?  <Text style={CommonStyles.whiteText}>Your genie from last game was restored, Enjoy! ✨</Text> : <Text style={CommonStyles.whiteText}>{`Congrats! You have ${genieCounter > 1 ? genieCounter + ' tambola genies' : 'the tambola genie'} at your service ✨`}</Text> :
                isSaving ? <Loader size="small" /> : <>
                    <View style={{flex: 2, alignItems: "center"}}>
                        <View style={{flexDirection: "row", backgroundColor: Colors.secondaryColor, padding: 8}}>
                            <View style={{flex: 1, alignItems: "center"}}>
                                {
                                    specialDiscount.isRunning ?
                                        <Text>{specialDiscount.message.icon}</Text>
                                        :
                                        <Image source={require("../assets/images/seal.png")} style={{height: 20, resizeMode: "contain"}}/>
                                }
                            </View>
                            <View style={{flex: 4, alignItems: "center"}}>
                                <Text allowFontScaling={false} style={[CommonStyles.textSmall, CommonStyles.darkText]}>Hire Now <Text allowFontScaling={false} style={[CommonStyles.textSmallest, CommonStyles.error, CommonStyles.textStrikethrough]}>{product.sale ? product.markedPrice : ''}</Text><Text allowFontScaling={false} style={[CommonStyles.textSmall, CommonStyles.darkText, CommonStyles.success]}> {product.priceString}</Text></Text>
                            </View>
                        </View>
                    </View>
                    <View style={{flex: 1, alignItems: "center", justifyContent: "center"}}>
                        {/* <Image source={require("../assets/images/tmlg.png")} style={{ height: 20, resizeMode: "contain"}} /> */}
                        <Text style={[CommonStyles.whiteText, CommonStyles.textSmall]}>
                            { specialDiscount.isRunning ? specialDiscount.message.title : product.pitch ? product.pitch : 'Single Genie' }
                        </Text>
                    </View>
                </>
            }
            </Animatable.View>
        </TouchableOpacity>
    </>)
})


export default function GetGenie({hasGenie, restoredGenie, genieCounter}) {
    const [isSaving, setSaving] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState('');
    const [startClearLoaderTimer, setStartClearLoaderTimer] = React.useState(false);

    const [product, setProduct] = React.useState({
        priceString: '⏳',
        markedPrice: ''
    });
    const [product20, setProduct20] = React.useState();
    React.useEffect(async () => {
        const res = await getPackage(packageTypes.genie);
        const mrpres = await getPackage(packageTypes.mrpgenie);
        const packageInfo = res?.success && res.data;
        const mrppackageInfo = mrpres?.success && mrpres.data;
        // const packageInfo = {product: {price: 1, price_string: '€0.59'}}
        const currency = getCurrencySymbol(packageInfo?.product?.price_string || '');
        setProduct({
            ...product,
            id: packageInfo?.product?.identifier,
            price: packageInfo?.product?.price,
            currency,
            priceString: packageInfo?.product?.price_string || '⏳',
            markedPrice: mrppackageInfo?.product?.price_string,
            sale: mrppackageInfo?.product?.price > packageInfo?.product?.price
        });
        const res20 = await getPackage(packageTypes.genie_20);
        const packageInfo20 = res20?.success && res20.data;
        const currency20 = getCurrencySymbol(packageInfo20?.product?.price_string || '');
        res20 && setProduct20({
            id: packageInfo20?.product?.identifier,
            price: packageInfo20?.product?.price,
            currency: currency20,
            priceString: packageInfo20?.product?.price_string || '⏳',
            pitch: '20 + 5 Free Genies'
        });
    }, []);

    React.useEffect(() => {
        let timerRef;
        // Alert.alert("In useEffect startClearLoaderTimer: " + startClearLoaderTimer);
        if (startClearLoaderTimer) {
            // Alert.alert("Starting timeout...")
            timerRef = setTimeout(() => {
                setSaving(false);
                setErrorMsg('');
                Alert.alert(
                    'You purchase is taking longer then usual',
                    'We will notify you once the payment is completed. Or you can try again after sometime.',
                    [{text: 'close'}],
                    { cancelable: true }
                )
            }, 10000)
        }

        return () => { clearInterval(timerRef) };
    }, [startClearLoaderTimer])

    return (
        <View style={styles.container}>
            <Animatable.Text useNativeDriver animation="zoomInRight" allowFontScaling={false} style={[CommonStyles.textAlignCenter, CommonStyles.whiteText, CommonStyles.textGiant]}>🧞‍♂️</Animatable.Text>
            {
                !hasGenie ? <Text style={[CommonStyles.textAlignCenter, CommonStyles.whiteText, CommonStyles.text16, CommonStyles.textBold]}>Hire <Text style={[CommonStyles.secondaryText]}>Tambola Genie</Text> to do the job!</Text>
                    : <>
                        <Text style={[CommonStyles.textAlignCenter, CommonStyles.whiteText, CommonStyles.text16, CommonStyles.textBold]}>You've the <Text style={[CommonStyles.secondaryText]}>Tambola Genie</Text></Text>
                        <Text style={[CommonStyles.textAlignCenter, CommonStyles.whiteText, CommonStyles.text16, CommonStyles.textBold]}>Sit back and relax!</Text>
                    </>
            }
            <View style={[{paddingBottom: 10, paddingTop: 5}]}>
                <View style={{flexDirection: "row", justifyContent: "space-around"}}>
                    <View>
                        <Text style={[CommonStyles.textLarge, CommonStyles.textAlignCenter]}>🏆</Text>
                        <Text style={[CommonStyles.whiteText, CommonStyles.textAlignCenter, CommonStyles.textSmallest]}>Never miss Claim</Text>
                        </View>
                    <View>
                        <Text style={[CommonStyles.textLarge, CommonStyles.textAlignCenter]}>🧠</Text>
                        <Text style={[CommonStyles.whiteText, CommonStyles.textAlignCenter, CommonStyles.textSmallest]}>Super Fast</Text>
                        </View>
                    <View>
                        <Text style={[CommonStyles.textLarge, CommonStyles.textAlignCenter]}>📝</Text>
                        <Text style={[CommonStyles.whiteText, CommonStyles.textAlignCenter, CommonStyles.textSmallest]}>Auto Cut</Text>
                        </View>
                    <View>
                        <Text style={[CommonStyles.textLarge, CommonStyles.textAlignCenter]}>🌐</Text>
                        <Text style={[CommonStyles.whiteText, CommonStyles.textAlignCenter, CommonStyles.textSmallest]}>No Internet</Text>
                        </View>
                </View>
            </View>
            {
                hasGenie || !isSaving || isSaving === product.id ? 
                <BuyGenieBtn genieCounter={genieCounter} packageType={packageTypes.genie} isSaving={isSaving} setSaving={setSaving} hasGenie={hasGenie} restoredGenie={restoredGenie} errorMsg={errorMsg} setErrorMsg={setErrorMsg} setStartClearLoaderTimer={setStartClearLoaderTimer} product={product} /> : null
            }
            {
                !hasGenie && product20 && product20.id && (!isSaving || isSaving === product20.id) ? 
                <BuyGenieBtn genieCounter={genieCounter} packageType={packageTypes.genie_20} isSaving={isSaving} setSaving={setSaving} hasGenie={hasGenie} restoredGenie={restoredGenie} setErrorMsg={setErrorMsg} setStartClearLoaderTimer={setStartClearLoaderTimer} product={product20} /> : null
            }
            <View style={[{paddingBottom: 10, paddingTop: 5}]}>
                <Text style={[CommonStyles.whiteText, CommonStyles.text16, CommonStyles.textBold]}>What <Text style={[CommonStyles.secondaryText]}>Genie</Text> will do for you?</Text>
                <Text style={[CommonStyles.whiteText, CommonStyles.text16]}>You can chose to simply buy your tickets and relax while the genie takes care of the difficult part.</Text>
                <Banner dark style={{marginTop: 10, marginBottom: 10 ,padding: 0}}>
                    <View style={{flexDirection: "row", justifyContent: "space-around", padding: 10}}>
                        <View style={{flex: 1}}>
                            <Text>📝</Text>
                        </View>
                        <View style={{flex: 15}}>
                            <Text style={[CommonStyles.whiteText]}>Cut a number on ticket, in-case you miss it</Text>
                        </View>
                    </View>
                    <View style={{flexDirection: "row", justifyContent: "space-around", padding: 10}}>
                        <View style={{flex: 1}}>
                            <Text>🧠</Text>
                        </View>
                        <View style={{flex: 15}}>
                            <Text style={[CommonStyles.whiteText]}>Understand difficult prize rules</Text>
                        </View>
                    </View>
                    <View style={{flexDirection: "row", justifyContent: "space-around", padding: 10}}>
                        <View style={{flex: 1}}>
                            <Text>🏆</Text>
                        </View>
                        <View style={{flex: 15}}>
                            <Text style={[CommonStyles.whiteText]}>Claim a prize as soon as rule is matched</Text>
                        </View>
                    </View>
                    <View style={{flexDirection: "row", justifyContent: "space-around", padding: 10}}>
                        <View style={{flex: 1}}>
                            <Text>🌐</Text>
                        </View>
                        <View style={{flex: 15}}>
                            <Text style={[CommonStyles.whiteText]}>Works even if internet is lost</Text>
                        </View>
                    </View>
                    <View style={{flexDirection: "row", justifyContent: "space-around", padding: 10}}>
                        <View style={{flex: 1}}>
                            <Text>😍</Text>
                        </View>
                        <View style={{flex: 15}}>
                            <Text style={[CommonStyles.whiteText]}>Unlimited games, until you win your first prize by genie</Text>
                        </View>
                    </View>
                </Banner>
                <Text style={[CommonStyles.whiteText, CommonStyles.text16]}>Make sure to complete the purchase before the first number is called.</Text>
            </View>
            {
                hasGenie || !isSaving || isSaving === product.id ? 
                <BuyGenieBtn genieCounter={genieCounter} packageType={packageTypes.genie} isSaving={isSaving} setSaving={setSaving} hasGenie={hasGenie} restoredGenie={restoredGenie} errorMsg={errorMsg} setErrorMsg={setErrorMsg} setStartClearLoaderTimer={setStartClearLoaderTimer} product={product} />:null
            }
            {
                !hasGenie ?
                    <View style={{flexDirection: "row", justifyContent: "space-evenly"}}>
                        <Text style={[CommonStyles.whiteText, CommonStyles.textSmallest]}>Still have questions?</Text>
                        <Anchor color={'#FFF'} fontSize={12} href={`http://api.whatsapp.com/send?text=Hi, have questions on hiring Tambola Genie. &phone=917477645641`}>
                            <Icon name="whatsapp" size={12} color={'#FFF'} /> Contact Support
                        </Anchor>
                    </View>
                : null
            }
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: "stretch",
      width: "100%"
    },
    contentContainer: {
      alignItems: "center"
    },
    resultsHeader: {
      paddingHorizontal: 2,
      paddingVertical: 2,
      justifyContent: "center",
      borderWidth: 1,
      borderColor: "black",
      backgroundColor: Colors.primaryColor,
      borderRadius: Units.cornerRadius
    },
    resultsItem: {
      paddingHorizontal: 2,
      paddingVertical: 2,
      borderWidth: 1,
      borderColor: "black",
      borderRadius: Units.cornerRadius
    },
    buyBtn: {
        borderRadius: Units.cornerRadius,
        marginLeft: -20,
        flexDirection: "row",
        justifyContent: "space-around",
        backgroundColor: Colors.primaryColor,
        marginBottom: 10,
        minHeight: 36,
        alignItems: "center"
    },
    buyBtnSuccess: {
        backgroundColor: Colors.successColor
    }
  });
  