import { createSelector } from '@reduxjs/toolkit'

const userstateTypes = {
  GENIE: 'GENIE',
  COINS: 'COINS',
  STATS: 'STATS',
  OTHERS: 'OTHERS'
}

const restoredGenie = createSelector(
  state => state.userstates.queryResult?.data || [],
  userstates => userstates.some(userstate => userstate.type == userstateTypes.GENIE && userstate.state?.active && userstate.state?.restored)
)

export default restoredGenie