import { createSelector } from '@reduxjs/toolkit'
import userStateTypes from '../constants/UserStateTypes';

const diamonds = createSelector(
  state => state.userstates.queryResult?.data || [],
  userstates => {
    const userState = userstates.find(userstate => userstate.type == userStateTypes.DIAMONDS && userstate.state?.active);
    if(!userState)
      return null;
    return (userState && userState.state.active);
  }
)

export default diamonds