import { Platform, StatusBar } from "react-native";
// import Toast from 'react-native-root-toast';
let Toast;
if (Platform.OS !== 'web') {
  Toast = require('react-native-root-toast').default;
}

const showToast = (message, placement, duration = 3000) => {
  if (Platform.OS === 'web') {
    return console.log(`Toast: ${message}`);
  }
  const position = placement === 'bottom' ? Toast.positions.BOTTOM : Platform.OS === 'android' ? StatusBar.currentHeight + 40 : 40
  return (
    Toast.show(message, {
      position,
      opacity: 1,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      textColor: 'white',
      containerStyle: { height: 30, borderRadius: 20, paddingVertical: 0, justifyContent: "center", paddingHorizontal: 10 },
      duration: duration,
    })
  )
}
export default showToast