import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View } from "react-native";
import * as Animatable from 'react-native-animatable';

import client from '../ApiClient'
import Colors from "../constants/Colors";
import { MonoText } from "../components/StyledText";
import Units from "../constants/Units";

export default function NetworkStatus() {
  const [isOffline, setIsOffline] = useState(null);

  useEffect(() => {
    const connected = () => setIsOffline(false)
    const disConnected = () => setIsOffline(true)
    client.io.on('disconnect', disConnected);
    client.io.on("connect", connected);

    return function cleanup() {
      client.io.off('disconnect', disConnected);
      client.io.off("connect", connected);
    };
  });

  return (
    isOffline ? (
      <Animatable.View animation="fadeInUp" useNativeDriver style={styles.offlineContainer}>
        <Text style={styles.offlineText}>connecting...</Text>
      </Animatable.View>
    ) : null
  );
}

const styles = StyleSheet.create({
  offlineContainer: {
    backgroundColor: Colors.primaryColor,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    position: 'absolute',
    width: '50%',
    left: '25%',
    bottom: 0,
    borderTopEndRadius: Units.cornerRadius,
    borderTopLeftRadius: Units.cornerRadius
  },
  offlineText: { color: Colors.white, fontWeight: 'bold' }
});
