import * as React from "react";
import {
  StyleSheet,
  Text,
  View,
} from "react-native";
import { Entypo } from '@expo/vector-icons';
import { TouchableOpacity } from "react-native";

import { Avatar } from './User';
import CommonStyles from "../styles/common";
import Colors from "../constants/Colors";
import Units from "../constants/Units";

const ListItem = ({ user, title, subtitle, onPress, arrow }) => (
  <TouchableOpacity onPress={onPress}>
    <View style={{
      flexDirection: "row",
      padding: 12,
      marginBottom: 1,
      backgroundColor: Colors.secondaryColor,
      borderRadius: Units.cornerRadius
    }}>
      <Avatar name={user.name} picture={user.picture} />
      <View style={{ marginHorizontal: 5 }}>
        <Text allowFontScaling={false} numberOfLines={1} style={[CommonStyles.textMedium, CommonStyles.darkText]}>
          {title}
        </Text>
        <Text allowFontScaling={false} style={[CommonStyles.grayText, CommonStyles.textSmall]}>
          {subtitle}
        </Text>
      </View>
      {
        arrow && <View style={[{ paddingLeft: 8, alignSelf: "center", marginLeft: 'auto' }]}>
          <Entypo
            name={'chevron-right'}
            size={30}
            style={{ marginBottom: -3 }}
          />
        </View>
      }

    </View>
  </TouchableOpacity>
)

export default ListItem;
