import * as React from "react";
import { ScrollView, Platform, StyleSheet, View, Text, TouchableOpacity } from "react-native";
// import Icon from 'react-native-vector-icons/FontAwesome';
import { useSelector, useDispatch } from "react-redux";
import { useIsFocused } from '@react-navigation/native';
import * as Analytics from 'expo-firebase-analytics';
import { requestTrackingPermissionsAsync } from 'expo-tracking-transparency';
import * as FacebookAds from 'expo-ads-facebook';
import * as Animatable from 'react-native-animatable';

import featherClient from "../ApiClient";
import CommonStyles from "../styles/common";
import deviceLanguage from "../constants/Locale";
import Logo from "../components/Logo";
import ScreenBackground from "../components/ScreenBackground";
import ThemeButton from "../components/ThemeButton";
import DevModeNotice from "../components/DevModeNotice";
// import LoginWithFacebook from "../components/LoginWithFacebook";
// import { logout } from '../reducers/auth';
import PinnedGame from "../components/PinnedGame";
import ResumeGame from "../components/ResumeGame";
import User from "../components/User";
import { diamondsSelector } from "../selectors";
import userStateTypes from "../constants/UserStateTypes";
import { Modalize } from "react-native-modalize";
import CreditDiamond from "../components/CreditDiamond";
import Dimensions from '../constants/Layout';

import { services } from "../store";

import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import Icon from 'react-native-vector-icons/FontAwesome';
import Colors from "../constants/Colors";
import EditProfile from "../components/EditProfile";

if (Constants.isDevice) {
  Notifications.setNotificationHandler({
    //TODO: shouldShowAlert false if already on gameScreen with game.id = data.gameId and type GAME_STARTED
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: false,
      shouldSetBadge: false,
    }),
  });
}

async function registerForPushNotificationsAsync() {
  let token;
  if (Constants.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      // alert('Failed to get push token for push notification!');
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync()).data;
  } else {
    // alert('Must use physical device for Push Notifications');
  }

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 150, 250, 250],
      lightColor: '#DC5F5D7C',
    });
  }

  return token;
}

export default function HomeScreen({ navigation }) {
  const [expoPushToken, setExpoPushToken] = React.useState('');
  const [fpackage, setFPackage] = React.useState();

  // Todo: move to selectors folder and reuse in prize list
  const user = useSelector(state => state.auth.user);
  const diamonds = useSelector(diamondsSelector);

  React.useEffect(() => {
    Analytics.setUserId(user.name);
  })

  React.useEffect(() => {
    services.userstates.find({ query: {type: {$in: [userStateTypes.DIAMONDS]}} });
    // showModal('BUY');//For Dev
  },[]);
  const [modalType, setModalType] = React.useState('ALL');

  const modalizeRef = React.useRef(null);
  const hideModal = React.useCallback(() => {
    modalizeRef.current?.close();
  }, [modalizeRef]);

  const showModal = React.useCallback((toModalType) => {
    setModalType(toModalType);
    modalizeRef.current?.open();
  }, [modalizeRef]);

  if(Platform.OS === 'ios') {
    React.useEffect(() => {
      (async () => {
        const { status } = await requestTrackingPermissionsAsync();
        if (status === 'granted') {
          // Alert.alert('Yay! I have user permission to track data');
          FacebookAds.AdSettings.setAdvertisingTrackingEnabled(true);
          // Any similar setting required for admob??
        }
      })();
    }, []);
  }

  const notificationListener = React.useRef();
  if(Platform.OS != 'web'){
    React.useEffect(() => {
      registerForPushNotificationsAsync().then(token => setExpoPushToken(token));
  
      // This listener is fired whenever a notification is received while the app is foregrounded
      // Notifications.addNotificationReceivedListener(notification => {
      //   // setNotification(notification);
      //   console.log(notification)
      // });
  
      // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
      notificationListener.current = Notifications.addNotificationResponseReceivedListener(response => {
        const data = response?.notification?.request?.content.data || null;
  
        if (data?.type === 'GAME_STARTED') {
          navigation.navigate("GameArea", {
            gameId: data.gameId.trim().toLowerCase()
          });
        }
      });
  
      return () => {
        Notifications.removeNotificationSubscription(notificationListener.current);
      };
    }, []);
  }

  React.useEffect(() => {
    expoPushToken && services.users.patch(user._id, {
      "$addToSet": { "pushTokens": expoPushToken }
    })
  }, [expoPushToken])

  const isFocused = useIsFocused();

  return (
    <ScreenBackground>
      <View style={styles.container}>
        <ScrollView
          style={styles.container}
          contentContainerStyle={styles.contentContainer}
        >
          <Logo />
          <View style={styles.innerContent}>
            <View style={{ flexDirection: "row", alignItems: "stretch", justifyContent: "space-between", height: 90, marginBottom: 15 }}>
              <TouchableOpacity onPress={() => showModal('PROFILE')}>
                <User editable={true} online={true} name={user.name} picture={user.picture} style={{ maxWidth: 105, flexDirection: "row", height: 90 }}>
                  {/* <ThemeButton type="underline" text="Logout" onPress={async () => {
                        await featherClient.authentication.logout();
                        dispatch(logout());
                      }} /> */}
                      {/* <View style={{ flexDirection: 'row', flexWrap: "wrap" }}>
                      {
                        diamonds != null ? <Text allowFontScaling={false} style={[CommonStyles.textBold, CommonStyles.textSmallest, CommonStyles.darkText, CommonStyles.hSpaced]}>{diamonds} 💎</Text> : null
                      }
                    </View> */}
                </User>
              </TouchableOpacity>
              <View style={[CommonStyles.streachItems, { justifyContent: "space-between", marginLeft: 15, flexGrow: 1 }]}>
                <ThemeButton
                  type="secondary"
                  onPress={() => {
                    services.games.create({}, {
                      query: {locale: deviceLanguage}
                    }).then((response) =>
                      navigation.navigate("GameArea", {
                        gameId: response.value._id,
                      })
                    );
                  }}
                  text="Host Game"
                />
                <ThemeButton
                  type="secondary"
                  onPress={() => navigation.navigate("JoinGame")}
                  text="I've an Invite Code"
                />
              </View>
            </View>
            {isFocused ? <PinnedGame navigation={navigation} /> : null}
          </View>
          <DevModeNotice />
        </ScrollView>
        {isFocused ? <ResumeGame navigation={navigation} /> : null}
        {/* <Animatable.Text useNativeDriver animation="zoomInRight" allowFontScaling={false} style={[CommonStyles.textGiantMinor, styles.offer]}>💰</Animatable.Text> */}
        {/* <View style={styles.container}>
          <Text style={[CommonStyles.textGiantMinor, styles.offer, styles.elevationLow]}>💰</Text>
        </View> */}
        {/* TODO: based on diamonds balance */}
        {/* <Animatable.View useNativeDriver animation="pulse" iterationCount="infinite" iterationDelay={6000} style={[styles.offer]}>
          <TouchableOpacity onPress={() => showModal('BUY')}>
            <Text allowFontScaling={false} style={[CommonStyles.textGiantMinor, {transform: [{ rotate: '25deg'}]}]}>💰</Text>
          </TouchableOpacity>
        </Animatable.View>
        <Animatable.View useNativeDriver animation="shake" iterationCount="infinite" delay={2000} iterationDelay={6000} style={[styles.offerTR]}>
          <TouchableOpacity onPress={() => showModal('BONUS')}>
            <Text allowFontScaling={false} style={[CommonStyles.textGiantMinor, {transform: [{ rotate: '-15deg'}]}]}>🎁</Text>
          </TouchableOpacity>
        </Animatable.View>
        <Animatable.View useNativeDriver animation="flash" iterationCount="infinite" delay={4000} iterationDelay={6000} style={[styles.offerTL]}>
          <TouchableOpacity onPress={() => showModal('WHEEL')}>
            <Text allowFontScaling={false} style={[CommonStyles.textGiantMinor, {transform: [{ rotate: '10deg'}]}]}>🎡</Text>
          </TouchableOpacity>
        </Animatable.View> */}
        {/* <Animatable.View useNativeDriver animation="flash" iterationCount="infinite" delay={4000} iterationDelay={6000} style={[styles.offerTL]}>
          <TouchableOpacity onPress={() => showModal('ADS')}>
            <Text allowFontScaling={false} style={[CommonStyles.textGiantMinor, {transform: [{ rotate: '20deg'}]}]}>📽️</Text>
          </TouchableOpacity>
        </Animatable.View> */}
        {/* <Text style={[CommonStyles.textGiantMinor, styles.offer]}>💰</Text> */}
        {/* <Text style={[CommonStyles.textLarge, styles.offer, CommonStyles.whiteText]}>A</Text> */}
        <Modalize
          adjustToContentHeight={true}
          ref={modalizeRef}
          modalStyle={[{ backgroundColor: "#281121", zIndex: 999}, {marginHorizontal: (Dimensions.window.width - 420) / 2}]}
        >
          <View style={CommonStyles.modalContentHeader} key="2">
            <Text numberOfLines={1} style={[CommonStyles.textLarge, CommonStyles.whiteText]}>
              {modalType == 'BUY' ? 'Buy Diamonds' : modalType == 'BONUS' ? 'Diamond Rewards' : modalType == 'ADS' ? 'Watch Ads for Diamonds' : modalType == 'WHEEL' ? 'Lucky Wheel' : modalType == 'PROFILE' ? 'Edit Name' : ''}
            </Text>
            <ThemeButton
              type="link"
              style={[CommonStyles.squareBtn, CommonStyles.modalHeaderCloseBtn]}
              onPress={hideModal}
            >
              <Icon name="close" size={16} color={Colors.white} />
            </ThemeButton>
          </View>
          {
            modalType == 'PROFILE' ? <EditProfile user={user} onclose={hideModal}/>:
            <CreditDiamond type={modalType}/>
          }
        </Modalize>
      </View>
    </ScreenBackground>
  );
}

HomeScreen.navigationOptions = {
  header: null,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    paddingTop: 30,
    alignItems: "center",
  },
  innerContent: {
    width: 350,
    // height: 220,
    justifyContent: "space-between",
    alignItems: "stretch",
    marginTop: 10
  },
  offer: {
    position: "absolute",
    left: -20,
    bottom: 20
  },
  offerTR: {
    position: "absolute",
    right: -10,
    top: 60
  },
  offerTL: {
    position: "absolute",
    left: -10,
    top: 60
  }
});
