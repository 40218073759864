import React, { useState, useEffect } from "react";
import client from "../ApiClient";
import { TouchableOpacity, View, Text } from "react-native";
import Icon from 'react-native-vector-icons/FontAwesome';
import CommonStyles from "../styles/common";
import Units from "../constants/Units";
import Colors from "../constants/Colors";

export default function ResumeGame({ navigation }) {
  const [games, setGames] = useState([]);

  useEffect(() => {
    client.service('games').find({
      query: {
        toresume: true,
        $select: ['_id', 'startAt', 'scheduledAt', 'updatedAt', 'hostId', 'hostName', 'public', 'host']
      }
    }).then(res => {
      setGames(res)
    });
  }, []);

  return (
    games && games.length ? games.map(
      game => (
        <View key={game._id} style={{ position: "absolute", bottom: "25%", right: 0 }}>
          <TouchableOpacity onPress={() => navigation.navigate("GameArea", {
          gameId: game._id })}
          style={{ flexDirection: 'row', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
            <View style={{ backgroundColor: "rgba(0, 0, 0, 0.8)", borderWidth: 2, borderStyle: "solid", borderColor: 'white', justifyContent: "center", alignSelf: "center", height: 42, paddingHorizontal: 10 }}>
              <Text allowFontScaling={false} style={[CommonStyles.whiteText, CommonStyles.textMedium]}>
                {game.hostName} - {game._id.toUpperCase()}
              </Text>
            </View>
            <View style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: Units.cornerRadius,
              borderBottomRightRadius: Units.cornerRadius,
              height: 42,
              width: 45,
              minWidth: "auto",
              paddingHorizontal: 8,
              backgroundColor: Colors.primaryColor,
              alignItems: "center",
              justifyContent: "center"
            }}>
              <Icon name="sign-in" size={20} color={Colors.white} />
            </View>
          </TouchableOpacity>
        </View>
      )
    )
      : null
  );
}