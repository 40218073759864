import * as React from "react";
import { Platform, Alert, StyleSheet, View } from 'react-native';
import { AdMobBanner } from 'expo-ads-admob';
import * as FacebookAds from 'expo-ads-facebook';
import { useSelector } from "react-redux";
import * as Analytics from 'expo-firebase-analytics';

import { userSelector } from '../selectors';

// FacebookAds.AdSettings.addTestDevice(FacebookAds.AdSettings.currentDeviceHash);
const adUnitID = {
  ios: {
    ticketsContainerSmall: "ca-app-pub-1958354350369601/3603483376",
    ticketsContainer: "ca-app-pub-1958354350369601/8534659707",
    winnersList: "ca-app-pub-1958354350369601/2352394736",
    winnersListFixed: "ca-app-pub-1958354350369601/2352394736",
    settings: "ca-app-pub-1958354350369601/1351802083",
    infoBottom: "ca-app-pub-1958354350369601/6029413695",
    infoBottomFixed: "ca-app-pub-1958354350369601/6029413695"
  },
  android: {
    ticketsContainerSmall: "ca-app-pub-1958354350369601/3016966493",
    ticketsContainer: "ca-app-pub-1958354350369601/5682648541",
    winnersList: "ca-app-pub-1958354350369601/6219379755",
    winnersListFixed: "ca-app-pub-1958354350369601/6219379755",
    settings: "ca-app-pub-1958354350369601/8715286781",
    infoBottom: "ca-app-pub-1958354350369601/3403250350",
    infoBottomFixed: "ca-app-pub-1958354350369601/3403250350",
  }
}

const fbplacementId = {
  ios: {
    ticketsContainer: '341873750168857_341877250168507',
    winnersList: '341873750168857_381245136231718',
    winnersListFixed: '341873750168857_381245136231718',
    infoBottom: '341873750168857_381245852898313',
    infoBottomFixed: '341873750168857_381245852898313',
    native: '341873750168857_342017426821156',
    demo: 'IMG_16_9_APP_INSTALL#YOUR_PLACEMENT_ID'
  },
  android: {
    ticketsContainer: '341873750168857_341882006834698',
    winnersList: '341873750168857_381244406231791',
    winnersListFixed: '341873750168857_387853052237593',
    infoBottom: '341873750168857_381241692898729',
    infoBottomFixed: '341873750168857_387854932237405',
    native: '341873750168857_342018303487735',
    demo: 'IMG_16_9_APP_INSTALL#YOUR_PLACEMENT_ID'
  }
}

const AdMobBannerWrapper = React.memo(
  (props) => (
    <AdMobBanner {...props} />
  )
)

const FacebookAdsBannerWrapper = React.memo(
  (props) => (
    <FacebookAds.BannerAd {...props} />
  )
)

const AdsBanner = React.memo(
  ({ fixed, width, placement }) => {
    const user = useSelector(userSelector);
    const testAd = user?.name?.toLowerCase().includes('budhia') || user?.name?.toLowerCase().includes('test');
    const [showBanner, setShowBanner] = React.useState(true);
    const bannerError = React.useCallback((err) => {
      Analytics.logEvent('Ads_banner_load_error', { error: JSON.stringify(err) });
      // Alert.alert("error");
      setShowBanner(false);
    }, []);
    const fbAdsError = React.useCallback((err) => {
      // Alert.alert(JSON.stringify(err.nativeEvent));
      Analytics.logEvent('Fb_ads_load_error', { error: JSON.stringify(err.nativeEvent) });
    }, []);
    const fbAdsOnPress = React.useCallback(() => {
      Analytics.logEvent('Fb_ads_on_press');
    }, []);

    const bannerSuccess = React.useCallback(() => {
      Analytics.logEvent('Ads_banner_load_success');
      // Alert.alert("success ");
      setShowBanner(true);
    }, []);

    // Alert.alert("rendering ad");
    const narrow = width < 468;
    const placementSuffix = narrow ? "Small" : ''

    return (Platform.OS === 'ios' || Platform.OS === 'android') ?
      Platform.OS === 'ios' ?
        <View style={[styles.containerAdmob, showBanner ? {} : { height: 0 }, fixed && width ? {width: width} : {}]}>
          <AdMobBannerWrapper
            bannerSize={narrow ? "banner" : "fullBanner"}
            adUnitID={__DEV__ || testAd ? "ca-app-pub-3940256099942544/6300978111" : adUnitID[Platform.OS][`${placement}${placementSuffix}`]}
            onDidFailToReceiveAdWithError={bannerError}
            onAdViewDidReceiveAd={bannerSuccess}
          />
        </View>
        :
        <View style={[fixed ? styles.containerFixed : styles.container, fixed && width ? {width: width} : {}]}>
          <FacebookAdsBannerWrapper
            placementId={__DEV__ || testAd ? fbplacementId[Platform.OS]['demo'] : fbplacementId[Platform.OS][placement]}
            type="standard"
            onPress={fbAdsOnPress}
            onError={fbAdsError}
          />
        </View>
      : null
  }
)

export default AdsBanner;

const styles = StyleSheet.create({
  container: {
    // backgroundColor: "rgba(128, 128, 128, 0.2)",
    width: "100%"
  },
  containerAdmob: {
    backgroundColor: "rgba(128, 128, 128, 0.2)",
    width: "100%",
    alignItems: "center",
    alignSelf: "center"
  },
  containerFixed: {
    position: "absolute",
    bottom: 0,
    width: 470,
    zIndex: 100,
    alignSelf: 'center'
  }
})
