import { AdMobInterstitial } from 'expo-ads-admob';
import * as FacebookAds from 'expo-ads-facebook';
import * as React from "react";
import {Platform} from 'react-native';
import { useSelector } from "react-redux";
import { userSelector } from '../selectors';

const adUnitID = {
    ios: {
      resultInst: "ca-app-pub-1958354350369601/1745774471"
    },
    android: {
      resultInst: "ca-app-pub-1958354350369601/8902032154"
    }
}

const fbplacementId = {
    ios: {
        resultInst: '341873750168857_424296761926555',
        demo: 'VID_HD_16_9_46S_APP_INSTALL#YOUR_PLACEMENT_ID'
    },
    android: {
        resultInst: '341873750168857_424296591926572',
        demo: 'VID_HD_16_9_46S_APP_INSTALL#YOUR_PLACEMENT_ID'
    }
  }

let ctr = 0;
export default function AdsInst({unit,minDelayInSec,isFacebookAds}) {
    const user = useSelector(userSelector);
    const testAd = user?.name?.toLowerCase().includes('budhia') || user?.name?.toLowerCase().includes('test');
    const unitId= __DEV__ || Platform.OS == 'web' || testAd ? 'ca-app-pub-3940256099942544/1033173712' : adUnitID[Platform.OS][unit];
    const fbunitId= __DEV__ || Platform.OS == 'web' || testAd ? 'VID_HD_16_9_46S_APP_INSTALL#YOUR_PLACEMENT_ID' : fbplacementId[Platform.OS][unit];
    React.useEffect(() => {
        if(Platform.OS != 'web'){
        // if(Platform.OS === 'web'){
            if(isFacebookAds){
                setTimeout(() =>
                    FacebookAds.InterstitialAdManager.showAd(fbunitId)
                    .then(didClick => {})
                    .catch(error => {}), minDelayInSec ? minDelayInSec*1000 : 0);
            }
            else{
                AdMobInterstitial.setAdUnitID(unitId);
                AdMobInterstitial.requestAdAsync({servePersonalizedAds: true}).then(() => {
                    setTimeout(() => AdMobInterstitial.showAdAsync(), minDelayInSec ? minDelayInSec*1000 : 0);
                });
            }
        }
        //will never be invoked
        else if(Platform.OS === 'android'){
            FacebookAds.InterstitialAdManager.showAd(fbunitId)
            .then(didClick => {})
            .catch(error => {});
        }
    },[]);
    return <></>
}