import { createSelector } from '@reduxjs/toolkit'

// const onlinePlayersMap = createSelector(
//   state => state.online.queryResult?.data || [],
//   online => online.reduce((map, playerId) => ({ ...map, [playerId]: true }), {})
// )
const onlinePlayersMap = createSelector(
  state => state.online || {},
  online => online
)

export default onlinePlayersMap