import * as React from "react";
import Icon from 'react-native-vector-icons/FontAwesome';
import { TouchableOpacity, ScrollView, StyleSheet, View, Text, Share, Alert, Platform } from "react-native";
import { useSelector } from 'react-redux'
import { useFocusEffect } from '@react-navigation/native';
import { createSelector } from '@reduxjs/toolkit'
import { captureRef } from "react-native-view-shot";
import * as Analytics from 'expo-firebase-analytics';
import * as Sharing from 'expo-sharing';
import Banner from "../components/GameElements/Banner";
import { Modalize } from "react-native-modalize";
import * as Animatable from 'react-native-animatable';

import CommonStyles from "../styles/common";
import ScreenBackground from "../components/ScreenBackground";
import ThemeButton from "../components/ThemeButton";
import { playersMapSelector, prizesSelector, prizePlayerMapSelector, playerRewardMapSelector, diamondsSelector } from '../selectors/';
import showRatePrompt from '../components/RateTheApp';
import { services } from '../store';
import Chat from "../components/GameElements/Chat";
import Colors from "../constants/Colors";
import Dimensions from '../constants/Layout';
import Units from "../constants/Units";
import { Avatar } from '../components/User';
import usePrevious from "../utils/hooks/usePrevious";
import PinnedGame from "../components/PinnedGame";
import AdsBanner from "../components/AdsBanner";
import AdsInst from "../components/AdsInst";
import AsyncStorage from "@react-native-async-storage/async-storage";

const isHostSelector = createSelector(
  state => state.games?.data?.hostId === state.auth.user?._id || state.auth.user?._id === '5ee4110d89bc480017461da2' || state.auth.user?._id === '5f0ce808ae1c580017ffcf3a',
  isHost => isHost
)

const accountingSelector = createSelector(
  state => state.games?.data?.accounting == true,
  accounting => accounting
)

const getWinnersNames = (winnersList, user) => {
  if (!winnersList || !winnersList.length) {
    return
  }
  let winnersListCopyForRearrangement = [...winnersList];
  const currUserIndex = winnersListCopyForRearrangement.findIndex(e => e.playerId === user._id);
  if (currUserIndex > 0) {
    const splicedItem = winnersListCopyForRearrangement.splice(currUserIndex, 1)[0];
    winnersListCopyForRearrangement.unshift(splicedItem);
  }
  return winnersListCopyForRearrangement.map(({ playerName }) => (playerName.split(' ')[0] || '')).join(', ');
}

const getWinnersAvatars = (winnersList, playersMap, user) => {
  if (!winnersList || !winnersList.length) {
    return
  }
  let winnersListCopyForRearrangement = [...winnersList];
  const currUserIndex = winnersListCopyForRearrangement.findIndex(e => e.playerId === user._id);
  if (currUserIndex > 0) {
    const splicedItem = winnersListCopyForRearrangement.splice(currUserIndex, 1)[0];
    winnersListCopyForRearrangement.unshift(splicedItem);
  }
  return winnersListCopyForRearrangement.map(({ playerId }) => {
    if (!playersMap[playerId]) {
      return null
    }
    return <Avatar size={30} key={playerId} name={playersMap[playerId].name} picture={playersMap[playerId].picture} borderRadius={50} />
  });
}

function useCapture(gameId) {
  const captureViewRef = React.useRef();

  function onCapture() {
    captureRef(captureViewRef, {
    }).then(
      uri => {
        Sharing.shareAsync('file://' + uri, { dialogTitle: `Many Congratulations to all the Winners of our Online Tambola game ${gameId.toUpperCase()}!` });
        Analytics.logEvent('results_share_success')
      },
      error => Analytics.logEvent('results_share_cancel')
    );
  }

  return {
    captureViewRef,
    onCapture
  };
}

const ShareResults = ({ onPress, style }) => (
  <View style={style}>
    <TouchableOpacity onPress={onPress} style={{ flexDirection: 'row', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
      <View style={{ backgroundColor: "rgba(0, 0, 0, 0.8)", borderWidth: 2, borderStyle: "solid", borderColor: 'white', justifyContent: "center", alignSelf: "center", height: 42, paddingHorizontal: 10 }}>
        <Text allowFontScaling={false} style={[CommonStyles.whiteText, CommonStyles.textMedium]}>
          Share results
        </Text>
      </View>
      <View style={{
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: Units.cornerRadius,
        borderBottomRightRadius: Units.cornerRadius,
        height: 42,
        width: 45,
        minWidth: "auto",
        paddingHorizontal: 8,
        backgroundColor: Colors.primaryColor,
        alignItems: "center",
        justifyContent: "center"
      }}>
        <Icon name="share-alt" size={20} color={Colors.white} />
      </View>
    </TouchableOpacity>
  </View>
)

export default function ResultsScreen({ navigation, route }) {
  const { name, params: { gameId } = {} } = route;
  const prizes = useSelector(prizesSelector);
  const prizePlayerMap = useSelector(prizePlayerMapSelector);
  const playerRewardMap = useSelector(playerRewardMapSelector);
  const playersMap = useSelector(playersMapSelector);
  const user = useSelector(state => state.auth.user);
  const isHost = useSelector(isHostSelector);
  const accounting = useSelector(accountingSelector);
  const diamonds = useSelector(diamondsSelector);
  const lastGameId = usePrevious(gameId);
  const modalizeRef = React.useRef(null);
  const { captureViewRef, onCapture } = useCapture(gameId);
  // const { captureViewRef:accountingRef, onCapture:onAccountingCapture } = useCapture(gameId);
  const hideModal = React.useCallback(() => {
    modalizeRef.current?.close();
  }, [modalizeRef]);

  React.useEffect(() => {
    const myReward = playerRewardMap[user._id];
    myReward && myReward.totalRewards && modalizeRef.current?.open();
  }, [playerRewardMap, user, accounting]);

  React.useEffect(
    () => {
      if(!isHost) {
        // AsyncStorage.getItem('gamesPlayed', (error, result) => {
        //   let gamesPlayed = new Set(result != null ? JSON.parse(result) : []);

        //   // Stop adding games after 15 to not take up too much memory for regular players
        //   if(gamesPlayed.size < 11) {
        //     gamesPlayed.add(gameId);
        //     AsyncStorage.setItem('gamesPlayed', JSON.stringify([...gamesPlayed]));
        //   }
        // });
      } else {
        const numberOfPlayers = Object.keys(playersMap).length;

        if(numberOfPlayers > 1) {
          AsyncStorage.getItem('gamesHosted', (error, result) => {
            let gamesHosted = new Set(result != null ? JSON.parse(result) : []);

            // Stop adding games after 15 to not take up too much memory for regular players
            if(gamesHosted.size < 11) {
              gamesHosted.add(gameId);
              AsyncStorage.setItem('gamesHosted', JSON.stringify([...gamesHosted]));
            }
          });
        }
      }

    }, [playersMap]
  )

  if (lastGameId && lastGameId !== gameId) {
    navigation.goBack();
  }
  useFocusEffect(
    React.useCallback(() => {
      let shouldShowRateAppAlert = false;
      async function showRateAppAlert() {
        const res = await services.magic.create({
          type: "rating",
          screen: name,
          gameId
        });
        shouldShowRateAppAlert = res?.value?.rate;
      };
      showRateAppAlert();

      return () => {
        if (shouldShowRateAppAlert) {
          showRatePrompt()
        }
      }
    }, [])
  );

  return (
    <ScreenBackground>
      <View style={styles.container}>
        <View style={[{ flexDirection: "row", margin: 10, alignItems: "center" }, CommonStyles.androidStatusBarSpace]}>
          <ThemeButton
            type="secondary"
            onPress={() => {
              navigation.goBack();
            }}
            style={CommonStyles.squareBtn}
          >
            <Icon name="chevron-left" size={20} />
          </ThemeButton>
          <View style={{ marginRight: 50, flexGrow: 1, alignItems: "center" }}>
            <Text allowFontScaling={false} style={[CommonStyles.whiteText, CommonStyles.textLarge]}>Winners 🎖️</Text>
          </View>
        </View>
        <ScrollView
          style={styles.container}
          contentContainerStyle={styles.contentContainer}
        >
          <View ref={captureViewRef} style={{ width: '65%', backgroundColor: "#281121" }}>
            {
              accounting && isHost ? (<View>
              <View style={{backgroundColor: Colors.primaryColor, flexDirection: 'row', padding: 5, borderWidth: 1, borderColor: "black", borderRadius: Units.cornerRadius, flex: 1, alignItems: "center"}}>
                <View style={{ flexDirection: "row", flex: 1}}>
                  <Text style={[CommonStyles.textLarge, CommonStyles.textAlignLeft, CommonStyles.whiteText]}>👑</Text>
                </View>
                <View style={{flex: 6, paddingRight: 10}}>
                  <Text style={[CommonStyles.textMedium, CommonStyles.textAlignLeft, CommonStyles.whiteText]}>Player Name</Text>
                </View>
                <View style={{flex: 1}}>
                  <Text style={[CommonStyles.textMedium, CommonStyles.textAlignRight, CommonStyles.whiteText]}>Won</Text>
                </View>
              </View>
              <View style={{flexDirection: "column", marginBottom: 20}}>
              {
                  Object.keys(playerRewardMap).sort((a, b) => playerRewardMap[b].totalRewards - playerRewardMap[a].totalRewards).map((id) => {
                    const player = playerRewardMap[id];
                    return (
                      <View key={`result_accounting_${id}`} style={{backgroundColor: Colors.secondaryColor, flexDirection: 'row', padding: 5, borderWidth: 1, borderColor: "black", borderRadius: Units.cornerRadius, flex: 1}}>
                        <View style={{ flexDirection: "row", height: 30, alignSelf: 'center', flex: 1}}>
                          <Avatar size={30} name={player?.name} picture={playersMap[id]?.picture} borderRadius={50} />
                        </View>
                        <View style={{flex: 6, paddingRight: 10}}>
                          <Text style={[CommonStyles.textSmall, CommonStyles.textAlignLeft]}>{player?.name}</Text>
                          <Text style={[CommonStyles.textSmall, CommonStyles.grayText, CommonStyles.textAlignLeft, CommonStyles.textWrap]}>{player?.prizes.join(', ')}</Text>
                        </View>
                        <View style={{flex: 1}}>
                          <Text style={[CommonStyles.textSmall, CommonStyles.textAlignRight]}>+₹{player?.totalRewards}</Text>
                        </View>
                      </View>
                  )})
              }
              </View>
              </View>): null
            }
            <View style={{ flexDirection: "row" }}>
              <View style={[styles.resultsHeader, styles.leftColumn]}><Text allowFontScaling={false} style={[CommonStyles.textMedium, CommonStyles.whiteText]}>Prize 🏆</Text></View>
              <View style={[styles.resultsHeader, styles.rightColumn]}><Text allowFontScaling={false} style={[CommonStyles.textMedium, CommonStyles.whiteText]}>{gameId && gameId.toUpperCase()} Winner(s)</Text></View>
            </View>
            {
              prizes.map((claim) => {
                return (
                  Array(claim.units).fill().map((x, index) => {
                    const isClaimed = prizePlayerMap[claim.name] && prizePlayerMap[claim.name][index]; //claim.claimed >= index + 1;
                    const claimTitle = (claim.units > 1) ? `${claim.name} ${index + 1}` : `${claim.name}`;

                    const subtext = isClaimed
                      ? `${getWinnersNames(prizePlayerMap[claim.name] && prizePlayerMap[claim.name][index]?.winners, user)}`
                      : '';
                    const avatars = getWinnersAvatars(prizePlayerMap[claim.name] && prizePlayerMap[claim.name][index]?.winners, playersMap, user)
                    return { ...claim, claimTitle, subtext, avatars }
                  })
                )
              }).flat().map(
                (claim, index) => (
                  <View key={claim.claimTitle} style={{ flexDirection: "row" }}>
                    <View style={[styles.resultsItem, styles.leftColumn]}>
                      <Text allowFontScaling={false} style={[CommonStyles.textMedium]}>{claim.claimTitle}</Text>
                    </View>
                    <View style={[styles.resultsItem, styles.rightColumn]}>
                      <Text allowFontScaling={false} style={[CommonStyles.textMedium]}>{claim.subtext}</Text>
                      <View style={{ flexDirection: "row", marginLeft: "auto", height: 30 }}>{claim.avatars}</View>
                    </View>
                  </View>
                )
              )
            }
            <Banner>
              <Text style={[CommonStyles.whiteText, CommonStyles.text16, CommonStyles.textAlignCenter]}>Host your own Tambola game on onlinetambolahousie.com, it's 100% free!</Text>
            </Banner>
          </View>
          <View style={{ marginTop: 15 }}>
            <Text allowFontScaling={false} style={[CommonStyles.secondaryText, CommonStyles.textMedium, { fontWeight: "bold" }]}>Coming up next</Text>
            <PinnedGame navigation={navigation} />
          </View>
          {/* <View style={{ height: 55 }}></View> */}
        </ScrollView>
        <ShareResults style={{ position: "absolute", bottom: "25%", right: 0 }} onPress={onCapture} />
        {/* <ThemeButton
          type="secondary"
          onPress={onCapture}
          style={[{ position: "absolute", top: "25%", left: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, minWidth: "auto" }]}
        >
          <Text>Share Results</Text>
          <Icon name="share-alt" size={20} />
        </ThemeButton> */}
        <View style={{ position: "absolute", top: Dimensions.window.height - 45, left: 5 }}><Chat /></View>
        {isHost ? <View style={{ position: "absolute", bottom: 0, right: 0 }}>
          <ThemeButton
            type="secondary"
            onPress={() => {
              services.games.create({ prevGameId: gameId });
              Analytics.logEvent('new_round_button_clicked');
            }}
            text={<Text><Text><Icon name='plus' size={16} /> New </Text><Text>Round</Text></Text>}
            style={{ height: 65, width: 80, minWidth: "auto", padding: 8 }}
          />
        </View> : null}
        {/* <AdsBanner placement="winnersListFixed" fixed={true} width={"65%"}/> */}
        {
          !isHost ? <AdsInst minDelayInSec={1} unit='resultInst' isFacebookAds={true}/> : null
        }
      </View>
      <Modalize
        ref={modalizeRef}
        modalStyle={[{ backgroundColor: "#281121", zIndex: 999}, {marginHorizontal: (Dimensions.window.width - 420) / 2}]}
      >
        <View style={CommonStyles.modalContentHeader} key="2">
          <Text numberOfLines={1} style={[CommonStyles.textLarge, CommonStyles.whiteText]}>
            It's time to celebrate 🥳
          </Text>
          <ThemeButton
            type="link"
            style={[CommonStyles.squareBtn, CommonStyles.modalHeaderCloseBtn]}
            onPress={hideModal}
          >
            <Icon name="close" size={16} color={Colors.white} />
          </ThemeButton>
        </View>
        <View style={{ paddingHorizontal: 15, paddingBottom: 10, alignItems: "flex-start", flexDirection: "row", justifyContent: "center" }} key="1">
          <View style={styles.container}>
            <View style={{flexDirection: "row", justifyContent: "space-around"}}> 
              <Animatable.Text useNativeDriver animation="zoomInRight" allowFontScaling={false} style={[CommonStyles.textAlignCenter, CommonStyles.secondaryText, CommonStyles.textGiantMinor]}>{accounting && '+'}{accounting && '₹'}<Text style={CommonStyles.textLarge}>{!accounting && '+'}{playerRewardMap[user?._id]?.totalRewards}</Text>{!accounting && '💎'}</Animatable.Text>
              <Animatable.Text useNativeDriver animation="zoomInRight" allowFontScaling={false} style={[CommonStyles.textAlignCenter, CommonStyles.secondaryText, CommonStyles.textGiantMinor]}><Text style={CommonStyles.textLargest}>{playerRewardMap[user?._id]?.claims?.length}</Text>🏆</Animatable.Text>
            </View>
            {/* <Text style={[CommonStyles.textAlignCenter, CommonStyles.whiteText, CommonStyles.text16, CommonStyles.textBold]}>You won <Text style={[CommonStyles.secondaryText]}>+{playerRewardMap[user?._id]?.totalRewards}</Text> shiny diamonds!</Text> */}
            <View style={[styles.container, {paddingTop: 10}]}>
              <View style={{ flexDirection: "row" }}>
                  <View style={[styles.resultsHeader, {flex: 1}]}><Text allowFontScaling={false} style={[CommonStyles.textSmall, CommonStyles.whiteText, CommonStyles.textAlignCenter]}>🎁</Text></View>
                  <View style={[styles.resultsHeader, {flex: 6}]}><Text allowFontScaling={false} style={[CommonStyles.textSmall, CommonStyles.whiteText]}>Prizes You Won</Text></View>
                  <View style={[styles.resultsHeader, {flex: 4}]}><Text allowFontScaling={false} style={[CommonStyles.textSmall, CommonStyles.whiteText, CommonStyles.textAlignCenter]}>Diamonds</Text></View>
              </View>
              {
                  playerRewardMap[user?._id]?.claims.map((claim,index) => {
                      return (<View style={{ flexDirection: "row" }} key={`rewards_${index}`}>
                          <View style={[styles.resultsItem, {flex: 1, justifyContent: "center"}]}><Text allowFontScaling={false} style={[CommonStyles.textSmall, CommonStyles.darkText, CommonStyles.textAlignCenter]}>🏆</Text></View>
                          <View style={[styles.resultsItem, {flex: 6}]}><Text allowFontScaling={false} style={[CommonStyles.textSmall, CommonStyles.darkText]}>{claim.prize}</Text></View>
                          <View style={[styles.resultsItem, {flex: 4, justifyContent: "center"}]}><Text allowFontScaling={false} style={[CommonStyles.textSmall, CommonStyles.darkText, CommonStyles.textAlignCenter, CommonStyles.hSpaced]}>{accounting && '+₹ '}{!accounting && '+'}{claim.reward || 0}{!accounting && ' 💎'}</Text></View>
                      </View>)
                  })
              }
              {/* <View style={{ flexDirection: "row" }}>
                  <View style={[styles.resultsItem, {flex: 1, justifyContent: "center", backgroundColor: "transparent"}]}><Text allowFontScaling={false} style={[CommonStyles.textSmall, CommonStyles.darkText, CommonStyles.textAlignCenter]}>💎</Text></View>
                  <View style={[styles.resultsItem, {flex: 6, backgroundColor: "transparent"}]}><Text allowFontScaling={false} style={[CommonStyles.textSmall, CommonStyles.whiteText, CommonStyles.textBold]}>Final</Text></View>
                  <View style={[styles.resultsItem, {flex: 4, justifyContent: "center", backgroundColor: "transparent"}]}><Text allowFontScaling={false} style={[CommonStyles.textSmall, CommonStyles.whiteText, CommonStyles.textAlignCenter, CommonStyles.textBold, CommonStyles.hSpaced]}>{diamonds || 0}</Text></View>
              </View> */}
            </View>
          </View>
        </View>
      </Modalize>
    </ScreenBackground>
  );
}

ResultsScreen.navigationOptions = {
  header: null,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    alignItems: "center"
  },
  resultsHeader: {
    paddingHorizontal: 10,
    paddingVertical: 4,
    justifyContent: "center",
    borderWidth: 1,
    borderColor: "black",
    backgroundColor: Colors.primaryColor,
    borderRadius: Units.cornerRadius
  },
  resultsItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 10,
    paddingVertical: 4,
    borderWidth: 1,
    borderColor: "black",
    backgroundColor: Colors.secondaryColor,
    borderRadius: Units.cornerRadius
  },
  leftColumn: {
    width: '30%',
  },
  rightColumn: {
    width: '70%',
  }
});
