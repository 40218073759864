import * as React from "react";
import { TouchableOpacity, StyleSheet, Text, View, ImageBackground, Platform } from "react-native";
import * as Animatable from 'react-native-animatable';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';

import Colors from "../../constants/Colors";
import Units from "../../constants/Units";

// const ctr = {};
const TicketCell = ({ number, onStrike, strikedInitial, shouldInitial, readOnly, blur, ticketId, index, genie}) => {
  // ctr['cell' + ticketId + number] = ctr['cell' + ticketId + number] ? ctr['cell' + ticketId + number] + 1 : 1;
  // number && ticketId!="ticketId" && console.log('Cellwa',ctr['cell' + ticketId + number]);
  const [striked, toggleStriked] = React.useState(!!strikedInitial);
  const isNotEmptyCell = !!number;
  const cellStyle = striked
    ? [styles.ticketCell, styles.crossedCell, genie && styles.crossedCellGenie]
    : [styles.ticketCell, genie && styles.ticketCellGenie];
  // This call is to save strike status in localstorage asynchronously and not block rendering
  React.useEffect(() => { !readOnly && onStrike(index, striked) }, [striked, readOnly]);
  React.useEffect(() => { striked != !!strikedInitial && toggleStriked(!!strikedInitial) }, [strikedInitial]);
  const Cell = readOnly ? View : TouchableOpacity;

  return isNotEmptyCell ? (
    <View
      style={cellStyle}
    >
      <Cell style={{ height: Units.ticketCellWidth, justifyContent: "center", alignItems: "center" }} onPress={
        () => {
          !readOnly && toggleStriked(!striked);
        }
      }>
        {
          blur ?
            <ImageBackground
              style={styles.blurBackground}
              imageStyle={styles.backgroundCover}
              blurRadius={Platform.OS == 'ios' ? 8 : Platform.OS == 'android' ? 2 : 4}
              source={require("../../assets/images/cell-bg.png")}>
              <View style={[{ margin: "auto" }, striked ? styles.crossedBubble : {}]}>
                <Text style={styles.ticketText}>  </Text>
              </View>
            </ImageBackground> :
            <View style={[{ margin: "auto" }, striked ? styles.crossedBubble : {}]}>
              <Text allowFontScaling={false} style={styles.ticketText}>{number}</Text>
            </View>
        }

      </Cell>
    </View>
  ) : (
      <View style={shouldInitial ? [styles.ticketCell, styles.shouldCell] : [styles.ticketCell, styles.emptyCell, genie && styles.emptyCellGenie]}></View>
    );
}

export default React.memo(TicketCell);

const styles = StyleSheet.create({
  ticketCell: {
    // width: Units.ticketCellWidth,
    width: "11%",
    height: Units.ticketCellWidth,
    backgroundColor: Colors.secondaryColor,
    borderColor: Colors.white,
    borderWidth: 1,
    borderRadius: Units.cornerRadius,
    alignItems: "stretch",
    justifyContent: "center",
  },
  ticketCellGenie: {
    backgroundColor: Colors.secondaryColorGenieTheme,
  },
  ticketText: {
    color: Colors.darkTextColor,
    fontSize: 20,
    // fontWeight: "bold",
  },
  emptyCell: {
    backgroundColor: Colors.secondaryColorLight,
  },
  emptyCellGenie: {
    backgroundColor: Colors.secondaryColorLightGenieTheme,
  },
  crossedCell: {
    backgroundColor: Colors.primaryColor,
  },
  crossedCellGenie: {
    backgroundColor: Colors.primaryColorGenieTheme,
  },
  shouldCell: {
    backgroundColor: Colors.primaryColor,
  },
  crossedBubble: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    width: 35,
    height: 35,
    alignItems: "center",
    justifyContent: "center",
  },
  blurBackground: {
    width: "100%",
    height: "100%"
  },
  backgroundCover: {
    resizeMode: "cover",
  }
});
