import * as React from "react";
import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { ScrollView, View, Text, Button } from "react-native";

import ThemeButton from '../ThemeButton';
import { services } from '../../store';
import commonStyles from '../../styles/common';
import showToast from '../../components/Toast';
import { prizePlayerMapSelector, prizesSelector } from "../../selectors";
import CommonStyles from "../../styles/common";

const currDrawSelector = createSelector(
  state => state.drawer.queryResult?.data?.length,
  currDraw => currDraw
)

const PrizeItem = React.memo(
  ({ claim, index, gameId, tickets, gameStarted }) => {
    const [isSaving, setSaving] = React.useState(false);
    return (
      <ThemeButton
        key={`${claim.name}${index}`}
        type={claim.isClaimed ? "primary" : "secondary"}
        style={{ marginBottom: 10, paddingVertical: 5, paddingHorizontal: 0, width: "100%", minHeight: 34 }}
        disabled={claim.noMoreClaimsPossible || isSaving}
        strikethrough={claim.noMoreClaimsPossible}
        onPress={
          () => {
            if (!tickets) {
              return showToast("Please request ticket(s) first")
            } else if (!gameStarted) {
              return showToast("Wait for host to start the game")
            }
            setSaving(true);
            services.claims.create({ gameId, prize: claim.name }).then(
              res => {
                setSaving(false);
                // Moving this logic inside confettiCannon file
                // res.value?.success && blowConfetti()
              }
            ).catch(error => {
              setSaving(false);
            })
          }
        }
      >
        <Text allowFontScaling={false} style={[commonStyles.textSmall, commonStyles.darkText, claim.noMoreClaimsPossible && commonStyles.textStrikethrough, claim.isClaimed && commonStyles.whiteText]}>
          {claim.claimTitle}
        </Text>
        {claim.subtext ? <Text allowFontScaling={false} numberOfLines={1} style={[commonStyles.textSmallest, { maxWidth: 120 }, claim.isClaimed && commonStyles.whiteText]}>{claim.subtext}</Text> : null}
      </ThemeButton>
    )
  }
)

const getWinnersNames = (winnersList, user) => {
  if (!winnersList || !winnersList.length) {
    return
  }
  let winnersListCopyForRearrangement = [...winnersList];
  const currUserIndex = winnersListCopyForRearrangement.findIndex(e => e.playerId === user._id);
  if (currUserIndex > 0) {
    const splicedItem = winnersListCopyForRearrangement.splice(currUserIndex, 1)[0];
    winnersListCopyForRearrangement.unshift(splicedItem);
  }
  return winnersListCopyForRearrangement.map(({ playerName }) => (playerName.split(' ')[0] || '')).join(', ');
}

const ClaimPrizes = ({ gameId, navigation, accounting, isPublic }) => {
  const prizes = useSelector(prizesSelector);
  const prizePlayerMap = useSelector(prizePlayerMapSelector);
  const currDraw = useSelector(currDrawSelector);
  const user = useSelector(state => state.auth.user);
  const tickets = useSelector(state => state.tickets.queryResult?.data?.length || 0);

  const areNoMoreClaimsPossible = React.useCallback(
    (claim, index) => !!prizePlayerMap[claim.name]
      && (
        prizePlayerMap[claim.name][index]?.winners.some(e => e.playerId === user._id)
        || prizePlayerMap[claim.name][index]?.currDraw < currDraw
      ),
    [prizePlayerMap, user, currDraw]
  )
  return (
    <View>
      {/* <Text style={[commonStyles.whiteText, { textDecorationLine: 'underline', textAlign: "center", padding: 2 }]} onPress={() => navigation.navigate("SettingsScreen", { gameId })}>Explanation?</Text> */}
      <ScrollView
        fadingEdgeLength={100}
        contentContainerStyle={{
          flexWrap: "wrap",
          width: 120,
        }}
      >
        {
          prizes.map((claim) => {
            return (
              Array(claim.units).fill().map((x, index) => {
                const isClaimed = prizePlayerMap[claim.name] && prizePlayerMap[claim.name][index]; //claim.claimed >= index + 1;
                const claimTitle = (claim.units > 1) ? `${claim.name} ${index + 1}` : `${claim.name}`;
                const noMoreClaimsPossible = areNoMoreClaimsPossible(claim, index);

                const subtext = isClaimed
                  ? `🏆 ${getWinnersNames(prizePlayerMap[claim.name] && prizePlayerMap[claim.name][index]?.winners, user)}`
                  : claim.reward && accounting ? `₹ ${claim.reward}` : claim.reward && isPublic ? `${claim.reward}💎` : null
                return { ...claim, noMoreClaimsPossible, claimTitle, subtext, isClaimed }
              })
            )
          }).flat().sort(
            (a, b) => {
              // console.log(`${a.claimTitle}:${a.noMoreClaimsPossible} ${b.claimTitle}:${b.noMoreClaimsPossible}`);
              if (!a.noMoreClaimsPossible && b.noMoreClaimsPossible) {
                return -1
              } else {
                return 0
              }
            }
          ).map(
            (claim, index) => (
              <PrizeItem claim={claim} index={index} gameId={gameId} tickets={tickets} gameStarted={!!currDraw} key={index} />
            )
          )
        }
        <View style={{ alignItems: "center" }}>
          <Text allowFontScaling={false} numberOfLines={1} style={[CommonStyles.whiteText, CommonStyles.textSmall]}>
            {
              !!currDraw ?
              `${prizes.reduce((sum,prize) => { sum += (prize.units - prize.claimed); return sum; }, 0)}/${prizes.reduce((sum,prize) => { sum += prize.units; return sum; }, 0)} 🎁💰`:
              `${prizes.reduce((sum,prize) => { sum += prize.units; return sum; }, 0)} 🎁💰`
            }
          </Text>
        </View>
      </ScrollView>
    </View>
  );
};

export default ClaimPrizes;
