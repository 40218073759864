import { createSelector } from '@reduxjs/toolkit'

const winsMap = createSelector(
  state => state.claims.queryResult?.data || [],
  wins => wins.filter(
    claim => claim.success
  ).reduce(
    (map, claim) => ({ ...map, [claim.playerId]: [...(map[claim.playerId] || []), claim.prize] }), {}
  )
)

export default winsMap