import { Platform } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import io from "socket.io-client";
import feathers from "@feathersjs/feathers";
import auth from "@feathersjs/authentication-client";
import socketio from "@feathersjs/socketio-client";
import reduxifyServices from "./feathers-redux";

// expo build:android -t app-bundle
// expo upload:android --key /Users/akash/Documents/Projects/tambolaexpokey/api-8365863208681564698-881355-d344648b96c9.json --track production

//TODO: change to tambolah.herokuapp.com for production
const DEV_API = "https://tambola-dev.herokuapp.com";
// const DEV_API = "https://localhost:3030";
const PROD_API = "https://onlinetambolahousie.com";
let apiUrl = __DEV__ ? DEV_API : PROD_API;

if (Platform.OS === 'web') {
  apiUrl = PROD_API;
}

const socket = io(apiUrl, {
  transports: ["websocket"],
  forceNew: true,
  upgrade: false
});
const client = feathers();

// client.hooks({
//   error(context) {
//     console.error(`Ho Ha Error in '${context.path}' service method '${context.method}'`, context);
//   }
// });

client.configure(socketio(socket));
client.configure(auth({ storage: AsyncStorage, storageKey: "auth" }));

export const rawServices = reduxifyServices(
  client,
  ["users", "games", "players", "tickets", "drawer", "claims", "prizes", "notification", "magic", "online", "dividends", "userstates"],
  { idField: "_id" }
);

export default client;
