import * as React from "react";
import Slider from '@react-native-community/slider';
import Icon from 'react-native-vector-icons/FontAwesome';
import * as Analytics from 'expo-firebase-analytics';
import { useFocusEffect } from '@react-navigation/native';
import { ScrollView, StyleSheet, View, Text, Alert } from "react-native";
import { useSelector } from 'react-redux'
import { Modalize } from "react-native-modalize";

import { services } from '../store';
import { currentGameSelector, userHasPaidGameSelector, paidGameCounterSelector} from '../selectors';
import CommonStyles from "../styles/common";
import ScreenBackground from "../components/ScreenBackground";
import ThemeButton from "../components/ThemeButton";
import UpgradeGame from "../components/UpgradeGame";
import UpgradeSmartCalculator from "../components/UpradeSmartCaclulator";
import Loader from "../components/Loader";
import Colors from "../constants/Colors";
import usePrevious from "../utils/hooks/usePrevious";

import Dimensions from '../constants/Layout';

const proSettingInterest = (name) => {
  Analytics.logEvent('pro_setting_interest', {name});
  Analytics.logEvent('pro_setting_interest_' + name);
  // Alert.alert('👑 Pro Settings 👑','Launcing soon for our premium club members.\nChoose from the available game settings on the top 🔝');
}

// const warnAutoCheck = () => {
//   Analytics.logEvent('autocheck');
//   Alert.alert('🎰 Auto Check Mode Enabled','Players will do nothing here. Claims on their ticket will be automatically made. It is for pure betting & no fun!');
// }

export default function SettingsScreen({ navigation, route }) {
  const { params: { gameId } = {} } = route;
  const game = useSelector(currentGameSelector);

  const userHasPaidGame = useSelector(userHasPaidGameSelector);
  const prevUserHasPaidGame = usePrevious(userHasPaidGame);
  const paidGameCounter = useSelector(paidGameCounterSelector);
  const makeGameAutoCheck = React.useCallback(() => {
    !game.autocheck && patchGame({ autocheck: true });
  }, [game.autocheck]);
  const makeGameAccounting = React.useCallback(() => {
    if(!game.accounting) {
      patchGame({ accounting: true });
    } else {
      showModal('ACCOUNTING');
    }
  }, [game.accounting]);
  // Make game autocheck on paid game call success
  React.useEffect(
    () => {
      if(userHasPaidGame && (prevUserHasPaidGame === false)){
        if(modalType === 'ACCOUNTING') {
          makeGameAccounting()
        } else if(modalType === 'AUTOCHECK') {
          makeGameAutoCheck()
        }
      }
    }, [userHasPaidGame, prevUserHasPaidGame, modalType]
  );

  const isSaving = useSelector(state => state.games.patchPending);
  const [maxTickets, setMaxTickets] = React.useState(game.maxTickets);
  const [ticketCost, setTicketCost] = React.useState(game.ticketCost);
  const [commission, setCommission] = React.useState(game.commission);
  React.useEffect(() => game.maxTickets && setMaxTickets(game.maxTickets), [game.maxTickets]);
  React.useEffect(() => game.ticketCost && setTicketCost(game.ticketCost), [game.ticketCost]);
  React.useEffect(() => game.commission && setCommission(game.commission), [game.commission]);
  
  const modalizeRef = React.useRef(null);
  const [modalType, setModalType] = React.useState();

  const showModal = React.useCallback((type) => {
      setModalType(type);
      modalizeRef.current?.open();
      Analytics.logEvent('auto_check_modal_shown');
  }, [modalizeRef, modalType]);

  const hideModal = React.useCallback(() => {
    modalizeRef.current?.close();
  }, [modalizeRef, modalType]);

  const renderContent = () => {
    return [
      <View style={CommonStyles.modalContentHeader} key="0">
        <Text numberOfLines={1} style={[CommonStyles.textLarge, CommonStyles.whiteText]}>
          Upgrade Game
        </Text>
        <ThemeButton
          type="link"
          style={[CommonStyles.squareBtn, CommonStyles.modalHeaderCloseBtn]}
          onPress={hideModal}
        >
          <Icon name="close" size={16} color={Colors.white} />
        </ThemeButton>
      </View>,
      <View style={{ paddingHorizontal: 15, paddingBottom: 10, alignItems: "flex-start", flexDirection: "row", justifyContent: "center" }} key="1">
        {modalType == 'AUTOCHECK' && <UpgradeGame hasPaidGame={userHasPaidGame} />}
        {modalType == 'ACCOUNTING' && <UpgradeSmartCalculator hasPaidGame={userHasPaidGame} />}
      </View>
    ]
  }

  const patchGame = React.useCallback((setting) => {
    services.games.patch(gameId, setting, {
      query: {
        $select: [
          '_id',
          'startAt',
          'scheduledAt',
          'updatedAt',
          'endAt',
          'currDraw',
          'hostId',
          'hostName',
          'public',
          'prevGameId',
          'pricing',
          'auto',
          'maxTickets',
          'disallowLateClaims',
          'limitClaims',
          'limitConcurrentClaims',
          'autoApproveTickets',
          'restoreBougie',
          'prizes',
          'insightful',
          'autocheck',
          'ticketCost',
          'accounting',
          'commission'
        ]
      }
    })
  }, [gameId])

  return (
    <ScreenBackground>
      <View style={[styles.container]}>
        <View style={[{ flexDirection: "row", margin: 10, alignItems: "center" }, CommonStyles.androidStatusBarSpace]}>
          <ThemeButton
            type="secondary"
            onPress={() => {
              navigation.goBack();
            }}
            style={CommonStyles.squareBtn}
          >
            <Icon name="chevron-left" size={20} />
          </ThemeButton>
          <View style={{ marginRight: 50, flexGrow: 1, alignItems: "center" }}>
            <Text allowFontScaling={false} style={[CommonStyles.whiteText, CommonStyles.textLarge]}>Game Settings</Text>
          </View>
          {
            isSaving ? <View style={{ position: "absolute", right: 0 }}><Loader size="small" /></View> : null
          }
        </View>
        <ScrollView
          style={styles.container}
          contentContainerStyle={styles.contentContainer}
        >
          <View>
            <Text style={[CommonStyles.whiteText, CommonStyles.textSmall]}><Text style={{ color: "red" }}>*</Text>available settings</Text>
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>Automatic number calling</Text>
              </View>
              <View style={styles.controls}>
                <ThemeButton type={!game.auto ? "secondary" : "deselected"} text="Off" style={styles.toggleBtnLeft} onPress={() => game.auto && patchGame({ auto: false })} />
                <ThemeButton type={game.auto === 8000 ? "secondary" : "deselected"} text="Slow" style={styles.toggleBtnCenter} onPress={() => game.auto != 8000 && patchGame({ auto: 8000 })} />
                <ThemeButton type={game.auto === 6000 ? "secondary" : "deselected"} text="Medium" style={styles.toggleBtnCenter} onPress={() => game.auto != 6000 && patchGame({ auto: 6000 })} />
                <ThemeButton type={game.auto === 4000 ? "secondary" : "deselected"} text="Fast" style={styles.toggleBtnRight} onPress={() => game.auto != 4000 && patchGame({ auto: 4000 })} />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>Maximum tickets per player</Text>
              </View>
              <View style={styles.controls}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textMedium, styles.controlValue]}>{maxTickets}</Text>
                <Slider
                  style={{ width: 200, height: 50 }}
                  thumbTintColor={Colors.secondaryColor}
                  value={maxTickets}
                  onValueChange={(val) => {
                    // Todo: add debounce for web
                    (game.maxTickets != val) && setMaxTickets(val);
                  }}
                  onSlidingComplete={
                    (maxTickets) => {
                      // console.log("Slide complete");
                      (game.maxTickets !== maxTickets) && patchGame({ maxTickets });
                    }
                  }
                  minimumValue={1}
                  maximumValue={6}
                  step={1}
                  minimumTrackTintColor="#FFFFFF"
                  maximumTrackTintColor="#434343"
                />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>Late claims</Text>
                <Text style={[CommonStyles.whiteText, CommonStyles.textSmallest]}>Can claim after the number has passed</Text>
              </View>
              <View style={styles.controls}>
                <ThemeButton type={!game.disallowLateClaims ? "secondary" : "deselected"} style={styles.toggleBtnLeft} text="Allow" onPress={() => game.disallowLateClaims != false && patchGame({ disallowLateClaims: false })} />
                <ThemeButton type={game.disallowLateClaims ? "secondary" : "deselected"} style={styles.toggleBtnRight} text="Don't Allow" onPress={() => game.disallowLateClaims != true && patchGame({ disallowLateClaims: true })} />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>Number of claims</Text>
                <Text style={[CommonStyles.whiteText, CommonStyles.textSmallest]}>Restrict to 2 minor and 1 Full Housie per player </Text>
              </View>
              <View style={styles.controls}>
                <ThemeButton type={game.limitClaims ? "secondary" : "deselected"} style={styles.toggleBtnLeft} text="Limited" onPress={() => game.limitClaims != true && patchGame({ limitClaims: true })} />
                <ThemeButton type={!game.limitClaims ? "secondary" : "deselected"} style={styles.toggleBtnRight} text="No Limit" onPress={() => game.limitClaims != false && patchGame({ limitClaims: false })} />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>Share / Split prize</Text>
                <Text style={[CommonStyles.whiteText, CommonStyles.textSmallest]}>How many players can claim a prize at the same number call?</Text>
              </View>
              <View style={styles.controls}>
                {
                  !game.autocheck ? 
                  <>
                  <ThemeButton type={game.limitConcurrentClaims === 1 ? "secondary" : "deselected"} style={styles.toggleBtnLeft} text="Single" onPress={() => game.limitConcurrentClaims !== 1 && patchGame({ limitConcurrentClaims: 1 })} />
                  <ThemeButton type={game.limitConcurrentClaims === 2 ? "secondary" : "deselected"} style={styles.toggleBtnCenter} text="Double" onPress={() => game.limitConcurrentClaims !== 2 && patchGame({ limitConcurrentClaims: 2 })} />
                  <ThemeButton type={game.limitConcurrentClaims === -1 ? "secondary" : "deselected"} style={styles.toggleBtnRight} text="Unlimited" onPress={() => game.limitConcurrentClaims !== -1 && patchGame({ limitConcurrentClaims: -1 })} />
                  </> :
                  <ThemeButton type={game.limitConcurrentClaims === -1 ? "secondary" : "deselected"} style={styles.toggleBtnRight} text="Unlimited" onPress={() => game.limitConcurrentClaims !== -1 && patchGame({ limitConcurrentClaims: -1 })} />
                }
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>Ticket requires your Approval?</Text>
                <Text style={[CommonStyles.whiteText, CommonStyles.textSmallest]}>Tickets requested by players needs to be manually approved by host or not</Text>
              </View>
              <View style={styles.controls}>
                <ThemeButton type={!game.autoApproveTickets ? "secondary" : "deselected"} style={styles.toggleBtnLeft} text="Yes" onPress={() => game.autoApproveTickets && patchGame({ autoApproveTickets: false })} />
                <ThemeButton type={game.autoApproveTickets ? "secondary" : "deselected"} style={styles.toggleBtnRight} text="No" onPress={() => !game.autoApproveTickets && patchGame({ autoApproveTickets: true })} />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>Bogey ticket on incorrect claim?</Text>
                <Text style={[CommonStyles.whiteText, CommonStyles.textSmallest]}>Ticket will be bogeyed on a false claim?</Text>
              </View>
              <View style={styles.controls}>
                <ThemeButton type={game.restoreBougie === -1 ? "secondary" : "deselected"} style={styles.toggleBtnLeft} text="Yes" onPress={() => game.restoreBougie !== -1 && patchGame({ restoreBougie: -1 })} />
                <ThemeButton type={game.restoreBougie === 0 ? "secondary" : "deselected"} style={styles.toggleBtnRight} text="No" onPress={() => game.restoreBougie !== 0 && patchGame({ restoreBougie: 0 })} />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.label}>
                {
                  !userHasPaidGame ? 
                  <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>Auto Check 🔒</Text> :
                  <View style={{flexDirection: "row"}}>
                    <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>Auto Check </Text>
                    <Text style={[styles.badge, paidGameCounter < 6 && paidGameCounter != 1 && styles.badgeLow]}>{paidGameCounter}</Text>
                  </View>
                }
                <Text style={[CommonStyles.whiteText, CommonStyles.textSmallest]}>Automatic prize claims</Text>
              </View>
              <View style={styles.controls}>
                <ThemeButton type={game.autocheck ? "secondary" : "deselected"} style={styles.toggleBtnLeft} text="Yes" onPress={ () => { userHasPaidGame ? makeGameAutoCheck() : showModal('AUTOCHECK');} } />
                <ThemeButton type={!game.autocheck ? "secondary" : "deselected"} style={styles.toggleBtnRight} text="No" onPress={() => game.autocheck && patchGame({ autocheck: false })} />
                <ThemeButton
                  type="link"
                  onPress={() => {
                    showModal('AUTOCHECK');
                  }}
                  text={<><Icon name='info-circle' size={16} color={Colors.white} /><Text style={[CommonStyles.whiteText, CommonStyles.text16]}> Info</Text></>}
                />
              </View>
              <View>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.label}>
                <View style={{flexDirection: "row"}}>
                {
                  !userHasPaidGame ? 
                      <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>Smart Calculator 🔒</Text>
                    :
                    <>
                      <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>Smart Calculator </Text>
                      <Text style={[styles.badge, paidGameCounter < 6 && paidGameCounter != 1 && styles.badgeLow]}>{paidGameCounter}</Text>
                    </>
                }
                  </View>
                <Text style={[CommonStyles.whiteText, CommonStyles.textSmallest]}>Bye bye to manual prize calculations</Text>
              </View>
              <View style={styles.controls}>
                <ThemeButton type={game.accounting ? "secondary" : "deselected"} style={styles.toggleBtnLeft} text="Yes" onPress={ () => { userHasPaidGame ? makeGameAccounting() : showModal('ACCOUNTING');} } />
                <ThemeButton type={!game.accounting ? "secondary" : "deselected"} style={styles.toggleBtnRight} text="No" onPress={() => game.accounting && patchGame({ accounting: false })} />
                <ThemeButton
                  type="link"
                  onPress={() => {
                    showModal('ACCOUNTING');
                  }}
                  text={<><Icon name='info-circle' size={16} color={Colors.white} /><Text style={[CommonStyles.whiteText, CommonStyles.text16]}> Info</Text></>}
                />
              </View>
              <View>
              </View>
            </View>
            {game.accounting && 
            <View style={styles.row}>
                <View style={styles.label}>
                  <Text style={[CommonStyles.secondaryText, CommonStyles.textMedium]}>Per Ticket Price</Text> 
                  <Text style={[CommonStyles.whiteText, CommonStyles.textSmallest]}>Price of 1 ticket, for smart calculations</Text>
                </View>
                <View style={styles.controls}>
                  <Text style={[CommonStyles.whiteText, CommonStyles.textMedium, styles.controlValue]}>₹ {ticketCost}</Text>
                  <Slider
                    style={{ width: 200, height: 50 }}
                    thumbTintColor={Colors.secondaryColor}
                    value={ticketCost}
                    onValueChange={(val) => {
                      // Todo: add debounce for web
                      (game.ticketCost != val) && setTicketCost(val);
                    }}
                    onSlidingComplete={
                      (ticketCost) => {
                        // console.log("Slide complete");
                        (game.ticketCost !== ticketCost) && patchGame({ ticketCost });
                      }
                    }
                    minimumValue={5}
                    maximumValue={100}
                    step={5}
                    minimumTrackTintColor="#FFFFFF"
                    maximumTrackTintColor="#434343"
                  />
                </View>
                <View>
              </View>
            </View>}
            {game.accounting && 
            <View style={styles.row}>
                <View style={styles.label}>
                  <Text style={[CommonStyles.secondaryText, CommonStyles.textMedium]}>Game Fees/Margin</Text> 
                  <Text style={[CommonStyles.whiteText, CommonStyles.textSmallest]}>% collection not shared in prizes, for smart calculations</Text>
                </View>
                <View style={styles.controls}>
                  <Text style={[CommonStyles.whiteText, CommonStyles.textMedium, styles.controlValue]}>{commission} %</Text>
                  <Slider
                    style={{ width: 200, height: 50 }}
                    thumbTintColor={Colors.secondaryColor}
                    value={commission}
                    onValueChange={(val) => {
                      // Todo: add debounce for web
                      (game.commission != val) && setCommission(val);
                    }}
                    onSlidingComplete={
                      (commission) => {
                        // console.log("Slide complete");
                        (game.commission !== commission) && patchGame({ commission });
                      }
                    }
                    minimumValue={0}
                    maximumValue={15}
                    step={1}
                    minimumTrackTintColor="#FFFFFF"
                    maximumTrackTintColor="#434343"
                  />
                </View>
                <View>
              </View>
            </View>}
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>Dividends / Variations </Text>
              </View>
              <View style={styles.controls}>
                {/* <Text style={[CommonStyles.whiteText, CommonStyles.textMedium, styles.controlValue]}>{game?.prizes?.length || 0}</Text> */}
                <ThemeButton type="secondary" text="Change" onPress={() => navigation.navigate("ConfigScreen", { gameId })} />
              </View>
            </View>
            {/* <View style={{ alignItems: "center", marginTop: 20, marginBottom: 10 }}>
              <Text style={[CommonStyles.whiteText, CommonStyles.textLarge]}>👑 Pro Settings 👑</Text>
              <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>💎 Launching Soon 💎</Text>
            </View> */}
            <View style={[styles.row, {marginTop: 35}]}>
            <Text style={[CommonStyles.whiteText, CommonStyles.textSmall]}><Text style={{ color: "red" }}>*</Text>below settings are unavailable on your account</Text>
            </View>
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textLarge]}>👑 Pro Settings 👑</Text>
              </View>
              <View style={styles.controls}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>💎 Launching Soon 💎</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>📑 Copy Dividend List</Text>
              </View>
              <View style={styles.controls}>
                <ThemeButton  onPress={()=> proSettingInterest('copy')} type="deselected" text="Copy"/>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>🎰 Smart Dividends</Text>
                <Text style={[CommonStyles.whiteText, CommonStyles.textSmallest]}>Auto set dividends & adjust with no. of players</Text>
              </View>
              <View style={styles.controls}>
                <ThemeButton  onPress={()=> proSettingInterest('smart_off')} type="secondary" text="Off" style={styles.toggleBtnLeft}/>
                <ThemeButton  onPress={()=> proSettingInterest('smart_var')} type="deselected" text="Variation" style={styles.toggleBtnCenter} />
                <ThemeButton  onPress={()=> proSettingInterest('smart_line')} type="deselected" text="Lines" style={styles.toggleBtnCenter} />
                {/* <ThemeButton  onPress={()=> proSettingInterest('smart_block')} type="deselected" text="Blocks" style={styles.toggleBtnCenter} /> */}
                <ThemeButton  onPress={()=> proSettingInterest('smart_rapid')} type="deselected" text="Rapid" style={styles.toggleBtnRight} />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>💡 Show Insights</Text>
                <Text style={[CommonStyles.whiteText, CommonStyles.textSmallest]}>Should disable for large groups</Text>
              </View>
              <View style={styles.controls}>
                <ThemeButton  onPress={()=> proSettingInterest('insght_yes')} type="secondary" text="Yes" style={styles.toggleBtnLeft}/>
                <ThemeButton  onPress={()=> proSettingInterest('insght_no')} type="deselected" text="No" style={styles.toggleBtnRight} />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>🔒 Secret Table</Text>
                <Text style={[CommonStyles.whiteText, CommonStyles.textSmallest]}>Player list will only be visible to host</Text>
              </View>
              <View style={styles.controls}>
                <ThemeButton  onPress={()=> proSettingInterest('noads_yes')} type="deselected" text="Yes" style={styles.toggleBtnLeft}/>
                <ThemeButton  onPress={()=> proSettingInterest('secret_no')} type="secondary" text="No" style={styles.toggleBtnRight} />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>🎟️ Limit Claims by Ticket</Text>
                <Text style={[CommonStyles.whiteText, CommonStyles.textSmallest]}>Minor and Full Housie by no. of tickets</Text>
              </View>
              <View style={styles.controls}>
                <ThemeButton  onPress={()=> proSettingInterest('limit_yes')} type="deselected" text="Yes" style={styles.toggleBtnLeft}/>
                <ThemeButton  onPress={()=> proSettingInterest('limit_no')} type="secondary" text="No" style={styles.toggleBtnRight} />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>🎡 Lucky Wheel</Text>
              </View>
              <View style={styles.controls}>
                <ThemeButton  onPress={()=> proSettingInterest('wheel_off')} type="secondary" text="Off" style={styles.toggleBtnLeft}/>
                <ThemeButton  onPress={()=> proSettingInterest('wheel_on')} type="deselected" text="On" style={styles.toggleBtnRight} />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>🎙️ Voice Chat</Text>
              </View>
              <View style={styles.controls}>
                <ThemeButton  onPress={()=> proSettingInterest('voice')} type="deselected" text="Enable"/>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>🔍 Incorrect Claim List</Text>
              </View>
              <View style={styles.controls}>
                <ThemeButton  onPress={()=> proSettingInterest('claims_list')} type="deselected" text="Show"/>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>💠 Exclusive Dividends</Text>
              </View>
              <View style={styles.controls}>
                <ThemeButton  onPress={()=> proSettingInterest('excl_div')} type="deselected" text="Change"/>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={[CommonStyles.whiteText, CommonStyles.textMedium]}>😇 No Advertisement</Text>
              </View>
              <View style={styles.controls}>
                <ThemeButton  onPress={()=> proSettingInterest('noads')} type="deselected" text="Hide"/>
              </View>
            </View>
          </View> 
        </ScrollView>
      </View>
      <Modalize
        ref={modalizeRef}
        adjustToContentHeight={false}
        modalStyle={[{ backgroundColor: "#281121", zIndex: 999}, {marginHorizontal: (Dimensions.window.width - 420) / 2}]}
      >
        {renderContent()}
      </Modalize>
    </ScreenBackground>
  );
}

SettingsScreen.navigationOptions = {
  header: null,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    alignItems: "center",
    marginHorizontal: 10
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 5
  },
  label: {
    width: "50%",
    marginRight: 10,
    flexShrink: 1,
  },
  controls: {
    flexDirection: "row",
    alignItems: "center",
    minWidth: 250
  },
  controlValue: {
    marginRight: 10
  },
  toggleBtnLeft: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    minWidth: 50
  },
  toggleBtnCenter: {
    borderRadius: 0,
    minWidth: 50
  },
  toggleBtnRight: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    minWidth: 50
  },
  badge:{
    color: Colors.darkTextColor,
    backgroundColor: Colors.secondaryColor,
    borderRadius: 11,
    borderWidth: 1,
    overflow: "hidden",
    padding: 3,
    minWidth: 24,
    maxHeight: 24,
    fontSize: 12,
    fontWeight: "bold",
    justifyContent: "center",
    textAlign: "center"
  },
  badgeLow: {
    backgroundColor: Colors.primaryColor
  }
});
