import { createSelector } from '@reduxjs/toolkit'

const prizePlayerMap = createSelector(
  state => state.claims.queryResult?.data || [],
  wins => {
    let map = wins.filter(
      claim => claim.success
    ).reduce(
      (map, claim) => (
        {
          ...map,
          [claim.prize]: [...(map[claim.prize] || []), { playerId: claim.playerId, playerName: claim.playerName, currDraw: claim.currDraw }]
        }
      ), {}
    );
    for (const prize in map) {
      if (map.hasOwnProperty(prize)) {
        let list = [];
        let element = map[prize].reduce(
          (acc, claim) => ({ ...acc, [claim.currDraw]: [...acc[claim.currDraw] || [], { playerId: claim.playerId, playerName: claim.playerName }] }), {}
        )
        // element = {
        // 45: [{playerId, playerName}, ...],
        // 51: [{playerId, playerName}, ...],
        // ...
        // }
        for (const currDraw in element) {
          if (element.hasOwnProperty(currDraw)) {
            list.push({
              winners: element[currDraw],
              currDraw
            });
          }
        }
        map[prize] = list;
      }
    }
    return map
  }
)

export default prizePlayerMap